import React, { Fragment, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import {
  Grid,
  Typography,
  styled,
  Box,
  Button,
  ButtonGroup,
  Paper,
  InputBase,
  IconButton,
  MenuList,
  MenuItem,
  Grow,
  Popper,
  Checkbox,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Header from '../firstview/Header';
import Sidebar from '../../layout/sidebar'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import { visuallyHidden } from '@mui/utils';
import { useEffect } from 'react';
import byAxios from './../../services/byAxios';
import { CASE_LAMBDAS } from './../../configs/lambdas';

const options = [
  'not_submitted',
  'submitted_to_bank',
  'submitted_to_credit',
  'awaiting_preapproval',
  'preapproved',
  'valuation_initiated',
  'fol_requested',
  'fol_signed',
  'disbursed'
];

const CreateButton = styled(Button)(({ theme }) => ({
  maxWidth: '230px',
  width: '100%',
  height: '40px',
  padding: '5px 10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'none',
  background: '#6E4EE7',
  color: 'white',
  '&:hover': {
    boxShadow: '0px 0px 10px black',
    color: 'black'
  }
}))

const CustHeader = styled(Box)(({ theme }) => ({
  width:'100%',
  height: '10%',
  padding: '10px 0px 0px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}))

const CustTypography = styled(Typography)(({ theme }) => ({
  textTransform: 'none',
}))

const TabTitle = styled(Typography)(({ theme }) => ({
  textTransform: 'none',
}))

const TabButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.secondary,
  display: 'flex',
  width:'20%',
  '&:focus': {
    color: '#6E4EE7',
    fontWeight: 600,
    borderBottom: '5px solid #6E4EE7',
    borderColor: 'none',
    'div': {
      borderRadius: '0px',
      width: '15px',
      background: '#6E4EE7',
      color: 'white',
      padding: '0px 3px',
      marginLeft: '10px'
    }
  },
}))

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'created',
    numeric: true,
    disablePadding: false,
    label: 'Created on',
  },
  {
    id: 'loan',
    numeric: true,
    disablePadding: false,
    label: 'Loan amount',
  },  
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status',
  },
 
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              sx={{fontWeight:700}}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="div" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Cases
        </Typography>
      )}


    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const MainCase = ({ moveHandle, nextClick, setLastTab, lastTab }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const [location, setLocation] = useState(`client`);
  const [cases, setCases] = useState([]);
  console.log('cases',cases)
  const [row, setRow] = useState(cases);
  const [loading, setLoading] = useState(false)
  const[sidebar, setSidebar]=useState(false)
  const showSidebar=()=>{
         setSidebar(true)
  }
  const hideSidebar=()=>{
      setSidebar(false)
}
  useEffect(() => {
    (async () => {
      setLoading(true);
      let session = await Auth.currentSession();
      console.log(`session`, session);
      const caseList = await byAxios({
        route: `${CASE_LAMBDAS.GET_CASES.url}`,
        method: CASE_LAMBDAS.GET_CASES.method,
        headerCred: {
          "x-access-token": session?.accessToken?.jwtToken,
          "x-id-token": session?.idToken?.jwtToken
        }
      });

      console.log(`caseList`, caseList);
      if (Array.isArray(caseList)) {
        // setCases([...caseList.map((caseInDyno) => {
        //   return {
        //     ...caseInDyno,
        //     status: `Not Committed`
        //   }
        // })]);
        setCases([...caseList.map((caseInDyno, index) => {
          let temp =  Object.keys(caseInDyno).filter(key => caseInDyno[key] === true)
          if (temp.length === 0) {
            return {
              ...caseInDyno,
              'not_submitted': true
            }
          }
          else return caseInDyno
        })])
      }
      // Avoid a layout jump when reaching the last page with empty rows.
      setEmptyRows(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - row.length) : 0)
      setLoading(false);
    })()
  }, []);

  useEffect(() => {
    setRow(cases)
  },[cases])

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
    
    setRow(cases.filter(item => item[options[index]] === true))
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('created');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [emptyRows, setEmptyRows] = React.useState(0);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = row.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
 

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

 const filter = (e) => {
  const keyword = e.target.value;
  if (keyword !== '') {
    const results = cases.filter((user) => {
      return user.firstName.toLowerCase().startsWith(keyword.toLowerCase());
    });
    setRow(results);
  } else {
    setRow(cases);
  }
  setSearchValue(keyword);
 }

  return (
    <body class={`${sidebar ? 'overlay-body' : ''}`} style={{width:'100%'}}>
      {
        loading ?
        <div id="preloader"> </div> :
        <div id="preloader" style={{display: 'none'}}></div>
      }
       <Header/>
       
       <div class="home" style={{marginTop:'120px', paddingBottom:'60px'}}>

      <Box sx={{ paddingBottom: '50px'  , paddingLeft:'40px' , paddingRight:'40px'}}>
        <Grid item 
          xs={{
            p: { sm: '20px', xs: '10px 0px 10px' },
            mt: { xs: '30px', sm: '0px' },
            width:'41%'
          }}
          sm={{
            p: { sm: '20px', xs: '10px 0px 10px' },
            mt: { xs: '30px', sm: '0px' },
            width:'41%'
          }} md={12} lg={12}
        >
          <CustHeader>
            <Box>
              <CustTypography variant='h4' sx={{
                color: 'black',
                fontSize: { sm: '48px', xs: '40px' }
              }}>
                Cases
              </CustTypography>
              <CustTypography variant='body1' sx={{
                color: 'black',
                fontSize: { sm: '16px', xs: '14px' }
              }}
              >
                Manage your clients
              </CustTypography>
            </Box>
            <Link to='/cases/create' style={{
              textDecoration: 'none'
            }}>
              <CreateButton>
                <Typography variant='h5'>+</Typography>
                &nbsp;Create a case
              </CreateButton>
            </Link>
          </CustHeader>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            justifyContent:'space-between',
            // flexDirection: 'column',
            // alignItems: 'flex-start',
            width:'100%',
            // '& > *': {
            //   m: 1,
            // },
            marginTop:'10px'
          }}
        >
          <TabButton variant='outlined' onClick={() => setRow(cases)}>
            <TabTitle variant='body2'>All</TabTitle>
            <div>&nbsp;{row.length}</div>
          </TabButton>
            <Paper
              elevation={0}
              component="form"
              sx={{
                height: 56,
                border: '1px solid grey',
                width: {xs:'100%', sm:'100%', md:'20%', lg:'20%'}
              }}
            >
              <div className="flex-1 p-1 m-1"></div>
              <InputBase
                placeholder="Search case..."
                inputProps={{ 'aria-label': 'search ' }}
                type="search"
                value={searchValue}
                onChange={filter}
                sx={{ paddingLeft: '10px'  }}
              />

              <IconButton
                size="small"
                type="submit"
                className='m-1 p-1'
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
            </Paper>
            {/* <Fragment> */}
              <ButtonGroup ref={anchorRef} aria-label="split button">
                <Button sx={{ textTransform: 'none', color: 'grey', width:'70%' }}>Status</Button>
                <Button
                  size="small"
                  aria-controls={open ? 'split-button-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-label="select merge strategy"
                  aria-haspopup="menu"
                  onClick={handleToggle}
                >
                  <ArrowDropDownIcon />
                </Button>
              </ButtonGroup>
              <Popper
                sx={{
                  zIndex: 1,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper sx={{ border: '1px solid grey' }}>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="split-button-menu" autoFocusItem>
                          {options.map((option, index) => (
                            <MenuItem
                              checked={true}
                              key={option}
                              selected={index === selectedIndex}
                              onClick={(event) => handleMenuItemClick(event, index)}
                            >
                              <Checkbox checked={index === selectedIndex ? true : false} />
                              {option}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            {/* </Fragment> */}
        </Box>
       
        <Box sx={{ width: '100%' }}>
          <Paper sx={{ width: '100%', mb: 2 }}>
            <EnhancedTableToolbar numSelected={selected.length} />
            <TableContainer>
              <Table
                sx={{ overflowX:'scroll' ,minWidth:700 }}
                aria-labelledby="tableTitle"
                size={'medium'}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={row.length}
                />
                <TableBody>
                  {stableSort(row, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={index}
                          selected={isItemSelected}
                          onClick={
                            () => { 
                              {
                              navigate(`/cases/review?caseId=${row?.clientId}`)
                            }  
                            }
                          }
                          sx={{
                            '&:hover': {
                              cursor: `pointer`
                            }
                          }}
                        >
                          {/* <TableCell padding="checkbox" style={{border:'none' , borderBottom:'1px solid lightgrey'}}>
                          </TableCell> */}
                          <TableCell
                          style={{border:'none' , borderBottom:'1px solid lightgrey'}}
                            component="th"
                            align="center"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row?.firstName}&nbsp;&nbsp;{row?.lastName}
                          </TableCell>
                          <TableCell align="center" style={{border:'none' , borderBottom:'1px solid lightgrey'}}>
                            {new Date(row?.created).getDate()}-{new Date(row?.created).getMonth()+1}-{new Date(row?.created).getFullYear()}  {new Date(row?.created).getHours()}:{new Date(row?.created).getMinutes()}
                          </TableCell>
                          <TableCell align="center" style={{border:'none' , borderBottom:'1px solid lightgrey'}}>{row?.loanAmount || 0}</TableCell>                          
                          <TableCell align="center" style={{border:'none' , borderBottom:'1px solid lightgrey'}}>
                            {
                              Object.keys(row).filter(key => row[key] === true)[0]
                            }
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={row.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </Box>
    </div>
   </body>
  )
}

export default MainCase