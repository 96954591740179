import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Box, Card, Typography, Checkbox, Button } from "@mui/material"
import CustButton from '../CustButton'
import { styled } from "@mui/material";

import { Link, useSearchParams } from 'react-router-dom';

import { CASE_LAMBDAS } from './../../configs/lambdas'

import byAxios from './../../services/byAxios';

const ProgressLine = styled(Box)((props) => ({
  width: `${props._width}%`,
  height: '8px',
  background: props._width < 50 ? 'black' : 'blue',
}))

const BankList = styled(Button)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  border: '1px solid lightgrey',
  borderRadius: '5px',
  margin: '10px auto',
  padding: '5px',
  textTransform: 'none'
}))

const Banks = ({ moveHandle, nextClick, setLastTab, lastTab }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [checkList, setCheckList] = useState(0)
  const [persent, setPersent] = useState(0)
  const [bankData, setBankData] = useState([
    {
      name: 'hsbc',
      imgurl: '/images/icons/project-icons/hsbc.png',
      text: 'HSBC',
      checked: false,
    },
    {
      name: 'adcb',
      imgurl: '/images/icons/project-icons/adcb.png',
      text: 'Abu Dhabi Commercial Bank',
      checked: false,
    },
    {
      name: 'adib',
      imgurl: '/images/icons/project-icons/adib.jpg',
      text: 'Abu Dhabi Islamic Bank',
      checked: false,
    },
    {
      name: 'ajman',
      imgurl: '/images/icons/project-icons/ajman.png',
      text: 'Ajman Bank',
      checked: false,
    },
    {
      name: 'commercial',
      imgurl: '/images/icons/project-icons/commercial.jpg',
      text: 'Commercial Bank of Dubai',
      checked: false,
    },
    {
      name: 'dubai',
      imgurl: '/images/icons/project-icons/dubai.jpg',
      text: 'Dubai IsLamic Bank',
      checked: false,
    },
    {
      name: 'enbd',
      imgurl: '/images/icons/project-icons/enbd.png',
      text: 'ENBD',
      checked: false,
    },
    {
      name: 'emirates',
      imgurl: '/images/icons/project-icons/emirates.png',
      text: 'Emirates Islamic Bank',
      checked: false,
    },
    {
      name: 'fab',
      imgurl: '/images/icons/project-icons/fab.png',
      text: 'FAB',
      checked: false,
    },
    {
      name: 'mashreq',
      imgurl: '/images/icons/project-icons/mashreq.png',
      text: 'Mashreq Bank',
      checked: false,
    },
    {
      name: 'national',
      imgurl: '/images/icons/project-icons/national.png',
      text: 'National Bank of Fujairah',
      checked: false,
    },
    {
      name: 'rakbank',
      imgurl: '/images/icons/project-icons/rakbank.png',
      text: 'RAKBANK',
      checked: false,
    },
    {
      name: 'chartered',
      imgurl: '/images/icons/project-icons/chartered.jpg',
      text: 'Standard Chartered Bank',
      checked: false,
    },
    {
      name: 'united-arab',
      imgurl: '/images/icons/project-icons/united-arab.png',
      text: 'United Arab Bank',
      checked: false,
    },
  ]);
  console.log('bankData', bankData)
  const [local, setLocal] = useState([]);
  console.log('local', local)
  const handleClick = (index) => {
    let temp = [...bankData];
    let length = 0;
    bankData.map((item, idx) => {
      if (index === idx && !temp[idx].checked) {
        temp[idx].checked = true
        setCheckList(checkList + 1)
      } else if (index === idx && temp[idx].checked) {
        temp[idx].checked = false
        setCheckList(checkList - 1)
      }
      temp[idx] = item
    })
    setBankData([...temp])
    console.log('length', length)
  };

  useEffect(() => {
    setPersent(checkList * 7.14)
  }, [checkList]);

  useEffect(() => {
    (async () => {
      if (searchParams.get(`caseId`)) {
        console.log('here')
        try {
          let session = await Auth.currentSession();
          const caseData = await byAxios({
            route: `${CASE_LAMBDAS.GET_DETAIL.url}/${searchParams.get(`caseId`)}`,
            method: CASE_LAMBDAS.GET_DETAIL.method,
            headerCred: {
              "x-access-token": session?.accessToken?.jwtToken,
              "x-id-token": session?.idToken?.jwtToken
            }
          });
          console.log('fetchBank',caseData)

          let newBankData = bankData

          for(let i = 0; i <= 1; i ++) {
            if (Object.keys(caseData).includes(`bank${i + 1}_name`)) {
              const index = newBankData.findIndex(data => data.text === caseData[`bank${i + 1}_name`])
              newBankData[index].checked = true
            }
          }
          setBankData([...newBankData])
        }
        catch (err) {

        }
      }
    })()

  }, [lastTab])

  const handleSubmit = async () => {
    moveHandle(6);
    nextClick('bankforms');
    let bank = [];
    console.log('hua')
    bankData.map((item, index) => {
      item.checked && bank.push({ name: item.text, image: item.imgurl });
    })
    localStorage.setItem('bankList', JSON.stringify(bank));

    // try {
    //   let session = await Auth.currentSession();
    //   const updateRes = await byAxios({
    //     route: CASE_LAMBDAS.UPDATE_CASE.url,
    //     method: CASE_LAMBDAS.UPDATE_CASE.method,
    //     headerCred: {
    //       "x-access-token": session?.accessToken?.jwtToken
    //     },
    //     data: {
    //       banks: JSON.stringify(bank),
    //       client_id: searchParams.get(`caseId`),
    //       last_location: 4
    //     }
    //   });
    // }
    // catch (err) {
    // }

    setLastTab(4)

  }

  return (
    <>
      <Typography variant='body2' sx={{
        p: '20px 0px',
        textAlign: 'left'
      }}>
        Create an account for your client so you can track their progress and stay in control.
      </Typography>
      <Box component='form' noValidate onSubmit={handleSubmit}>
        <Card sx={{
          borderRadius: '5px',
          boxShadow: '0px 0px 10px lightgrey'
        }}>
          <ProgressLine _width={persent} />
          <Box sx={{
            p: '20px 25px 0px',
            textAlign: 'left'
          }}>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}>
              <Typography variant='h5'>Banks</Typography>
              <Button sx={{
                display: 'flex',
                alignItems: 'center',
                textTransform: 'none',
                color: 'black'
              }}>
                <img style={{
                  width: '15px'
                }} src='/images/icons/project-icons/save.png' />
                <Typography variant='body2'>
                  &nbsp;Changes saved
                </Typography>
              </Button>
            </Box>
            <Typography variant='body2' sx={{
              pt: '20px'
            }}>
              Select up to 3 banks to send your application to
            </Typography>
          </Box>
          <Box sx={{ p: { sm: '10px 25px', xs: '10px' } }}>
            {
              local?.length > 0 ? local.map((item, idx) => (
                <BankList key={idx}>
                  <img style={{ width: '50px' }} src={item.image} />
                  <Typography variant='body2' sx={{
                    px: '10px',
                    color: 'black'
                  }}>{item.name}</Typography>
                </BankList>
              )) :
                bankData.map((item, idx) => (
                  <BankList key={idx} onClick={() => handleClick(idx)}>
                    <Checkbox
                      checked={item.checked}
                      inputProps={{ 'aria-label': 'controlled' }}
                      disabled={local?.length > 0}
                    />
                    <img style={{ width: '50px' }} src={item.imgurl} />
                    <Typography variant='body2' sx={{
                      px: '10px',
                      color: 'black'
                    }}>{item.text}</Typography>
                  </BankList>
                ))
            }
          </Box>
        </Card>
        {
          local?.length > 0 ? <></> :
            <Box sx={{
              p: '30px 0px',
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
              <CustButton
                mw={150}
              >
                Next step
              </CustButton>
            </Box>
        }

      </Box>
    </>
  )
}

export default Banks