import {
  AUTH_SIGNUP,
  AUTH_SINGIN,
  AUTH_TOKEN
} from '../configs/actionTypes/auth'

const INITIAL_STATE = {
  currentUser: {
    email: '',
    name: '',
    token: ''
  }
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH_SIGNUP:
      console.log('action', action)
      return {
        ...state,
        currentUser: {
          email: action.payload.email,
          name: action.payload.name
        }
      }
    case AUTH_SINGIN:
      return {
        ...state,
        currentUser: {
          email: action.email
        }
      }
    case AUTH_TOKEN:
      return {
        ...state,
        currentUser: {
          token: action.token
        }
      }
    default:
      return state
  }
}

export default reducer