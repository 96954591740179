export const CASE_LAMBDAS = {
  GET_CASES: {
    url: `https://89hrz8w5tj.execute-api.eu-west-2.amazonaws.com/test/get_clients_data`,
    method: `GET`
  },
  GET_PROFILE: {
    url: `https://89hrz8w5tj.execute-api.eu-west-2.amazonaws.com/test/get_broker_profile`,
    method: `GET`
  },
  UPDATE_PROFILE: {
    url: `https://89hrz8w5tj.execute-api.eu-west-2.amazonaws.com/test/update_broker_profile`,
    method: `POST`
  },
  CREATE_CASE: {
    url: `https://89hrz8w5tj.execute-api.eu-west-2.amazonaws.com/test/create_new_client`,
    method: `POST`
  },
  UPDATE_CASE: {
    url: `https://89hrz8w5tj.execute-api.eu-west-2.amazonaws.com/test/save_client_data`,
    method: `POST`
  },
  GET_DETAIL: {
    url: `https://89hrz8w5tj.execute-api.eu-west-2.amazonaws.com/test/get_client_detail`,
    method: `GET`
  },
  INSERT_CRM: {
    url: `https://89hrz8w5tj.execute-api.eu-west-2.amazonaws.com/test/submit_lead_data_crm`,
    method: `POST`
  },
  UPDATE_ACCOUNT: {
    url: `https://89hrz8w5tj.execute-api.eu-west-2.amazonaws.com/test/update_broker_documents`,
    method: `POST`
  },
  GET_SESSION: {
    url: `https://crm.mortgagebcf.com/service/v4_1/rest.php`,
    method: `get`
  }
}
