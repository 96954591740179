import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { 
  Box, 
  Typography, 
  Card, 
  Grid,
  styled,
  Tabs,
  Tab,
  useMediaQuery
} from "@mui/material"
import { Link, useSearchParams } from 'react-router-dom';

import { Client, Income, Banks, Bankforms, Documents, Review } from '../../components';
import MainCase from '../maincase'
import Sidebar from '../../layout/sidebar';
import { Dropdown } from 'react-bootstrap';
import Header from '../firstview/Header';
import byAxios from './../../services/byAxios';
import { CASE_LAMBDAS } from './../../configs/lambdas';

const Container = styled(Grid)(({ theme }) => ({
  '.MuiGrid-container': {
    display: 'flex',
    justifyContent: 'center !important'
  }
}))

const NavBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
}))

const TabBar = styled(Tabs)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center !important',
  '.Mui-selected': {
    color: 'black !important'
  },
}))

const CustTab = styled(Tab)((props) => ({
  padding: '10px 5px',
  textTransform: 'none',
  fontWeight: '700',
  fontSize: '15px',
  color: (props.idx <= props.fw) ? 'black' : 'black',
  borderColor: '#6E4EE7'
}))

const CustTypography = styled(Typography)(({ theme }) => ({
  padding: '15px 5px'
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

const CasesCreate = () => {
  const smDown = useMediaQuery('(max-Width:414px)')
  const [searchParams, setSearchParams] = useSearchParams();
  const [lastTab, setLastTab] = useState(-2);
  const [value, setValue] = useState(0);
  console.log('value', value)
  const [location, setLocation] = useState(`client`);
  const [activedTabs, setActivedTabs] = useState(['client']);
  const [loading, setLoading] = useState(false)
  const[sidebar, setSidebar]=useState(false)
  const showSidebar=()=>{
         setSidebar(true)
  }
  const hideSidebar=()=>{
      setSidebar(false)
}
  
  useEffect(() => {
    (async () => {
      setLoading(true)
      
      localStorage.setItem('location', `client`);
      
      if (location === 'client') {
        moveHandle(0)
      } else if (location === 'income') {
        moveHandle(2)
      }  else if (location === 'documents') {
        moveHandle(4)
      } else if (location === 'review') {
        moveHandle(6)
      }
      else if (location === 'bank') {
        moveHandle(8)
      } else if (location === 'bankforms') {
        moveHandle(10)
      }

      if (searchParams.get(`caseId`)) {
        let session = await Auth.currentSession();

        const caseData = await byAxios({
          route: `${CASE_LAMBDAS.GET_DETAIL.url}/${searchParams.get(`caseId`)}`,
          method: CASE_LAMBDAS.GET_DETAIL.method,
          headerCred: {
            "x-access-token": session?.accessToken?.jwtToken,
            "x-id-token": session?.idToken?.jwtToken
          }
        });

        console.log(`caseData in case`, caseData);

        if (caseData) {
          setLastTab(caseData?.last_location);
        }

        console.log(`caseData`, caseData);
      }
      setLoading(false)
    })()
  }, [])

  const isTabActived = (value) => {
    return value > lastTab + 2
  }

  const nextClick = (path) => {
    localStorage.setItem('location', path)
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const tabChange = (event, newValue) => {
   
    setValue(newValue);
    if (newValue === 0) {
      localStorage.setItem('location', 'client')
    } else if (newValue === 2) {
      localStorage.setItem('location', 'income')
    } else if (newValue === 4) {
      localStorage.setItem('location', 'documents')
    } else if (newValue === 6) {
      localStorage.setItem('location', 'review')
    } else if (newValue === 8) {
      localStorage.setItem('location', 'banks')
    } else if (newValue === 10) {
      localStorage.setItem('location', 'bankforms')
    }
  
  };

  const getIndexByTabName = (tabName) => {
    let result = 0;
    switch (tabName) {
      case 'client':
        result = 0
        break;

      case 'income':
        result = 2
        break;

      case 'documents':
        result = 4
        break;

      case 'review':
        result = 6
        break;

      case 'bank':
        result = 8
        break;

      case 'bankforms':
        result = 10
        break;
    }

    return result;
  }

  const getTabNameByIndex = (index) => {
    let result = ``;
    switch (index) {
      case 0:
        result = `client`
        break;

      case 2:
        result = `income`
        break;

      case 4:
        result = `documents`
        break;

      case 6:
        result = `review`
        break;

      case 8:
        result = `bank`
        break;

      case 10:
        result = `bankforms`
        break;
    }

    return result;
  }

  const moveHandle = (idx) => {
    a11yProps(idx)
    tabChange('', idx)
  }

  return (
    <div>
      {
        loading ?
        <div id="preloader"> </div> :
        <div id="preloader" style={{display: 'none'}}></div>
      }
      <body  class={`${sidebar ? 'overlay-body' : ''}`} style={{width:'100%'}}>
      <Container container columnGap={{ xs: 2, ss: 4 }} sx={{
        display: 'flex',
        justifyContent: 'center'
      }}>
       <Header/>
      
       <div class="home" style={{marginTop:'120px ' , width:'100%', paddingBottom:'60px'}}>

   
        <Grid item xs={12} sm={12} ss={12} md={12} sx={{
          p: { sm: '20px', xs: '10px 0px 10px' },
          mt: { xs: '40px', sm: '0px' }
        }}>
          <Grid item xs={12}>
            <NavBar>
              <Link to='/cases' style={{
                color: 'black'
              }}>
                <Typography>Cases</Typography>
              </Link>
              <Link to='/cases/create' style={{
                textDecoration: 'none'
              }}>
                <Typography>&nbsp;&nbsp;/&nbsp;&nbsp;Create a case</Typography>
              </Link>
            </NavBar>
            <Card sx={{
              mt: '30px',
              borderRadius: '10px',
              display: 'flex',
              justifyContent: { lg: 'center', xs: 'flex-start' },
              boxShadow: '0px 0px 10px lightgrey',
              overflow: 'auto',
              '.MuiTabs-root': {

              },
              '.MuiTabs-flexContainer': {
                display: 'flex',
                justifyContent: 'center'
              }
            }}>
               { smDown ?
                  <>
                    <TabBar
                      value={value}
                      onChange={tabChange}
                      aria-label="basic tabs example"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center !important',
                        boxShadow: '0px 0px 10px grey',
                        alignItems:'center'
                      }}
                    >
                    {
                      value === 0 ? <Typography variant='h6'>Client</Typography> :
                      value === 2 ? <Typography variant='h6'>Customer Financial Details</Typography> :                      
                      value === 4 ? <Typography variant='h6'>Documents</Typography> :
                      value === 6 ?<Typography variant='h6'>Review and Submit</Typography> :
                      <></>
                    }
                    </TabBar>
                  </>
                :
                  <TabBar
                    value={value}
                    onChange={tabChange}
                    aria-label="basic tabs example"
                    sx={{
                      display: 'flex',
                      justifyContent: 'center !important',
                      boxShadow: '0px 0px 10px grey',
                      alignItems:'center'
                    }}
                  >
                  <CustTab
                    fw={value}
                    disabled={isTabActived(0)}
                    idx={0}
                    label="Client" {...a11yProps(0)} />
                  <CustTypography>{'>'}</CustTypography>
                  <CustTab
                    fw={value}
                    disabled={isTabActived(2)}
                    idx={2}
                    label="Customer Financial Details" {...a11yProps(1)} />
                  <CustTypography>{'>'}</CustTypography>
                  <CustTab
                    fw={value}
                    enabled={isTabActived(4)}
                    idx={4}
                    label="Documents" {...a11yProps(2)} />
                  <CustTypography>{'>'}</CustTypography>
                  <CustTab
                    fw={value}
                    enabled={isTabActived(6)}
                    idx={6}
                    label="Review and submit" {...a11yProps(3)} 
                  />
                {/* </>
                } */}
                  </TabBar>
                }
            </Card>
            <TabPanel value={value} index={0}>
              <Client
                moveHandle={moveHandle}
                nextClick={nextClick}
                setLastTab={setLastTab}
                lastTab={lastTab}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Income
                moveHandle={moveHandle}
                nextClick={nextClick}
                setLastTab={setLastTab}
                lastTab={lastTab}
              />
            </TabPanel>            
            <TabPanel value={value} index={4}>
              <Documents
                moveHandle={moveHandle}
                nextClick={nextClick}
                setLastTab={setLastTab}
                lastTab={lastTab}
              />
            </TabPanel>
            <TabPanel value={value} index={6}>
              <Review
                setLastTab={setLastTab}
                lastTab={lastTab}
              />
            </TabPanel>
          </Grid>
        </Grid>
        </div>
      
      </Container>
      </body>
      </div>
  )
}

export default CasesCreate