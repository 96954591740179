import { Button } from "@mui/material";
import { styled } from "@mui/material";

const ReviewButton = styled(Button)((props) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#6E4EE7',
  padding: '10px 0px',
  maxWidth: props.mw ? `${props.mw}px` : '230px',
  maxHeight: props.mh ? `${props.mh}px` : 'auto',
  width: '100%',
  textTransform: 'none',
  color: 'white',
  '&:hover': {
    boxShadow: '0px 0px 10px black',
    color: 'black'
  }
}))

const SendButton = ({ children, mw, onClick, mh }) => {
  return (
    <ReviewButton mw={mw} mh={mh} onClick={onClick}>
      {children}
    </ReviewButton>
  )
}

export default SendButton