import React, { useState } from 'react';
import {
  Button,
  CssBaseline,
  TextField,
  Box,
  Typography,
  CircularProgress
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Auth } from 'aws-amplify';
import { useToasts } from 'react-toast-notifications';
import Sidebar from '../firstview/Sidebar';

const theme = createTheme();

export default function SignIn() {
  const[sidebar, setSidebar]=useState(false)

  const showSidebar=()=>{
     
         setSidebar(true)
         
  }
  const hideSidebar=()=>{
     
      setSidebar(false)
      
}
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const result = await Auth.signIn(email);
      window.cognitoUser = result
      navigate('/confirmation')
    }
    catch (error) {
      addToast(error.message, {
        appearance: 'error',
        autoDismiss: true
      })
    }
    setLoading(false)
  };

  return (
    <body>
     <Sidebar/>
<div id="home" class="confidence-home-area col-lg-12 mt-5" style={{ height:'100%' , width:'100%' , paddingBottom:'100px', marginLeft:'0px ' }}>
            <div class="container-fluid">
                <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12 more-pos-mar" style={{textAlign: 'center'}}>
                        <div class="confidence-home-content">
                          <div className="order1">
                        <span class="sub-title" style={{fontSize: '25px'}}>Leading mortgage broker</span>
                        <h2 style={{fontSize:'35px' , fontWeight:'bold'}}>Your Personalised Mortgage Broker</h2>
                        </div>
                       
                        </div>
                        </div>
                        <div class="col-lg-6 col-md-12 more-neg-mar-opp" style={{textAlign: 'center'}}>
                        <div class="confidence-home-content">
                          <div className="order2">
                        <span class="sub-title" style={{fontSize: '15px'}}>Don't have an account yet?&nbsp;
            <Link to='/signup'>Sign up</Link></span>
                        <h2>Sign In for Brokers</h2>
                        <Box component="form" onSubmit={handleSubmit} noValidate >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={e => setEmail(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              class='main-default-btn2'
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                textTransform: 'none',
                background: '#00C195'
              }}
              style={{width:'280px'}}
            >
              {loading && <CircularProgress />}
              Sign In
            </Button>
          </Box>
          </div>
                        </div>
                        </div>
                        <div class="col-lg-6 col-md-12" style={{textAlign: 'center'}}>
                        <div class="confidence-home-content more-neg-mar">
                          
                        <div className="order3">
                        <p style={{textAlign:'center',margin:'auto'}}>
                    Bandenia will save you a lot of time and stress; We will handle everything from searching for a deal to applying and communicating with the lender on your behalf.
                     Expert in the mortgage market and will be able to recommend deals that suit your situation.</p>
                     <p style={{textAlign:'center',margin:'auto', fontWeight:'bold'}}>
                     Empanel with us as a Referral Broker Partner and get your pay-out within 48 hours of registering the mortgage deal at Land Department (On submission of Title Deed) <br/>

*Conditions Apply please contact us for more details <a href="mailto:contact@bcf.ae"> (contact@bcf.ae)</a>
</p>
                        <a href="#" class="main-default-btn2" >Islamic Finance</a>
                        <a href="#" class="main-default-btn2" >Conventional Finance</a>
                        <a href="#" class="main-default-btn2" >Instant Quotation</a>
                        </div>
                        </div>
                        </div>
                </div>
            </div>
        </div>
      {/* <Box
        component="main"
        sx={{
          maxWidth: '450px',
          width: '100%',
          p: '20px',
          marginTop: {xs:'40%', sm:'30%', md:'120px', lg:'120px'},
        }}> */}
        {/* <CssBaseline />
        <Box>
          <Typography>Dont't have an account yet?&nbsp;
            <Link to='/signup'>Sign up</Link>
          </Typography>
        </Box>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography component="h1" variant="h4">
            Sign in for Brokers
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={e => setEmail(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                textTransform: 'none',
                background: '#00C195'
              }}
            >
              {loading && <CircularProgress />}
              Sign In
            </Button>
          </Box>
        </Box> */}
      {/* </Box> */}
     
      </body>
  );
}