import {
  ACTION_BROKER
} from '../configs/actionTypes/broker'

const INITIAL_STATE = {
  currentUser: {
    name:"",
    email:"",
    companyName:"",
    rm:"",
    phone_office:"",
    trade_license_path:"",
    agreement_path:"",
    id_path:"",
    passport_path:""
  }
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_BROKER:
      return {
        ...state,
        currentUser: {
          firstName:action.payload.firstName,
          lastName:action.payload.lastName,
          email:action.payload.email,
          name:action.payload.name,
          email:action.payload.email,
          companyName:action.payload.companyName,
          rm:action.payload.rm,
          phone_office:action.payload.phone_office,
          trade_license_path:action.payload.trade_license_path,
          agreement_path:action.payload.agreement_path,
          id_path:action.payload.id_path,
          passport_path:action.payload.passport_path,
          agreement_path:action.payload.agreement_path
        }
      }
    default:
      return state
  }
}

export default reducer