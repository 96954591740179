import { useState } from 'react'

import {
    Container,
    Row,
    Col,
    Button,
    Card,
    Form,
    InputGroup,
    Dropdown,
    Carousel
  } from 'react-bootstrap'

  import { Link , useNavigate } from 'react-router-dom'
  
  
  const Footer = () => {
    const[sidebar, setSidebar]=useState(false)
    const navigate=useNavigate();
    const showSidebar=()=>{
       
           setSidebar(true)
           localStorage.setItem('sidebar', sidebar);
    }
    const hideSidebar=()=>{
       
        setSidebar(false)
        localStorage.setItem('sidebar', sidebar);
 }

    return (
 
   
    <body class={`${sidebar ? 'overlay-body' : ''}`}>
    {/*<!-- Start Overview Style Area --> */}
        <div class="overview-style-area" style={{marginTop:'50px', marginBottom:'50px'}}>
            <div class="container">
                <div class="overview-style-inner-box">
                    <div class="content">
                        <h3>Take The First Step Toward The Right <span>Mortgage</span></h3>
                    
                        <ul class="overview-btn">
                            <li>
                                <a href="https://user.mortgagebcf.com/" class="main-default-btn">Career Opportunity</a>
                            </li>
                            <li>
                                <a href="https://broker.bcf-mortgage.com/signup" class="main-optional-btn">Join BCF</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="overview-style-shape-1">
                <img src="/assets/images/overview.png" alt="image"/>
            </div>
        </div>
        {/* <!-- End Overview Style Area -->

      
      

        <!-- Subscribe Style Area --> */}
        <section class="subscribe-style-area ptb-100">
            <div class="container">
                <div class="row align-items-center">
                    <div class="subscribe-style-wrap-content">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-6">
                                <h2>Do You Want to Get Update What’s Upcoming?</h2>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <form class="newsletter-form" data-toggle="validator">
                                    <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required autocomplete="off"/>
            
                                    <button class="main-default-btn" type="submit">
                                        Subscribe Now
                                    </button>
                                    <div id="validator-newsletter" class="form-result"></div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- End Subscribe Style Area -->

        <!-- Footer Style Area --> */}
        <footer class="footer-style-area pt-100">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-sm-6 col-lg-3">
                        <div class="single-footer-widget">
                            <div class="footer-logo">
                                <a href="#">
                                    <img src="/assets/images/cropped-logo.png" alt="Logo" style={{height:'80px' , width:'90px'}}/>
                                </a>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                <ul class="footer-social">
                                    <li>
                                        <a href="#" target="_blank">
                                            <i class='bx bxl-facebook'></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">
                                            <i class='bx bxl-twitter'></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">
                                            <i class='bx bxl-linkedin'></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">
                                            <i class='bx bxl-instagram'></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-2">
                        <div class="single-footer-widget ps-3">
                            <h3>Quick Links</h3>
                            
                            <ul class="quick-link">
                                <li>
                                    <a href="#" target="_blank">About Us</a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">What We Offer</a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">Why Choose Us</a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">Contact</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-2">
                        <div class="single-footer-widget ps-3">
                            <h3>Company</h3>
                            
                            <ul class="quick-link">
                                <li>
                                    <a href="#" target="_blank">Blog</a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">FAQ</a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">Loan Calculator</a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">Mortgage Calculator</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-2">
                        <div class="single-footer-widget ps-3">
                            <h3>Support</h3>
                            
                            <ul class="quick-link">
                                <li>
                                    <a href="#" target="_blank">Contact</a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">Privacy Policy</a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">Loan Calculator</a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">Terms & Conditionsr</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-3">
                        <div class="single-footer-widget">
                            <h3>Address</h3>

                            <ul class="address-info">
                                <li>
                                    <i class='bx bx-phone-call'></i>
                                    <a href="tel:+971 42014000">+971 42014000</a>
                                </li>
                                <li>
                                    <i class='bx bx-message-square-detail'></i>
                                    <a href="mailto:support@bcf.ae">support@bcf.ae</a>
                                </li>
                                <li>
                                    <i class='bx bx-current-location' ></i>
                                    Conrad Dubai, 17th Floor, Office # 1707-08 Sheikh Zayed Road, Dubai, U.A.E. Toll Free No: 800-Bandenia.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

               
            </div>
        </footer>
        {/* <!-- End Footer Style Area -->*/}
        </body>
    )
  }
  
  export default Footer