import { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';

import { useSearchParams, useNavigate ,Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useDispatch } from 'react-redux';
import { CASE_LAMBDAS } from './../../configs/lambdas'
import byAxios from './../../services/byAxios';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import TimerIcon from '@mui/icons-material/Timer';
import Sidebar from '../../layout/sidebar';
import { broker } from '../../actions/broker';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CallIcon from '@mui/icons-material/Call';

import {
  Box,
  Divider,
  Typography,
  Grid,
  TextField,
  styled,
  Paper,
  Stack,
  CircularProgress,
  Chip,
  Card,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Checkbox,
  FormGroup,

} from '@mui/material';
import SendButton from '../../components/CustButton/review';
import { Dropdown } from 'react-bootstrap';
import Header from '../firstview/Header';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const Row = styled('div')(({ theme }) => ({
  display: 'flex',
  marginLeft: '20%'
}))
const NavBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
}))
const Title = styled(Typography)(({ theme }) => ({
  color: 'black',
}))

const Review = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const [profileData, setProfileData] = useState({name:"",email:"",companyName:"",rm:"",phone_office:"",trade_license_path:""});
  const [banks,setBanks]= useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const client = JSON.parse(localStorage.getItem('client'));
  console.log('client', client)
  const income = JSON.parse(localStorage.getItem('income'));
  console.log('income', income)
  const bankForms = JSON.parse(localStorage.getItem('bankForms'));
  console.log('bankForms', bankForms)
 const [comment, setComment]= useState('')
 const[sidebar, setSidebar]=useState(false)
  const showSidebar=()=>{
         setSidebar(true)
  }
  const hideSidebar=()=>{
      setSidebar(false)
}
  const [reviewData, setReviewData] = useState(null);
  console.log('reviewData',reviewData)
  const handleComment = async (e) => {
   setComment(e.target.value)
   console.log(comment)
  }

  const handleReview = async () => {
    setLoading(true);
   
    try {
      let session = await Auth.currentSession();
      const res = await byAxios({
        route: CASE_LAMBDAS.UPDATE_CASE.url,
        method: CASE_LAMBDAS.UPDATE_CASE.method,
        headerCred: {
          "x-access-token": session?.accessToken?.jwtToken,
          "x-id-token": session?.idToken?.jwtToken
        },
        data: {
          comment:comment,
        }
      });
     
    }
    catch (err) {
      addToast(err.message, {
        appearance:'error',
        autoDismiss:true
      })
    }
    try {
      let session = await Auth.currentSession();
      const res = await byAxios({
        route:  `${CASE_LAMBDAS.INSERT_CRM.url}/${searchParams.get(`caseId`)}`,
        method: CASE_LAMBDAS.INSERT_CRM.method,
        headerCred: {
          "x-access-token": session?.accessToken?.jwtToken,
          "x-id-token": session?.idToken?.jwtToken
        }
      });
      addToast('Your case is submitted successfully', {
        appearance: 'success',
        autoDismiss: true
      })
    }
    catch (err) {
      addToast(err.message, {
        appearance:'error',
        autoDismiss:true
      })
    }
    setLoading(false);
    navigate('/cases');
  }

  useEffect(() => {
    (async () => {
      setLoading(true)
      let session = await Auth.currentSession();

      const caseData = await byAxios({
        route: `${CASE_LAMBDAS.GET_DETAIL.url}/${searchParams.get(`caseId`)}`,
        method: CASE_LAMBDAS.GET_DETAIL.method,
        headerCred: {
          "x-access-token": session?.accessToken?.jwtToken,
          "x-id-token": session?.idToken?.jwtToken
        }
      });
      console.log('reviewEffect', caseData)
      
      
      if (caseData) {
        setReviewData({ ...caseData });
      }

      const downloadedProfileData = await byAxios({
        route: `${CASE_LAMBDAS.GET_PROFILE.url}`,
        method: CASE_LAMBDAS.GET_PROFILE.method,
        headerCred: {
          "x-access-token": session?.accessToken?.jwtToken,
          "x-id-token": session?.idToken?.jwtToken
        }
      });      
      dispatch(broker(downloadedProfileData))
      setProfileData(downloadedProfileData)    

      setLoading(false)
    })()
  }, []);

  return (
    <body class={`${sidebar ? 'overlay-body' : ''}`} style={{width:'100%'}}>
  <Box>
      {
        loading ?
        <div id="preloader"> </div> :
        <div id="preloader" style={{display: 'none'}}></div>
      }
      
        <Header/>
     
       <div class="home" style={{backgroundColor:'#fafafa' , marginTop:'120px' , paddingBottom:'60px'}}>


<Grid item xs={12} style={{paddingLeft:'40px'}}>
            <NavBar>
              <Link to='/cases' style={{
                color: 'black'
              }}>
                <Typography>Cases</Typography>
              </Link>
             
                <Typography style={{cursor:'pointer'}} onClick={()=> { navigate(`/cases/create?caseId=${reviewData?.clientId}`)}}>
                  &nbsp;&nbsp;/&nbsp;&nbsp;Edit Your case</Typography>
            
            </NavBar>
            </Grid>
      <Typography variant='body2' sx={{
        p: '20px 0px',
        textAlign: 'left'
      }}>
       
      </Typography>


     
        <Grid
          container
          direction='row'
          alignItems={`stretch`}
          spacing={2}
          style={{backgroundColor:'#fafafa' , paddingLeft:'80px'}}
          >
            <Grid item
            xs={12}
            direction='column'
            justifyContent={`center`}>
              <p style={{textAlign:'center'}}> Case for </p>
              <h2 style={{textAlign:'center'}}> {reviewData?.firstName ? reviewData?.firstName : ''} {reviewData?.lastName ? reviewData?.lastName : ''} </h2>
            </Grid>
          <Grid item
              xs={8} sm={8} lg={4} md={4}
              direction='column'
              >
              <Grid style={{backgroundColor:'white', padding:'5px', paddingLeft:'25px',marginBottom:'20px'}}>
                <p style={{fontSize:'12px'}}> Your Bandenia Contact</p>
                <Grid style={{display:'flex' , flexDirection:'row'}}>
                <Grid item
                            >
                            <img src="/assets/images/logo_bandenia.jpg" alt="" style={{height:'100px'}} />
                </Grid>                      
                {
                        profileData?.assigned_user?<Grid xs={8} sm={10} lg={6} md={6}>
                            <p> 
                            <span style={{fontWeight:'bold'}}>
                            &nbsp;&nbsp;{profileData?.assigned_user['name']['value']}
                            </span>                            
                            <br/>
                            <a href="mailto:shahid@bcf.ae">
                            <span style={{fontSize:'12px' , color:'blue'}}>
                            &nbsp;&nbsp;{profileData?.assigned_user['email1']['value']}
                            </span>
                            </a>
                            </p>
                            &nbsp;&nbsp;<a href={"https://wa.me/" + profileData?.assigned_user['phone_mobile']['value']+"?text="} target="_blank" style={{color:'black'}}> <WhatsAppIcon/></a> <a href={"tel:"+profileData?.assigned_user['phone_mobile']['value']} style={{color:'black'}}> <CallIcon/>  </a>   
                        </Grid>:
                        <Grid xs={8} sm={10} lg={6} md={6}>
                        <p> 
                          <span style={{fontWeight:'bold'}}>
                        &nbsp;&nbsp;Shahid Khan
                        </span>
                        <br/>
                        <span style={{fontSize:'12px'}}>
                        &nbsp;&nbsp;Mortgage Specialist
                        </span>
                        <br/>
                        <a href="mailto:shahid@bcf.ae">
                        <span style={{fontSize:'12px' , color:'blue'}}>
                        &nbsp;&nbsp; shahid@bcf.ae
                        </span>
                        </a>
                        </p>
                        &nbsp;&nbsp;<a href="https://wa.me/+971503786701?text=" target="_blank" style={{color:'black'}}> <WhatsAppIcon/></a> <a href="tel:+971503786701" style={{color:'black'}}> <CallIcon/>  </a>   
                    </Grid>
                        }
                  </Grid>
              </Grid>
            <Grid style={{backgroundColor:'white', padding:'5px', paddingLeft:'25px',marginBottom:'20px'}}>
                <p style={{fontSize:'15px'}}>Case Status</p>
                <Grid style={{display:'flex' , flexDirection:'row' ,  pointerEvents:'none'}}>
                 <FormGroup>
               <FormControlLabel disabled control={<Checkbox checked={reviewData?.submitted_to_crm ? true : false}/>} label="Submitted to CRM" />
               <FormControlLabel disabled control={<Checkbox checked={reviewData?.submitted_to_bank ? true : false}/>} label="Submitted To Bank" />
               <FormControlLabel disabled control={<Checkbox checked={reviewData?.submitted_to_credit ? true : false}/>} label="Submitted To Credit" />
               <FormControlLabel disabled control={<Checkbox checked={reviewData?.awaiting_preapproval ? true : false}/>} label="Awaiting Preapproval" />
               <FormControlLabel disabled control={<Checkbox checked={reviewData?.preapproved ? true : false}/>} label="Preapproved" />
               <FormControlLabel disabled control={<Checkbox checked={reviewData?.valuation_initiated ? true : false}/>} label="Valuation Initiated" />
               <FormControlLabel disabled control={<Checkbox checked={reviewData?.fol_requested ? true : false}/>} label="Fol Requested" />
               <FormControlLabel disabled control={<Checkbox checked={reviewData?.fol_signed ? true : false}/>} label="Fol Signed" />
               <FormControlLabel disabled control={<Checkbox checked={reviewData?.disbursed ? true : false}/>} label="Fol Disbursed" />
                </FormGroup> 
                  </Grid>
              </Grid>
              <Grid style={{backgroundColor:'white', padding:'5px', paddingLeft:'25px'}}>
                  <p style={{fontWeight:'bold'}}> Case Comments </p>
                </Grid>
                <Grid container direction='row'  style={{backgroundColor:'white', padding:'10px', paddingLeft:'20px', marginTop:'5px',marginBottom:'20px'}}>
                <TextField
                     
                      name="comment"
                      required
                      fullWidth
                      id="comment"
                      label=""
                      onChange={(e)=>handleComment(e)}
                      value={ comment}
                      //error={errors.comment ? true : false}
                      //helperText={errors.comment}
                     // onBlur={handleBlur}
                      placeholder='Write Comment'
                     // disabled={local?.comment ? true : false}
                      sx={{
                        color: 'black'
                      }}
                    />
                      
                  </Grid>
          </Grid>
      {/* another column */}
          <Grid item
             xs={8} sm={8} lg={7} md={4}
              direction='column'>
                <Grid style={{backgroundColor:'white', padding:'5px', paddingLeft:'25px'}}>
                  <p style={{fontWeight:'bold'}}> Main Applicant </p>
                </Grid>
                <Grid container direction='row'  style={{backgroundColor:'white', padding:'10px', paddingLeft:'20px', marginTop:'5px',marginBottom:'20px'}}>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> First Name </p>
                      <p style={{fontSize:'14px',}}>{reviewData?.firstName ? reviewData?.firstName : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Last Name </p>
                      <p style={{fontSize:'14px',}}> {reviewData?.lastName ? reviewData?.lastName : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={12} sm={12} lg={6} md={12}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Email </p>
                      <p style={{fontSize:'14px',}}>{reviewData?.email ? reviewData?.email : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={12} sm={12} lg={6} md={12}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Phone Number </p>
                      <p style={{fontSize:'14px',}}> {reviewData?.phoneNumber ? reviewData?.phoneNumber : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Date of Birth </p>
                      <p style={{fontSize:'14px',}}>{reviewData?.birthDate ? reviewData?.birthDate : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Nationality </p>
                      <p style={{fontSize:'14px',}}> {reviewData?.nationality ? reviewData?.nationality : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Employment Status </p>
                      <p style={{fontSize:'14px',}}>{reviewData?.employment ? reviewData?.employment : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Residency Status </p>
                      <p style={{fontSize:'14px',}}> {reviewData?.residency ? reviewData?.residency : '-'} </p>
                  </Grid>
               </Grid>
               {reviewData?.applicant ?
                <div> 
               {reviewData?.applicant[0] ? 
               <div>
               <Grid style={{backgroundColor:'white', padding:'5px', paddingLeft:'25px'}}>
               <p>
                  <span style={{fontWeight:'bold'}}> Co-Applicant # 1   </span> <br/>  
                  </p>
               </Grid>
               <Grid container direction='row'  style={{backgroundColor:'white', padding:'10px', paddingLeft:'20px', marginTop:'5px',marginBottom:'20px'}}>
               <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> First Name </p>
                      <p style={{fontSize:'14px',}}>{reviewData?.applicant[0].firstName ? reviewData?.applicant[0].firstName : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Last Name </p>
                      <p style={{fontSize:'14px',}}> {reviewData?.applicant[0].lastName ? reviewData?.applicant[0].lastName : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={12} sm={12} lg={6} md={12}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Email </p>
                      <p style={{fontSize:'14px',}}>{reviewData?.applicant[0].email ? reviewData?.applicant[0].email : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={12} sm={12} lg={6} md={12}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Phone Number </p>
                      <p style={{fontSize:'14px',}}> {reviewData?.applicant[0].phoneNumber ? reviewData?.applicant[0].phoneNumber : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Residency Status </p>
                      <p style={{fontSize:'14px',}}>{reviewData?.applicant[0].residency ? reviewData?.applicant[0].residency : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> RelationShip </p>
                      <p style={{fontSize:'14px',}}> {reviewData?.applicant[0].relationship ? reviewData?.applicant[0].relationship : '-'} </p>
                  </Grid>
               </Grid>
               </div>
               :
               <></>
               }
               {reviewData?.applicant[1] ? 
               <div>
               <Grid style={{backgroundColor:'white', padding:'5px', paddingLeft:'25px'}}>
               <p>
                  <span style={{fontWeight:'bold'}}> Co-Applicant # 2   </span> <br/>  
                  </p>
               </Grid>
               <Grid container direction='row'  style={{backgroundColor:'white', padding:'10px', paddingLeft:'20px', marginTop:'5px',marginBottom:'20px'}}>
               <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> First Name </p>
                      <p style={{fontSize:'14px',}}>{reviewData?.applicant[1].firstName ? reviewData?.applicant[1].firstName : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Last Name </p>
                      <p style={{fontSize:'14px',}}> {reviewData?.applicant[1].lastName ? reviewData?.applicant[1].lastName : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={12} sm={12} lg={6} md={12}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Email </p>
                      <p style={{fontSize:'14px',}}>{reviewData?.applicant[1].email ? reviewData?.applicant[1].email : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={12} sm={12} lg={6} md={12}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Phone Number </p>
                      <p style={{fontSize:'14px',}}> {reviewData?.applicant[1].phoneNumber ? reviewData?.applicant[1].phoneNumber : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Residency Status </p>
                      <p style={{fontSize:'14px',}}>{reviewData?.applicant[1].residency ? reviewData?.applicant[1].residency : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> RelationShip </p>
                      <p style={{fontSize:'14px',}}> {reviewData?.applicant[1].relationship ? reviewData?.applicant[1].relationship : '-'} </p>
                  </Grid>
               </Grid>
               </div>
               :
               <></>
               }
               {reviewData?.applicant[2] ? 
               <div>
               <Grid style={{backgroundColor:'white', padding:'5px', paddingLeft:'25px'}}>
               <p>
                  <span style={{fontWeight:'bold'}}> Co-Applicant # 3   </span> <br/>  
                  </p>
               </Grid>
               <Grid container direction='row'  style={{backgroundColor:'white', padding:'10px', paddingLeft:'20px', marginTop:'5px',marginBottom:'20px'}}>
               <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> First Name </p>
                      <p style={{fontSize:'14px',}}>{reviewData?.applicant[2].firstName ? reviewData?.applicant[2].firstName : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Last Name </p>
                      <p style={{fontSize:'14px',}}> {reviewData?.applicant[2].lastName ? reviewData?.applicant[2].lastName : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={12} sm={12} lg={6} md={12}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Email </p>
                      <p style={{fontSize:'14px',}}>{reviewData?.applicant[2].email ? reviewData?.applicant[2].email : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={12} sm={12} lg={6} md={12}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Phone Number </p>
                      <p style={{fontSize:'14px',}}> {reviewData?.applicant[2].phoneNumber ? reviewData?.applicant[2].phoneNumber : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Residency Status </p>
                      <p style={{fontSize:'14px',}}>{reviewData?.applicant[2].residency ? reviewData?.applicant[2].residency : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> RelationShip </p>
                      <p style={{fontSize:'14px',}}> {reviewData?.applicant[2].relationship ? reviewData?.applicant[2].relationship : '-'} </p>
                  </Grid>
               </Grid>
               </div>
               :
               <></>
               }
               {reviewData?.applicant[3] ? 
               <div>
               <Grid style={{backgroundColor:'white', padding:'5px', paddingLeft:'25px'}}>
               <p>
                  <span style={{fontWeight:'bold'}}> Co-Applicant # 4  </span> <br/>  
                  </p>
               </Grid>
               <Grid container direction='row'  style={{backgroundColor:'white', padding:'10px', paddingLeft:'20px', marginTop:'5px',marginBottom:'20px'}}>
               <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> First Name </p>
                      <p style={{fontSize:'14px',}}>{reviewData?.applicant[3].firstName ? reviewData?.applicant[3].firstName : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Last Name </p>
                      <p style={{fontSize:'14px',}}> {reviewData?.applicant[3].lastName ? reviewData?.applicant[3].lastName : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={12} sm={12} lg={6} md={12}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Email </p>
                      <p style={{fontSize:'14px',}}>{reviewData?.applicant[3].email ? reviewData?.applicant[3].email : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={12} sm={12} lg={6} md={12}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Phone Number </p>
                      <p style={{fontSize:'14px',}}> {reviewData?.applicant[3].phoneNumber ? reviewData?.applicant[3].phoneNumber : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Residency Status </p>
                      <p style={{fontSize:'14px',}}>{reviewData?.applicant[3].residency ? reviewData?.applicant[3].residency : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> RelationShip </p>
                      <p style={{fontSize:'14px',}}> {reviewData?.applicant[3].relationship ? reviewData?.applicant[3].relationship : '-'} </p>
                  </Grid>
               </Grid>
               </div>
               :
               <></>
               }
               {reviewData?.applicant[4] ? 
               <div>
               <Grid style={{backgroundColor:'white', padding:'5px', paddingLeft:'25px'}}>
               <p>
                  <span style={{fontWeight:'bold'}}> Co-Applicant # 5   </span> <br/>  
                  </p>
               </Grid>
               <Grid container direction='row'  style={{backgroundColor:'white', padding:'10px', paddingLeft:'20px', marginTop:'5px',marginBottom:'20px'}}>
               <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> First Name </p>
                      <p style={{fontSize:'14px',}}>{reviewData?.applicant[4].firstName ? reviewData?.applicant[4].firstName : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Last Name </p>
                      <p style={{fontSize:'14px',}}> {reviewData?.applicant[4].lastName ? reviewData?.applicant[4].lastName : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={12} sm={12} lg={6} md={12}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Email </p>
                      <p style={{fontSize:'14px',}}>{reviewData?.applicant[4].email ? reviewData?.applicant[4].email : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={12} sm={12} lg={6} md={12}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Phone Number </p>
                      <p style={{fontSize:'14px',}}> {reviewData?.applicant[4].phoneNumber ? reviewData?.applicant[4].phoneNumber : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Residency Status </p>
                      <p style={{fontSize:'14px',}}>{reviewData?.applicant[4].residency ? reviewData?.applicant[4].residency : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> RelationShip </p>
                      <p style={{fontSize:'14px',}}> {reviewData?.applicant[4].relationship ? reviewData?.applicant[4].relationship : '-'} </p>
                  </Grid>
               </Grid>
               </div>
               :
               <></>
               }
               </div> 
               :
                <></>}
                 {reviewData?.borrower ? 
                <div>
               {reviewData?.borrower[0] ? 
               <div>
               <Grid style={{backgroundColor:'white', padding:'5px', paddingLeft:'25px'}}>
               <p>
                  <span style={{fontWeight:'bold'}}>  Co-Borrower # 1 </span> <br/>
                   
                  </p>
               </Grid>
               <Grid container direction='row'  style={{backgroundColor:'white', padding:'10px', paddingLeft:'20px', marginTop:'5px',marginBottom:'20px'}}>
               <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> First Name </p>
                      <p style={{fontSize:'14px',}}>{reviewData?.borrower[0].firstName ? reviewData?.borrower[0].firstName : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Last Name </p>
                      <p style={{fontSize:'14px',}}> {reviewData?.borrower[0].lastName ? reviewData?.borrower[0].lastName : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={12} sm={12} lg={6} md={12}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Email </p>
                      <p style={{fontSize:'14px',}}>{reviewData?.borrower[0].email ? reviewData?.borrower[0].email : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={12} sm={12} lg={6} md={12}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Phone Number </p>
                      <p style={{fontSize:'14px',}}> {reviewData?.borrower[0].phoneNumber ? reviewData?.borrower[0].phoneNumber : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Residency Status </p>
                      <p style={{fontSize:'14px',}}>{reviewData?.borrower[0].residency ? reviewData?.borrower[0].residency : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> RelationShip </p>
                      <p style={{fontSize:'14px',}}> {reviewData?.borrower[0].relationship ? reviewData?.borrower[0].relationship : '-'} </p>
                  </Grid>
               </Grid>
               </div>
               :
               <></>
               }

            {reviewData?.borrower[1] ? 
               <div>
               <Grid style={{backgroundColor:'white', padding:'5px', paddingLeft:'25px'}}>
               <p>
                  <span style={{fontWeight:'bold'}}>  Co-Borrower # 2 </span> <br/>
                   
                  </p>
               </Grid>
               <Grid container direction='row'  style={{backgroundColor:'white', padding:'10px', paddingLeft:'20px', marginTop:'5px',marginBottom:'20px'}}>
               <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> First Name </p>
                      <p style={{fontSize:'14px',}}>{reviewData?.borrower[1].firstName ? reviewData?.borrower[1].firstName : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Last Name </p>
                      <p style={{fontSize:'14px',}}> {reviewData?.borrower[1].lastName ? reviewData?.borrower[1].lastName : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={12} sm={12} lg={6} md={12}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Email </p>
                      <p style={{fontSize:'14px',}}>{reviewData?.borrower[1].email ? reviewData?.borrower[1].email : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={12} sm={12} lg={6} md={12}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Phone Number </p>
                      <p style={{fontSize:'14px',}}> {reviewData?.borrower[1].phoneNumber ? reviewData?.borrower[1].phoneNumber : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Residency Status </p>
                      <p style={{fontSize:'14px',}}>{reviewData?.borrower[1].residency ? reviewData?.borrower[1].residency : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> RelationShip </p>
                      <p style={{fontSize:'14px',}}> {reviewData?.borrower[1].relationship ? reviewData?.borrower[1].relationship : '-'} </p>
                  </Grid>
               </Grid>
               </div>
               :
               <></>
               }
               {reviewData?.borrower[2] ? 
               <div>
               <Grid style={{backgroundColor:'white', padding:'5px', paddingLeft:'25px'}}>
               <p>
                  <span style={{fontWeight:'bold'}}>  Co-Borrower # 3 </span> <br/>
                   
                  </p>
               </Grid>
               <Grid container direction='row'  style={{backgroundColor:'white', padding:'10px', paddingLeft:'20px', marginTop:'5px',marginBottom:'20px'}}>
               <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> First Name </p>
                      <p style={{fontSize:'14px',}}>{reviewData?.borrower[2].firstName ? reviewData?.borrower[2].firstName : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Last Name </p>
                      <p style={{fontSize:'14px',}}> {reviewData?.borrower[2].lastName ? reviewData?.borrower[2].lastName : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={12} sm={12} lg={6} md={12}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Email </p>
                      <p style={{fontSize:'14px',}}>{reviewData?.borrower[2].email ? reviewData?.borrower[2].email : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={12} sm={12} lg={6} md={12}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Phone Number </p>
                      <p style={{fontSize:'14px',}}> {reviewData?.borrower[2].phoneNumber ? reviewData?.borrower[2].phoneNumber : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Residency Status </p>
                      <p style={{fontSize:'14px',}}>{reviewData?.borrower[2].residency ? reviewData?.borrower[2].residency : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> RelationShip </p>
                      <p style={{fontSize:'14px',}}> {reviewData?.borrower[2].relationship ? reviewData?.borrower[2].relationship : '-'} </p>
                  </Grid>
               </Grid>
               </div>
               :
               <></>
               }
               {reviewData?.borrower[3] ? 
               <div>
               <Grid style={{backgroundColor:'white', padding:'5px', paddingLeft:'25px'}}>
               <p>
                  <span style={{fontWeight:'bold'}}>  Co-Borrower # 4 </span> <br/>
                   
                  </p>
               </Grid>
               <Grid container direction='row'  style={{backgroundColor:'white', padding:'10px', paddingLeft:'20px', marginTop:'5px',marginBottom:'20px'}}>
               <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> First Name </p>
                      <p style={{fontSize:'14px',}}>{reviewData?.borrower[3].firstName ? reviewData?.borrower[3].firstName : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Last Name </p>
                      <p style={{fontSize:'14px',}}> {reviewData?.borrower[3].lastName ? reviewData?.borrower[3].lastName : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={12} sm={12} lg={6} md={12}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Email </p>
                      <p style={{fontSize:'14px',}}>{reviewData?.borrower[3].email ? reviewData?.borrower[3].email : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={12} sm={12} lg={6} md={12}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Phone Number </p>
                      <p style={{fontSize:'14px',}}> {reviewData?.borrower[3].phoneNumber ? reviewData?.borrower[3].phoneNumber : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Residency Status </p>
                      <p style={{fontSize:'14px',}}>{reviewData?.borrower[3].residency ? reviewData?.borrower[3].residency : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> RelationShip </p>
                      <p style={{fontSize:'14px',}}> {reviewData?.borrower[3].relationship ? reviewData?.borrower[3].relationship : '-'} </p>
                  </Grid>
               </Grid>
               </div>
               :
               <></>
               }
               {reviewData?.borrower[4] ? 
               <div>
               <Grid style={{backgroundColor:'white', padding:'5px', paddingLeft:'25px'}}>
               <p>
                  <span style={{fontWeight:'bold'}}>  Co-Borrower # 5 </span> <br/>
                   
                  </p>
               </Grid>
               <Grid container direction='row'  style={{backgroundColor:'white', padding:'10px', paddingLeft:'20px', marginTop:'5px',marginBottom:'20px'}}>
               <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> First Name </p>
                      <p style={{fontSize:'14px',}}>{reviewData?.borrower[4].firstName ? reviewData?.borrower[4].firstName : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Last Name </p>
                      <p style={{fontSize:'14px',}}> {reviewData?.borrower[4].lastName ? reviewData?.borrower[4].lastName : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={12} sm={12} lg={6} md={12}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Email </p>
                      <p style={{fontSize:'14px',}}>{reviewData?.borrower[4].email ? reviewData?.borrower[4].email : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={12} sm={12} lg={6} md={12}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Phone Number </p>
                      <p style={{fontSize:'14px',}}> {reviewData?.borrower[4].phoneNumber ? reviewData?.borrower[4].phoneNumber : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Residency Status </p>
                      <p style={{fontSize:'14px',}}>{reviewData?.borrower[4].residency ? reviewData?.borrower[4].residency : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> RelationShip </p>
                      <p style={{fontSize:'14px',}}> {reviewData?.borrower[4].relationship ? reviewData?.borrower[4].relationship : '-'} </p>
                  </Grid>
               </Grid>
               </div>
               :
               <></>
               }
               </div>
                : 
                <></>
                } 
              
               {reviewData?.monthlySalary ? 
               <div>
               <Grid style={{backgroundColor:'white', padding:'5px', paddingLeft:'25px'}}>
               <p>
                  <span style={{fontWeight:'bold'}}> Income for Main Applicant  </span> <br/>
                    <span style={{fontSize:'12px', color:'#a9b5b8'}}>
                      {reviewData?.firstName ? reviewData?.firstName : ''} {reviewData?.lastName ? reviewData?.lastName : ''}
                    </span>
                  </p>
               </Grid>
               <Grid container direction='row'  style={{backgroundColor:'white', padding:'10px', paddingLeft:'20px', marginTop:'5px',marginBottom:'20px'}}>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Fixed monthly salary </p>
                      <p style={{fontSize:'14px',}}>{reviewData?.monthlySalary ? reviewData?.monthlySalary : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Additional income </p>
                      <p style={{fontSize:'14px',}}> {reviewData?.addIncome ? reviewData?.addIncome : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Additional income type </p>
                      <p style={{fontSize:'14px',}}>{reviewData?.addIncomeType ? reviewData?.addIncomeType : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Total credit card limits </p>
                      <p style={{fontSize:'14px',}}> {reviewData?.creditCard ? reviewData?.creditCard : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={12}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Monthly fixed installments </p>
                      <p style={{fontSize:'14px',}}>{reviewData?.monthlyInstallments ? reviewData?.monthlyInstallments : '-'} </p>
                  </Grid>
               </Grid>
               </div>
               :
               <></>
               }
               {reviewData?.mortgageType ? 
                <div>
               <Grid style={{backgroundColor:'white', padding:'5px', paddingLeft:'25px'}}>
                  <p style={{fontWeight:'bold'}}> Mortgage </p>
                </Grid>
                <Grid container direction='row'  style={{backgroundColor:'white', padding:'10px', paddingLeft:'20px', marginTop:'5px',marginBottom:'20px'}}>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Emirate? </p>
                      <p style={{fontSize:'14px',}}>{reviewData?.emirate ? reviewData?.emirate : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Transaction type </p>
                      <p style={{fontSize:'14px',}}> {reviewData?.transactionType ? reviewData?.transactionType : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Mortgage type </p>
                      <p style={{fontSize:'14px',}}>{reviewData?.mortgageType ? reviewData?.mortgageType : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Property Status </p>
                      <p style={{fontSize:'14px',}}> {reviewData?.propertyStatus ? reviewData?.propertyStatus : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Property value </p>
                      <p style={{fontSize:'14px',}}>{reviewData?.propertyValue ? reviewData?.propertyValue : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Downpayment amount </p>
                      <p style={{fontSize:'14px',}}> {reviewData?.downPayment ? reviewData?.downPayment : '-'} </p>
                  </Grid>
                 
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Mortgage Length </p>
                      <p style={{fontSize:'14px',}}> {reviewData?.mortgageLength ? reviewData?.mortgageLength : '-'} </p>
                  </Grid>
                  <Grid direction='column' xs={6}>
                      <p style={{fontSize:'12px', color:'#a9b5b8'}}> Add Residual/Associate fee? </p>
                      <p style={{fontSize:'14px',}}> {reviewData?.residualFee ? reviewData?.residualFee : '-'} </p>
                  </Grid>
               </Grid>
               </div>
               :
               <></>
                }
                { reviewData?.visa1_path || reviewData?.audit1_path || reviewData?.salary1_path || reviewData?.pass1_path || reviewData?.EID1_path || reviewData?.bank1_path || reviewData?.credit1_path || reviewData?.MOA1_path || reviewData?.offerLetter1_path || reviewData?.income1_path || reviewData?.trade1_path || reviewData?.share1_path ? 
                <div>
               <Grid style={{backgroundColor:'white', padding:'5px', paddingLeft:'25px'}}>
                <p>
                  <span style={{fontWeight:'bold'}}> Main applicant's documents  </span> <br/>
                    <span style={{fontSize:'12px', color:'#a9b5b8'}}>
                      {reviewData?.firstName ? reviewData?.firstName : ''} {reviewData?.lastName ? reviewData?.lastName : ''}
                    </span>
                    </p>
                </Grid>
                <Grid container direction='row'  style={{backgroundColor:'white', padding:'10px', paddingLeft:'20px', marginTop:'5px',marginBottom:'20px'}}>
                  <Grid direction='column' xs={12}>
                      <p style={{fontSize:'12px',color:'#a9b5b8'}}> Uncategorized </p>
                      <p style={{fontSize:'14px' , textDecoration:'underline' }}> {reviewData?.visa1_path ? reviewData?.visa1_path : ''} </p>
                      <p style={{fontSize:'14px' , textDecoration:'underline' }}> {reviewData?.visa2_path ? reviewData?.visa2_path : ''} </p>
                      <p style={{fontSize:'12px' , textDecoration:'underline' }}> {reviewData?.visa3_path ? reviewData?.visa3_path : ''} </p>
                      <p style={{fontSize:'12px' , textDecoration:'underline' }}> {reviewData?.visa4_path ? reviewData?.visa4_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.salary1_path ? reviewData?.salary1_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.salary2_path ? reviewData?.salary2_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.salary3_path ? reviewData?.salary3_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.salary4_path ? reviewData?.salary4_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.pass1_path ? reviewData?.pass1_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.pass2_path ? reviewData?.pass2_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.pass3_path ? reviewData?.pass3_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.pass4_path ? reviewData?.pass4_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.EID1_path ? reviewData?.EID1_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.EID2_path ? reviewData?.EID2_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.EID3_path ? reviewData?.EID3_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.EID4_path ? reviewData?.EID4_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.bank1_path ? reviewData?.bank1_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.bank2_path ? reviewData?.bank2_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.bank3_path ? reviewData?.bank3_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.bank4_path ? reviewData?.bank4_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.earning1_path ? reviewData?.earning1_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.earning2_path ? reviewData?.earning2_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.earning3_path ? reviewData?.earning3_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.earning4_path ? reviewData?.earning4_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.other1_path ? reviewData?.other1_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.other2_path ? reviewData?.other2_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.other3_path ? reviewData?.other3_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.other4_path ? reviewData?.other4_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.MOA1_path ? reviewData?.MOA1_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.MOA2_path ? reviewData?.MOA2_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.MOA3_path ? reviewData?.MOA3_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.MOA4_path ? reviewData?.MOA4_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.credit1_path ? reviewData?.credit1_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.credit2_path ? reviewData?.credit2_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.credit3_path ? reviewData?.credit3_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.credit4_path ? reviewData?.credit4_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.audit1_path ? reviewData?.audit1_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.audit2_path ? reviewData?.audit2_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.audit3_path ? reviewData?.audit3_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.audit4_path ? reviewData?.audit4_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.trade1_path ? reviewData?.trade1_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.trade2_path ? reviewData?.trade2_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.trade3_path ? reviewData?.trade3_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.trade4_path ? reviewData?.trade4_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.share1_path ? reviewData?.share1_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.share2_path ? reviewData?.share2_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.share3_path ? reviewData?.share3_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.share4_path ? reviewData?.share4_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.offerLetter1_path ? reviewData?.offerLetter1_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.offerLetter2_path ? reviewData?.offerLetter2_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.offerLetter3_path ? reviewData?.offerLetter3_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.offerLetter4_path ? reviewData?.offerLetter4_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.income1_path ? reviewData?.income1_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.income2_path ? reviewData?.income2_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.income3_path ? reviewData?.income3_path : ''} </p>
                      <p style={{fontSize:'14px', textDecoration:'underline'}}> {reviewData?.income4_path ? reviewData?.income4_path : ''} </p> 
                  </Grid>
               </Grid>
               </div> 
               :
               <></>
                }
          </Grid>
        </Grid>

      {/* <Box
        sx={{
          p: '30px 0px',
          display: 'flex',
          justifyContent: 'flex-end'
        }}>
        <SendButton
          mw={150}
          onClick={() => {
            handleReview();
          }}

        >
          {loading && <CircularProgress />}
          Submit
        </SendButton>
       
      </Box> */}
      </div>
   
    </Box>
    </body>
    
    
  )
}

export default Review