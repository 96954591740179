import React, { Fragment, useState, useRef } from 'react';
import { useNavigate , Link} from 'react-router-dom';
import { Auth } from 'aws-amplify';
import {
  Grid,
  Typography,
  styled,
  Box,
  Button  
} from '@mui/material';
import Sidebar from '../../layout/sidebar'
import { useToasts } from 'react-toast-notifications';
import './index.css';
import { Dropdown } from 'react-bootstrap';
import Header from '../firstview/Header';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField'
import { broker } from '../../actions/broker';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import { visuallyHidden } from '@mui/utils';
import { useEffect } from 'react';
import byAxios from './../../services/byAxios';
import { CASE_LAMBDAS } from './../../configs/lambdas';
import { set } from 'react-hook-form';
import { Storage } from "aws-amplify";
import { ConsoleNetwork } from 'mdi-material-ui';
import { useDispatch } from 'react-redux';
import CheckCircle from '@mui/icons-material/CheckCircle';
import AccountCircle from '@mui/icons-material/AccountCircle';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CallIcon from '@mui/icons-material/Call';
import TelegramIcon from '@mui/icons-material/Telegram';

const options = [
  'not_submitted',
  'submitted_to_bank',
  'submitted_to_credit',
  'awaiting_preapproval',
  'preapproved',
  'valuation_initiated',
  'fol_requested',
  'fol_signed',
  'disbursed'
];

const CreateButton = styled(Button)(({ theme }) => ({
  maxWidth: '230px',
  width: '100%',
  height: '40px',
  padding: '5px 10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'none',
  background: '#6E4EE7',
  color: 'white',
  '&:hover': {
    boxShadow: '0px 0px 10px black',
    color: 'black'
  }
}))

const CustHeader = styled(Box)(({ theme }) => ({
  width:'100%',
  height: '10%',
  padding: '10px 0px 0px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}))

const CustTypography = styled(Typography)(({ theme }) => ({
  textTransform: 'none',
}))

const TabTitle = styled(Typography)(({ theme }) => ({
  textTransform: 'none',
}))

const TabButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.secondary,
  display: 'flex',
  width:'20%',
  '&:focus': {
    color: '#6E4EE7',
    fontWeight: 600,
    borderBottom: '5px solid #6E4EE7',
    borderColor: 'none',
    'div': {
      borderRadius: '0px',
      width: '15px',
      background: '#6E4EE7',
      color: 'white',
      padding: '0px 3px',
      marginLeft: '10px'
    }
  },
}))

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'created',
    numeric: true,
    disablePadding: false,
    label: 'Created on',
  },
  {
    id: 'loan',
    numeric: true,
    disablePadding: false,
    label: 'Loan amount',
  },
  {
    id: 'bank',
    numeric: true,
    disablePadding: false,
    label: 'Bank',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              sx={{fontWeight:700}}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="div" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >

    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const Profile = () => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');

  const [profileData, setProfileData] = useState({name:"",email:"",companyName:"",rm:"",phone_office:"",trade_license_path:""});
  console.log('profileData',profileData)
  const { addToast } = useToasts()

  const [isSave, setIsSave] = useState(false)
  const [loading, setLoading] = useState(false)
  const [license, setLicense] = useState(null)
  const [id, setID] = useState(null)
  const [passport, setPassport] = useState(null)
  const [agreement, setAgreement] = useState(null)
  const dispatch = useDispatch();
  const[sidebar, setSidebar]=useState(false)
  const showSidebar=()=>{
         setSidebar(true)
  }
  const hideSidebar=()=>{
      setSidebar(false)
}

  useEffect(() => {
    (async () => {
      setLoading(true);
      let session = await Auth.currentSession();
      console.log(`session`, session);
      const downloadedProfileData = await byAxios({
        route: `${CASE_LAMBDAS.GET_PROFILE.url}`,
        method: CASE_LAMBDAS.GET_PROFILE.method,
        headerCred: {
          "x-access-token": session?.accessToken?.jwtToken,
          "x-id-token": session?.idToken?.jwtToken
        }
      });      
      dispatch(broker(downloadedProfileData))
      setProfileData(downloadedProfileData)      
      setLoading(false);
    })()
  }, []);


  const saveButtonAction = async(event, index) => {
    setLoading(true)
    let result = await handleUpload();
    let session = await Auth.currentSession();    
    const updateRes = await byAxios({
      route: CASE_LAMBDAS.UPDATE_PROFILE.url,
      method: CASE_LAMBDAS.UPDATE_PROFILE.method,
      headerCred: {
        "x-access-token": session?.accessToken?.jwtToken,
        "x-id-token": session?.idToken?.jwtToken
      },
      data: {
        ...profileData,
        ...result
      }
    });
    dispatch(broker({
      ...profileData,
      ...result
    }))
    setLoading(false)
    //navigate('./profile')
    setIsSave(false)
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleChange = (e, index) => {
    console.log("handleChange")
    setIsSave(true)
    const { name, value } = e.target;    
    setProfileData(() => ({
        ...profileData,
        [name]: value
      }));    
  };

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('created');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [emptyRows, setEmptyRows] = React.useState(0);
  
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleLicense = (e) => {
    console.log('e', e)
    if(e.target.files[0].size <= 26214400) {
      setLicense(e.target.files[0])
      setIsSave(true)
    }
    else {
      addToast('You can not select files larger than 25MB', {
        appearance:'error',
        autoDismiss:true
      })
    }
  }

  const handleAgreement = (e) => {
    console.log('e', e)
    if(e.target.files[0].size <= 26214400) {
      setAgreement(e.target.files[0])
      setIsSave(true)
    }
    else {
      addToast('You can not select files larger than 25MB', {
        appearance:'error',
        autoDismiss:true
      })
    }
  }

  const handleID = (e) => {
    if(e.target.files[0].size <= 26214400) {
      setID(e.target.files[0])
      setIsSave(true)
    }
    else {
      addToast('You can not select files larger than 25MB', {
        appearance:'error',
        autoDismiss:true
      })
    }
  }
  const handlePassport = (e) => {
    if(e.target.files[0].size <= 26214400) {
      setIsSave(true)
      setPassport(e.target.files[0])
    }
    else {
      addToast('You can not select files larger than 25MB', {
        appearance:'error',
        autoDismiss:true
      })
    }
  }

  const handleUpload = async() => {
    setLoading(true)
    let path1="";
    let path2="";
    let path3="";
    let path4="";
    let pathDataDictionary = {}
    try {      
      let session = await Auth.currentSession();
      let username=await session.getIdToken().payload.sub;        
      
      if (license!=null) {
          let fileExt1 = license?.name.split('.').pop()
          path1=username + "/" + `trade_license.`+ fileExt1
          console.log("path1")
          console.log(path1)
          await Storage.put(path1, license, {
            contentType: license.type,
          });          
      }
      if (agreement!=null) {
        let fileExt4 = agreement?.name.split('.').pop()
        path4=username + "/" + `agreement.`+ fileExt4
        console.log("path4")
        console.log(path4)
        await Storage.put(path4, agreement, {
          contentType: agreement.type,
        });          
    }
      if (id!=null) {
        let fileExt2 = id?.name.split('.').pop()
        path2=username + "/" + `ID.`+ fileExt2
        await Storage.put(path2, id, {
          contentType: id.type,
        });
      }
      if (passport!=null) {
        let fileExt3 = passport?.name.split('.').pop()
        path3=username + "/" + `passport.`+ fileExt3
        await Storage.put(path3, passport, {
          contentType: passport.type,
        });
      }    
      pathDataDictionary={
        "trade_license_path":path1 ? path1 : profileData.trade_license_path,
        "id_path":path2 ? path2 : profileData.id_path,
        "passport_path":path3 ? path3 : profileData.passport_path,
        "agreement_path":path4 ? path4 : profileData.agreement_path,
      }
      
      addToast('Your files are uploaded successfully', {
        appearance:'success',
        autoDismiss:true
      })
    }
    catch(err){
      addToast(err.message, {
        appearance:'error',
        autoDismiss:true
      })
    }    
    setLoading(false)
    return Promise.resolve(pathDataDictionary)
  }


  const isSelected = (name) => selected.indexOf(name) !== -1;


 

  return (
    <body class={`${sidebar ? 'overlay-body' : ''}`} style={{width:'100%'}}>
      {
        loading ?
        <div id="preloader"> </div> :
        <div id="preloader" style={{display: 'none'}}></div>
      }
      
         <Header/>
     
       <div class="home" style={{marginTop:'120px', paddingBottom:'60px'}}>

      <Box sx={{ paddingBottom: '0px' , paddingLeft:'40px' }}>
        <Grid item>
          <CustHeader>
            <Box>
              <CustTypography variant='h4' sx={{
                color: 'black',
                fontSize: { sm: '48px', xs: '40px' }
              }}>
                Profile
              </CustTypography>
              <CustTypography variant='body1' sx={{
                color: 'black',
                fontSize: { sm: '16px', xs: '14px' }
              }}
              >
                <br/>
              </CustTypography>
            </Box>
            <CreateButton disabled={!isSave} onClick={saveButtonAction}>
                Save
            </CreateButton>
          </CustHeader>
        </Grid>        
          
        <Grid container spacing={5}
        direction='row'>
          <Grid item
              xs={12} sm={6} lg={6} md={6}
              direction='column'
              >
                  <Grid item ss={6} style={{marginBottom:'13px'}}>
                    <Typography variant='body2'>Name</Typography>
                    <TextField
                      autoComplete="given-name"
                      name="name"
                      required
                      fullWidth
                      id="firstName"
                      onChange={handleChange}
                      label=""                      
                      value={profileData?.name}                      
                      placeholder='Company Name'                      
                      sx={{
                        color: 'black'
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} ss={6} style={{marginBottom:'13px'}}>
                    <Typography variant='body2'>Company Name</Typography>
                    <TextField
                      autoComplete="given-name"
                      name="company_c"
                      required
                      fullWidth
                      id="firstName"                      
                      label=""                      
                      value={profileData?.company_c}
                      onChange={handleChange}
                      placeholder='Company Name'                      
                      sx={{
                        color: 'black'
                      }}
                    />
                  </Grid> 
                  <Grid item  ss={6} style={{marginBottom:'13px'}}>
                    <Typography variant='body2'>Email Address</Typography>
                    <TextField
                      autoComplete="given-name"
                      onChange={handleChange}
                      name="email"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CheckCircle color="success" />
                          </InputAdornment>
                        ),
                      }}                      
                      required
                      fullWidth
                      id="email"
                      label=""
                      disabled={true}                      
                      value={profileData?.email}
                      placeholder='Email Address'                      
                      sx={{
                        color: 'black'
                      }}
                    />
                  </Grid> 
                  <Grid item xs={12} ss={6} style={{marginBottom:'13px'}}>
                    <Typography variant='body2'>Phone</Typography>
                    <TextField
                      autoComplete="given-name"
                      name="phone_office"
                      required
                      fullWidth
                      disabled={true}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CheckCircle color="success" />
                          </InputAdornment>
                        ),
                      }}                      
                      id="firstName"
                      label=""
                      onChange={handleChange}
                      value={profileData?.phone_office}
                      placeholder='Relationship Manager'                      
                      sx={{
                        color: 'black'
                      }}
                    />
                  </Grid>        
                </Grid>
                <Grid item
              xs={12} sm={6} lg={6} md={6}
              direction='column'
              >
                  <Grid item xs={12} ss={6}>
                    <Typography variant='body2'>Relationship Manager</Typography>
                    {/* <TextField
                      autoComplete="given-name"
                      name="rm"
                      required
                      fullWidth
                      id="firstName"
                      label=""                      
                      disabled={true}                 
                      value={profileData?.rm?profileData?.rm:"Not Assigned"}
                      placeholder='Relationship Manager'                      
                      sx={{
                        color: 'black'
                      }}
                    /> */}
                    <Grid style={{display:'flex' , flexDirection:'row'}}>
                        <Grid item
                            >
                            <img src="/assets/images/logo_bandenia.jpg" alt="" style={{height:'100px'}} />
                        </Grid>                      
                        {
                        profileData?.assigned_user?<Grid xs={8} sm={10} lg={6} md={6}>
                            <p> 
                            <span style={{fontWeight:'bold'}}>
                            &nbsp;&nbsp;{profileData?.assigned_user['name']['value']}
                            </span>                            
                            <br/>
                            <a href="mailto:shahid@bcf.ae">
                            <span style={{fontSize:'12px' , color:'blue'}}>
                            &nbsp;&nbsp;{profileData?.assigned_user['email1']['value']}
                            </span>
                            </a>
                            </p>
                            &nbsp;&nbsp;<a href={"https://wa.me/" + profileData?.assigned_user['phone_mobile']['value']+"?text="} target="_blank" style={{color:'black'}}> <WhatsAppIcon/></a> <a href={"tel:"+profileData?.assigned_user['phone_mobile']['value']} style={{color:'black'}}> <CallIcon/>  </a>   
                        </Grid>:
                        <Grid xs={8} sm={10} lg={6} md={6}>
                        <p> 
                          <span style={{fontWeight:'bold'}}>
                        &nbsp;&nbsp;Shahid Khan
                        </span>
                        <br/>
                        <span style={{fontSize:'12px'}}>
                        &nbsp;&nbsp;Mortgage Specialist
                        </span>
                        <br/>
                        <a href="mailto:shahid@bcf.ae">
                        <span style={{fontSize:'12px' , color:'blue'}}>
                        &nbsp;&nbsp; shahid@bcf.ae
                        </span>
                        </a>
                        </p>
                        &nbsp;&nbsp;<a href="https://wa.me/+971503786701?text=" target="_blank" style={{color:'black'}}> <WhatsAppIcon/></a> <a href="tel:+971503786701" style={{color:'black'}}> <CallIcon/>  </a>   
                    </Grid>
                        }

                  </Grid>
                  </Grid>  
                 </Grid>                                                                                          
                </Grid>
        
      <br/>
      <Box  component="main"
      sx={{
        maxWidth: {xs:'80%',sm:'80%', md:'45%', lg:'45%'},
        width: '100%',        
      }}>
      <Grid container spacing={3}>
            <Grid item xs={12} style={{display:'flex', flexDirection:'column', gap:5}}>
              <Typography variant='body'>Trade license</Typography>
              <div style={{border: '1px solid lightgrey', padding: '10px',borderRadius: '4px'}}>
                <div>
                  <input type="file" id="license-btn" hidden onChange={e => handleLicense(e)} disabled={profileData.trade_license_path}/>
                  <label
                    htmlFor="license-btn" 
                    style={{
                      backgroundColor: '#6E4EE7',
                      color: 'white',
                      padding: '0.5rem',
                      fontFamily: 'sans-serif',
                      borderRadius: '0.3rem',
                      cursor: 'pointer',
                      marginTop: '1rem'
                    }}
                  >
                    Choose File
                  </label>
                  <span id="file-chosen" style={{marginLeft: '1rem', fontFamily: 'sans-serif'}}>
                    {license?.name ? license.name : profileData.trade_license_path?profileData.trade_license_path:""}
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} style={{display:'flex', flexDirection:'column', gap:5}}>
              <Typography variant='body'>ID</Typography>
              <div style={{border: '1px solid lightgrey', padding: '10px',borderRadius: '4px'}}>
                <div>
                  <input type="file" id="id-btn" hidden onChange={e => handleID(e)} disabled={profileData.id_path}/>
                  <label 
                    htmlFor="id-btn"                     
                    style={{
                      backgroundColor: '#6E4EE7',
                      color: 'white',
                      padding: '0.5rem',
                      fontFamily: 'sans-serif',
                      borderRadius: '0.3rem',
                      cursor: 'pointer',
                      marginTop: '1rem'
                    }}
                  >
                    Choose File
                  </label>
                  <span id="file-chosen" style={{marginLeft: '1rem', fontFamily: 'sans-serif'}}>
                  {id?.name ? id.name : profileData.id_path?profileData.id_path:""}
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} style={{display:'flex', flexDirection:'column', gap:5}}>
              <Typography variant='body'>Passport</Typography>
              <div style={{border: '1px solid lightgrey', padding: '10px',borderRadius: '4px'}}>
                <div>
                  <input type="file" id="passport-btn" hidden onChange={(e) => handlePassport(e)} disabled={profileData.passport_path}/>
                  <label     
                    htmlFor="passport-btn"                                     
                    style={{
                      backgroundColor: '#6E4EE7',
                      color: 'white',
                      padding: '0.5rem',
                      fontFamily: 'sans-serif',
                      borderRadius: '0.3rem',
                      cursor: 'pointer',
                      marginTop: '1rem'
                    }}
                  >
                    Choose File
                  </label>
                  <span id="file-chosen" style={{marginLeft: '1rem', fontFamily: 'sans-serif'}}>                                      
                    {passport?.name ? passport.name : profileData.passport_path?profileData.passport_path:""}
                  </span>
                </div>
              </div>
            </Grid>  
            <Grid item xs={12} style={{display:'flex', flexDirection:'column', gap:5}}>
              <Typography variant='body'>Visa Copy</Typography>
              <div style={{border: '1px solid lightgrey', padding: '10px',borderRadius: '4px'}}>
                <div>
                  <input type="file" id="agreement-btn" hidden onChange={e => handleAgreement(e)} disabled={profileData.agreement_path}/>
                  <label
                    htmlFor="agreement-btn" 
                    style={{
                      backgroundColor: '#6E4EE7',
                      color: 'white',
                      padding: '0.5rem',
                      fontFamily: 'sans-serif',
                      borderRadius: '0.3rem',
                      cursor: 'pointer',
                      marginTop: '1rem'
                    }}
                  >
                    Choose File
                  </label>
                  <span id="file-chosen" style={{marginLeft: '1rem', fontFamily: 'sans-serif'}}>
                    {agreement?.name ? agreement.name : profileData.agreement_path?profileData.agreement_path:""}
                  </span>
                </div>
              </div>
            </Grid>          
          </Grid>
          </Box>
      </Box>
      </div>
      </body>
  
  )
}

export default Profile