import React, { useState, useEffect, useMemo } from 'react';
import { Auth } from 'aws-amplify';
import { Box, Typography, Card } from "@mui/material"
import { styled } from "@mui/material"
import Grid from '@mui/material/Grid'

import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import CardContent from '@mui/material/CardContent'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { useToasts } from 'react-toast-notifications'; 
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import PhoneInput from 'react-phone-number-input'
 import DatePicker from "react-datepicker"
import countryList from 'react-select-country-list'
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CustButton from '../CustButton'
import { default as Selection } from 'react-select'
import useForm from './useForm'
import { validator } from './validator'
import "react-datepicker/dist/react-datepicker.css"
import 'react-phone-number-input/style.css'
import { useSearchParams } from 'react-router-dom';
import byAxios from './../../services/byAxios';
import { CASE_LAMBDAS } from './../../configs/lambdas';

import AddIcon from '@mui/icons-material/Add';
import moment from 'moment';
const ProgressLine = styled(Box)((props) => ({
  width: `${props._width}%`,
  height: '8px',
  background: props._width < 50 ? 'black' : 'blue',
}))


const NormalButton = styled(Button)((props)=>({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#6E4EE7',
  padding: '10px 0px',
  maxWidth: props.mw ? `${props.mw}px` : '230px',
  maxHeight: props.mh ? `${props.mh}px` : 'auto',
  width: '100%',
  textTransform: 'none',
  color: 'white',
  '&:hover': {
    boxShadow: '0px 0px 10px black',
    color: 'black'
  }
}))
const Client = ({
  moveHandle,
  nextClick,
  setLastTab,
  lastTab
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const initState = {
    firstName: '',
    lastName: '',
    email: '',
    nationality: {},
    employment: '',
    residency: '',
    borrower_residency: '',
    applicant_residency: '',
    borrower_relationship: '',
    applicant_residency: '',
    borrower_residency: '',
    leadType:'',
    applicant_firstName: [''],
    applicant_lastName: [''],
    applicant_email: [''],
    applicant_residency: [''],
    applicant_relationship: [''],
    borrower_firstName: [''],
    borrower_lastName: [''],
    borrower_email: [''],
    borrower_residency: [''],
    borrower_relationship: [''],
  }

  const submit = () => {
    console.log(" Submited");
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    state,
    errors,
    phone,
    birthDate,
    setBirthDate,
    addNewApplicant,
    applicant,
    setApplicant,
    borrower,
    setBorrower,
    handlePhone,
    addNewBorrower
  } = useForm({
    initState,
    callback: submit,
    validator,
    moveHandle,
    nextClick,
    setLastTab
  })

  const handlePanel = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [progress, setProgress] = useState(0)
  const [expanded, setExpanded] = useState('panel1');
 const[birth, setBirth] = useState('')
 console.log(birth)
  const options = useMemo(() => countryList().getData(), [])
  const [loading, setLoading] = useState(false)
  const { addToast } = useToasts();

  const [local, setLocal] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    nationality: '',
    employment: '',
    residency: '',
    birthDate: ``,
    leadType:'',
    applicant:[],
    borrower:[],
    applicant:'',
    applicant_firstName: '',
    applicant_lastName: '',
    applicant_email: '',
    applicant_phoneNumber: '',
    applicant_residency: '',
    applicant_relationship: '',
    borrower_firstName: '',
    borrower_lastName: '',
    borrower_email: '',
    borrower_phoneNumber: '',
    borrower_residency: '',
    borrower_relationship: ''
  });
 
  useEffect(() => {
    (async () => {
      setLoading(true)
      if (searchParams.get(`caseId`) && (lastTab > -2)) {
        try {
          let session = await Auth.currentSession();
          const caseData = await byAxios({
            route: `${CASE_LAMBDAS.GET_DETAIL.url}/${searchParams.get(`caseId`)}`,
            method: CASE_LAMBDAS.GET_DETAIL.method,
            headerCred: {
              "x-access-token": session?.accessToken?.jwtToken,
              "x-id-token": session?.idToken?.jwtToken
            }
          });
          console.log(`caseData in client`, caseData);
          
          if (caseData) {
            setLocal({ ...caseData});
          //   let m = moment(local?.birthDate);
          // let dob=m.format("DD-MM-YYYY");
          // setBirth(dob)
          // console.log('date' ,birth)

          }

        }
        catch (err) {

        }
      }
      setLoading(false)
    })()
  }, [lastTab])

  const addApplicant = () => {
    if(applicant.length>4){
      addToast('Only add 5 co-applicant.', {
        appearance:'error',
        autoDismiss:true
    }
      )
  }
  else {
    setApplicant([...applicant, { id: applicant.length + 1 }])
    addNewApplicant(applicant.length)
  }
  }

  const addCoborrower = () => {
    if(borrower.length>4){
      addToast('Only add 5 co-borrower.', {
        appearance:'error',
        autoDismiss:true
    }
      )
  }
  else
  {
    setBorrower([...borrower, { id: borrower.length + 1 }])
    addNewBorrower(borrower.length)
  }
   
  }

  const removeApplicant = (index) => {
    setApplicant(applicant.filter(item => item.id !== index))
  }

  const removeBorrower = (index) => {
    setBorrower(borrower.filter(item => item.id !== index))
  }

  
  
  return (
    <>
      {
        loading ?
        <div id="preloader"> </div> :
        <div id="preloader" style={{display: 'none'}}></div>
      }
      <Typography variant='body2' sx={{
        p: '20px 0px',
        textAlign: 'left'
      }}>
        Create an account for your client so you can track their progress and stay in control.
      </Typography>
      <Box component="form" noValidate onSubmit={handleSubmit}>
        <Card sx={{
          borderRadius: '5px',
          boxShadow: '0px 0px 10px lightgrey',
          overflow: 'unset'
        }}>
          <Box sx={{
            height: '8px',
            background: 'lightgrey',
            borderRadius: '5px 5px 0px 0px',
            overflow: 'hidden'
          }}>
            <ProgressLine _width={progress} />
          </Box>
          <Accordion
            expanded={expanded === 'panel1'}
            onChange={handlePanel('panel1')}
            sx={{
              boxShadow: 'none',
              '.MuiAccordionSummary-content': {
                m: '10px auto !important'
              },
              '.MuiAccordionSummary-root': {
                minHeight: 'unset'
              }
            }}
          >
            <AccordionSummary
              expandIcon={<img style={{ width: '20px' }} src='/images/icons/project-icons/direction.png' />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{
                p: '0px 20px',
              }}
            >
              <Typography variant='h6'>
                Main applicant
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{
              p: '0px',
            }}>
              <CardContent sx={{
                p: { xs: '10px' }
              }}>

                <Grid container spacing={5}>
                  <Grid item xs={12} ss={6}>
                    <Typography variant='body2'>First Name</Typography>
                    <TextField
                      autoComplete="given-name"
                      name="firstName"
                      required
                      fullWidth
                      id="firstName"
                      label=""
                      onChange={handleChange}
                      value={local?.firstName ? local?.firstName : state.firstName}
                      error={errors.firstName ? true : false}
                      helperText={errors.firstName}
                      onBlur={handleBlur}
                      placeholder='Tom'
                     // disabled={local?.firstName ? true : false}
                      sx={{
                        color: 'black'
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} ss={6}>
                    <Typography variant='body2'>Last Name</Typography>
                    <TextField
                      autoComplete="given-name"
                      name="lastName"
                      required
                      fullWidth
                      id="lastName"
                      label=""
                      onChange={handleChange}
                      value={local?.lastName ? local?.lastName : state.lastName}
                      error={errors.lastName ? true : false}
                      helperText={errors.lastName}
                      onBlur={handleBlur}
                      placeholder='Richard'
                      //disabled={local?.firstName ? true : false}
                    />
                  </Grid>
                  <Grid item xs={12} ss={6}>
                    <Typography variant='body2'>Email</Typography>
                    <TextField
                      autoComplete="given-name"
                      name="email"
                      required
                      fullWidth
                      id="email"
                      label=""
                      onChange={handleChange}
                      value={local?.firstName ? local?.email : state.email}
                      error={errors.email ? true : false}
                      helperText={errors.email}
                      onBlur={handleBlur}
                      placeholder='TomRichard@gmail.com'
                      //disabled={local?.firstName ? true : false}
                    />
                  </Grid>
                  <Grid item xs={12} ss={6} sx={{
                    'input': {
                      height: '50px !important',
                      borderColor: '#D9D9D9',
                      borderWidth: '0.3px',
                      borderRadius: '5px',
                      '&:hover': {
                        border: 'solid 1px black'
                      }
                    }
                  }}>
                    <Typography variant='body2'>Phone number</Typography>
                    <PhoneInput
                      required
                      fullWidth
                      id='phoneNumber'
                      placeholder="Enter phone number"
                      international
                      name='phoneNumber'
                      defaultCountry="AE"
                      value={local?.firstName ? local?.phoneNumber : phone.phoneNumber}
                      onChange={(e) => handlePhone(e, -1, 'main')}
                     // disabled={local?.firstName ? true : false}
                    />
                  </Grid>
                  <Grid item xs={11.9} ss={6}
                    sx={{
                      'input': {
                        width: '99%',
                        height: '50px',
                        borderColor: '#D9D9D9',
                        borderWidth: '0.3px',
                        borderRadius: '5px',
                        '&:hover': {
                          border: 'solid 1px black'
                        }
                      }
                    }}
                  >
                    <Typography variant='body2'>Date of birth</Typography>
                  
                    
                    <DatePicker
                      name='birthDate'
                      onChange={(e) => { console.log(`e`, e); setBirthDate(e) }}
                      
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText='Date of birth'
                      //disabled={local?.birthDate ? true : false}
                      selected={local?.birthDate ? new Date(local?.birthDate) : birthDate}
                       dateFormat={(birthDate, "dd-MM-yyyy")}
                    />
                  </Grid>
                  <Grid item xs={12} ss={6}>
                    <Typography variant='body2'>Nationality</Typography>
                    <FormControl fullwidth sx={{
                      width: '100%',

                    }}>
                      <Selection
                        options={options}
                        required
                        fullWidth
                        id='nationality'
                        placeholder="Enter your nationality"
                        international
                        name='nationality'
                        defaultCountry="US"
                        value={local?.firstName ? {label:local.nationality, name:''} : state.nationality}
                        onChange={handleChange}
                        error={errors.nationality ? true : false}
                        helperText={errors.nationality}
                        onBlur={handleBlur}
                       // disabled={local?.firstName ? true : false}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} ss={6}>
                    <Typography variant='body2'>Employment status</Typography>
                    <FormControl fullWidth>
                      <InputLabel id='form-layouts-separator-select-label'>
                      </InputLabel>
                      <Select
                        defaultValue='Salaried'
                        label=''
                        onChange={handleChange}
                        labelId='form-layouts-separator-select-label'
                        name="employment"
                        required
                        fullWidth
                        id="employment"
                        value={local?.firstName ? local?.employment : state.employment}
                        error={errors.employment ? true : false}
                        helperText={errors.employment}
                        onBlur={handleBlur}
                       // disabled={local?.firstName ? true : false}
                      >
                        <MenuItem value='Salaried'>Salaried</MenuItem>
                        <MenuItem value='Self employed'>Self employed</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} ss={6}>
                    <Typography variant='body2'>Residency status</Typography>
                    <FormControl fullWidth>
                      <InputLabel id='form-layouts-separator-select-label'>
                      </InputLabel>
                      <Select
                        defaultValue=''
                        label=''
                        onChange={handleChange}
                        name='residency'
                        value={local?.firstName ? local?.residency : state.residency}
                        id='form-layouts-separator-select'
                        labelId='form-layouts-separator-select-label'
                       // disabled={local?.firstName ? true : false}
                      >
                        <MenuItem value='UAE National'>UAE National</MenuItem>
                        <MenuItem value='UAE Resident'>UAE Resident</MenuItem>
                        <MenuItem value='Non Resident'>Non Resident</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} ss={0}>
                    <Typography variant='body2'>Lead Type</Typography>
                    <FormControl fullWidth>
                      <InputLabel id='form-layouts-separator-select-label'>
                      </InputLabel>
                      <Select
                        defaultValue=''
                        label=''
                        onChange={handleChange}
                        name='leadType'
                        value={local?.leadType ? local?.leadType : state.leadType}
                        id='form-layouts-separator-select'
                        labelId='form-layouts-separator-select-label'
                       // disabled={local?.leadType ? true : false}
                      >                        
                        <MenuItem value='Mortgage'>Mortgage</MenuItem>
                        <MenuItem value='TradeFinance'>Trade Finance</MenuItem>
                        <MenuItem value='Investment'>Investment</MenuItem>
                        <MenuItem value='Other'>Other</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                </Grid>

                {applicant.map((item, index) =>
                  item.id > 0 &&
                  <>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography variant='h6' style={{ margin: '20px 0px 20px 0px' }}>
                        Co-applicant # {item.id}
                      </Typography>

                      <IconButton aria-label="add" size="small" style={{ display: 'flex' }} onClick={() => addApplicant(item.id)} disabled={item.id>4}>
                        <AddIcon fontSize="small" />
                        <Typography variant='body2'>Add</Typography>
                      </IconButton>
                      
                      <IconButton aria-label="delete" size="small" style={{ display: 'flex' }} onClick={() => removeApplicant(item.id)}>
                        <DeleteIcon fontSize="small" />
                        <Typography variant='body2'>Remove</Typography>
                      </IconButton>
                    </div>
                    <Grid container spacing={5}>
                      <Grid item xs={12} ss={6}>
                        <Typography variant='body2'>First Name</Typography>
                        <TextField
                          autoComplete="given-name"
                          name={`applicant_firstName_${item.id}`}
                          required
                          fullWidth
                          id="firstName"
                          label=""
                          // onChange={e => handleChange(e, index)}
                          onChange={e => {
                            handleChange(e)
                            applicant[index]["firstName"]=e.target.value
                          }}
                          defaultValue={local?.applicant[index] ? local?.applicant[index].firstName : ''}
                          error={errors.firstName ? true : false}
                          helperText={errors.firstName}
                          onBlur={handleBlur}
                          placeholder='Tom'
                          
                          // value={local?.firstName ? local?.applicant_firstName : state.applicant_firstName[index]}
                          //disabled={local?.firstName ? true : false}
                        />
                      </Grid>
                      <Grid item xs={12} ss={6}>
                        <Typography variant='body2'>Last Name</Typography>
                        <TextField
                          name={`applicant_lastName_${item.id}`}
                          required
                          fullWidth
                          id="lastName"
                          label=""
                          onChange={e => {
                            handleChange(e)
                            applicant[index]["lastName"]=e.target.value
                          }}
                          defaultValue={local?.applicant[index] ? local?.applicant[index].lastName : ''}
                          //error={errors.firstName ? true : false}
                          error={errors.lastName ? true : false}
                          helperText={errors.lastName}
                          onBlur={handleBlur}
                          placeholder='Richard'
                          //value={local?.firstName ? local?.applicant_lastName : state.applicant_lastName[index]}
                         // disabled={local?.firstName ? true : false}
                        />
                      </Grid>
                      <Grid item xs={12} ss={6}>
                        <Typography variant='body2'>Email</Typography>
                        <TextField
                          name={`applicant_email_${item.id}`}
                          required
                          fullWidth
                          id="email"
                          label=""
                          onChange={e => {
                            handleChange(e)
                            applicant[index]["email"]=e.target.value
                          }}
                          defaultValue={local?.applicant[index] ? local?.applicant[index].email : ''}
                         // error={errors.firstName ? true : false}
                          error={errors.email ? true : false}
                          helperText={errors.email}
                          onBlur={handleBlur}
                          placeholder='TomRichard@gmail.com'
                          //value={local?.firstName ? local?.applicant_email : state.applicant_email[index]}
                        //  disabled={local?.firstName ? true : false}
                        />
                      </Grid>
                      <Grid item xs={12} ss={6} sx={{
                        'input': {
                          height: '50px !important',
                          borderColor: '#D9D9D9',
                          borderWidth: '0.3px',
                          borderRadius: '5px',
                          '&:hover': {
                            border: 'solid 1px black'
                          }
                        }
                      }}>
                        <Typography variant='body2'>Phone number</Typography>
                        <PhoneInput
                          required
                          fullWidth
                          id='phoneNumber'
                          placeholder="Enter phone number"
                          international
                          name={`applicant_phoneNumber_${item.id}`}
                          defaultCountry="AE"
                          onChange={e => {
                            handlePhone(e)
                            applicant[index]["phoneNumber"]=e
                          }}
                          defaultValue={local?.applicant[index] ? local?.applicant[index].phoneNumber : ''}
                          error={errors.firstName ? true : false}
                          //onChange={(e) => handlePhone(e, index, 'applicant')}
                          //value={local?.firstName ? local?.applicant_phoneNumber : phone.applicant_phoneNumber[index]}
                          //disabled={local?.firstName ? true : false}
                        />
                      </Grid>
                      <Grid item xs={12} ss={6}>
                        <Typography variant='body2'>Residency status</Typography>
                        <FormControl fullWidth>
                          <InputLabel id='form-layouts-separator-select-label'>
                          </InputLabel>
                          <Select
                            //defaultValue=''
                            label=''
                            //onChange={e => handleChange(e, index)}
                            onChange={e => {
                              handleChange(e)
                              applicant[index]["residency"]=e.target.value
                            }}
                            defaultValue={local?.applicant[index] ? local?.applicant[index].residency : ''}
                            error={errors.firstName ? true : false}
                            name={`applicant_residency_${item.id}`}
                            id='form-layouts-separator-select'
                            labelId='form-layouts-separator-select-label'
                            //value={local?.firstName ? local?.applicant_residency : state.applicant_residency[index]}
                           // disabled={local?.firstName ? true : false}
                          >
                            <MenuItem value='UAE National'>UAE National</MenuItem>
                            <MenuItem value='UAE Resident'>UAE Resident</MenuItem>
                            <MenuItem value='Non Resident'>Non Resident</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} ss={6}>
                        <Typography variant='body2'>Relationship</Typography>
                        <FormControl fullWidth>
                          <InputLabel id='form-layouts-separator-select-label'>
                          </InputLabel>
                          <Select
                            //defaultValue='Salaried'
                            label=''
                            //onChange={e => handleChange(e, index)}
                            onChange={e => {
                              handleChange(e)
                              applicant[index]["relationship"]=e.target.value
                            }}
                            defaultValue={local?.applicant[index] ? local?.applicant[index].relationship : ''}
                           // error={errors.firstName ? true : false}
                            labelId='form-layouts-separator-select-label'
                            name={`applicant_relationship_${item.id}`}
                            required
                            fullWidth
                            id="relaionship"
                            error={errors.applicant_relationship ? true : false}
                            helperText={errors.applicant_relationship}
                            onBlur={handleBlur}
                            //value={local?.firstName ? local?.applicant_relationship : state.applicant_relationship[index]}
                           // disabled={local?.firstName ? true : false}
                          >
                            <MenuItem value='Father'>Father</MenuItem>
                            <MenuItem value='Mother'>Mother</MenuItem>
                            <MenuItem value='Son'>Son</MenuItem>
                            <MenuItem value='Daughter'>Daughter</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </>
                )}
                {borrower.map((item, index) =>
                  item.id > 0 &&
                  <>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography variant='h6' style={{ margin: '20px 0px 20px 0px' }}>
                        Co-borrower # {item.id}
                      </Typography>
                     
                      <IconButton aria-label="add" size="small" style={{ display: 'flex' }} onClick={() => addCoborrower(item.id)} disabled={item.id>4}>
                        <AddIcon fontSize="small" />
                        <Typography variant='body2'>Add</Typography>
                      </IconButton>
                      
                      <IconButton aria-label="delete" size="small" style={{ display: 'flex' }} onClick={() => removeBorrower(item.id)}>
                        <DeleteIcon fontSize="small" />
                        <Typography variant='body2'>Remove</Typography>
                      </IconButton>
                    </div>
                    <Grid container spacing={5}>
                      <Grid item xs={12} ss={6}>
                        <Typography variant='body2'>First Name</Typography>
                        <TextField
                          autoComplete="given-name"
                          name={`borrower_firstName_${item.id}`}
                          required
                          fullWidth
                          id="firstName"
                          label=""
                          onChange={e => {
                            handleChange(e)
                            borrower[index]["firstName"]=e.target.value
                          }}
                          defaultValue={local?.borrower[index] ? local?.borrower[index].firstName : ''}
                          error={errors.borrower_firstName ? true : false}
                          helperText={errors.borrower_firstName}
                          onBlur={handleBlur}
                          placeholder='Tom'
                          //value={local?.firstName ? local?.borrower_firstName : state.borrower_firstName[index]}
                         // disabled={local?.firstName ? true : false}
                        />
                      </Grid>
                      <Grid item xs={12} ss={6}>
                        <Typography variant='body2'>Last Name</Typography>
                        <TextField
                          name={`borrower_lastName_${item.id}`}
                          required
                          fullWidth
                          id="lastName"
                          label=""
                          onChange={e => {
                            handleChange(e)
                            borrower[index]["lastName"]=e.target.value
                          }}
                          defaultValue={local?.borrower[index] ? local?.borrower[index].lastName : ''}
                          error={errors.lastName ? true : false}
                          helperText={errors.lastName}
                          onBlur={handleBlur}
                          placeholder='Richard'
                          //value={local?.firstName ? local?.borrower_lastName : state.borrower_lastName[index]}
                         // disabled={local?.firstName ? true : false}
                        />
                      </Grid>
                      <Grid item xs={12} ss={6}>
                        <Typography variant='body2'>Email</Typography>
                        <TextField
                          name={`borrower_email_${item.id}`}
                          required
                          fullWidth
                          id="email"
                          label=""
                          onChange={e => {
                            handleChange(e)
                            borrower[index]["email"]=e.target.value
                          }}
                          defaultValue={local?.borrower[index] ? local?.borrower[index].email : ''}
                          error={errors.email ? true : false}
                          helperText={errors.email}
                          onBlur={handleBlur}
                          placeholder='TomRichard@gmail.com'
                          //value={local?.firstName ? local?.borrower_email : state.borrower_email[index]}
                         // disabled={local?.firstName ? true : false}
                        />
                      </Grid>
                      <Grid item xs={12} ss={6} sx={{
                        'input': {
                          height: '50px !important',
                          borderColor: '#D9D9D9',
                          borderWidth: '0.3px',
                          borderRadius: '5px',
                          '&:hover': {
                            border: 'solid 1px black'
                          }
                        }
                      }}>
                        <Typography variant='body2'>Phone number</Typography>
                        <PhoneInput
                          required
                          fullWidth
                          id='phoneNumber'
                          placeholder="Enter phone number"
                          international
                          name={`borrower_phoneNumber_${item.id}`}
                          defaultCountry="AE"
                          onChange={e => {
                            handlePhone(e)
                            borrower[index]["phoneNumber"]=e
                          }}
                          defaultValue={local?.borrower[index] ? local?.borrower[index].phoneNumber : ''}
                          // onChange={(e) => handlePhone(e, index, 'borrower')}
                          // value={local?.firstName ? local?.borrower_phoneNumber : phone.borrower_phoneNumber[index]}
                         // disabled={local?.firstName ? true : false}
                        />
                      </Grid>
                      <Grid item xs={12} ss={6}>
                        <Typography variant='body2'>Residency status</Typography>
                        <FormControl fullWidth>
                          <InputLabel id='form-layouts-separator-select-label'>
                          </InputLabel>
                          <Select
                            //defaultValue=''
                            label=''
                            //onChange={e => handleChange(e, index)}
                            onChange={e => {
                              handleChange(e)
                              borrower[index]["residency"]=e.target.value
                            }}
                            defaultValue={local?.borrower[index] ? local?.borrower[index].residency : state.borrower_residency}
                            error={errors.firstName ? true : false}
                            name={`borrower_residency_${item.id}`}
                            id='form-layouts-separator-select'
                            labelId='form-layouts-separator-select-label'
                            //value={local?.firstName ? local?.borrower_residency : state.borrower_residency[index]}
                           // disabled={local?.firstName ? true : false}
                          >
                            <MenuItem value='UAE National'>UAE National</MenuItem>
                            <MenuItem value='UAE Resident'>UAE Resident</MenuItem>
                            <MenuItem value='Non Resident'>Non Resident</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} ss={6}>
                        <Typography variant='body2'>Relationship</Typography>
                        <FormControl fullWidth>
                          <InputLabel id='form-layouts-separator-select-label'>
                          </InputLabel>
                          <Select
                            //defaultValue='Salaried'
                            label=''
                            onChange={e => {
                              handleChange(e)
                              borrower[index]["relationship"]=e.target.value
                            }}
                            defaultValue={local?.borrower[index] ? local?.borrower[index].relationship : state.borrower_relationship}
                            labelId='form-layouts-separator-select-label'
                            name={`borrower_relationship_${item.id}`}
                            required
                            fullWidth
                            id="relaionship"
                            error={errors.borrower_relationship ? true : false}
                            helperText={errors.borrower_relationship}
                            onBlur={handleBlur}
                            //value={local?.firstName ? local?.borrower_relationship : state.borrower_relationship[index]}
                           // disabled={local?.firstName ? true : false}
                          >
                            <MenuItem value='Father'>Father</MenuItem>
                            <MenuItem value='Mother'>Mother</MenuItem>
                            <MenuItem value='Son'>Son</MenuItem>
                            <MenuItem value='Daughter'>Daughter</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </>
                )}
              </CardContent>
            </AccordionDetails>
          </Accordion>
          <Box sx={{
            p: 2,
            pt: '0px',
            mb: 3,
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: { xs: 'column', sm: 'row' },
          }}>
            {
              applicant?.length > 0 ? <></> :
                <Typography
                  variant={'subtitle1'}
                  onClick={() => addApplicant()}
                  disabled={applicant?.length > 0}
                  sx={{
                    color: `#0072E5`,
                    textDecoration: `underline`,
                    textDecorationColor: `rgba(0,127,255,0.4)`,
                    '&:hover': {
                      cursor: `pointer`
                    }
                  }}
                >
                  Add co-applicant
                </Typography>
            }
          </Box>

          <Box sx={{
            p: 2,
            pt: '0px',
            mb: 3,
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: { xs: 'column', sm: 'row' },
          }}>
            {
              borrower?.length > 0 ? <></> :
                <Typography
                  variant={'subtitle1'}
                  onClick={() => addCoborrower()}
                  disabled={borrower?.length > 0}
                  sx={{
                    color: `#0072E5`,
                    textDecoration: `underline`,
                    textDecorationColor: `rgba(0,127,255,0.4)`,
                    '&:hover': {
                      cursor: `pointer`
                    }
                  }}
                >
                  Add co-borrower
                </Typography>
            }
          </Box>

        </Card>
        {
          local?.firstName ? 
          <Box sx={{
            p: '30px 0px',
            display: 'flex',
            justifyContent: 'flex-end'
          }}>
            <NormalButton
              mw={150}
               onClick={() => {
                moveHandle(2);
                nextClick('income');
                 
              }}
            >
              Next step
            </NormalButton>
          </Box> :
            <Box sx={{
              p: '30px 0px',
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
              <CustButton
                mw={150}
              >
                Next step
              </CustButton>
            </Box>
        }
      </Box>
    </>
  )
}

export default Client