import { useState, useEffect } from 'react';

import { Auth } from 'aws-amplify';
import { Box, Typography, Card, Button } from "@mui/material"
import { styled } from "@mui/material"
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import CardContent from '@mui/material/CardContent'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import DatePicker from "react-datepicker"
import { useDispatch, useSelector } from 'react-redux'
import CustButton from '../CustButton'
import "react-datepicker/dist/react-datepicker.css"
import useForm from './useForm'
import { validator } from './validator';

import { Link, useSearchParams } from 'react-router-dom';

import byAxios from './../../services/byAxios';
import { CASE_LAMBDAS } from './../../configs/lambdas';

const ProgressLine = styled(Box)((props) => ({
  width: `${props._width}%`,
  height: '8px',
  background: (props._width < 50 ? 'black' : 'blue') || 'lightgrey',
}))

const PlusButton = styled(Button)({
  backgroundColor: 'black',
  borderRadius: '100px',
  fontSize: "15px",
  fontColor: "white",
  color: 'white',
  minWidth: '40px',
  height: "40px",
  '&:hover': {
    backgroundColor: 'black'
  }
})

const NormalButton = styled(Button)((props)=>({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#6E4EE7',
  padding: '10px 0px',
  maxWidth: props.mw ? `${props.mw}px` : '230px',
  maxHeight: props.mh ? `${props.mh}px` : 'auto',
  width: '100%',
  textTransform: 'none',
  color: 'white',
  '&:hover': {
    boxShadow: '0px 0px 10px black',
    color: 'black'
  }
}))

const Income = ({ moveHandle, nextClick, setLastTab, lastTab }) => {
  const email = useSelector(state => state.client.currentUser.email);
  const local_client = JSON.parse(localStorage.getItem('cases'));
  const [loading, setLoading] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams();
  const initState = {
    monthlySalary: '',
    addIncome: '',
    addIncomeType: '',
    creditCard: '',
    monthlyInstallments: '',
    emirate: '',
    loanAmount: '',
    propertyStatus: '',
    propertyValue: '',
    downPayment: '',
    mortgageType: '',
    residualFee: '',
    transactionType: '',
    mortgageLength:''
  }
  const submit = () => {
    console.log(" Submited");
  };
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    state,
    errors,
    incrementCount,
    decrementCount,
    count
  } = useForm({
    initState,
    callback: submit,
    validator,
    moveHandle,
    nextClick,
    email,
    local_client,
    setLastTab

  })
  const firstName = useSelector(state => state.client.currentUser.firstName);
  const lastName = useSelector(state => state.client.currentUser.lastName);
  

  const [progress1, setProgress1] = useState(0)
  const [expanded, setExpanded] = useState('panel1');

  const [progress2, setProgress2] = useState(0);
 
  

  const handlePanel = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : true);
  };
  const [local, setLocal] = useState(
    {
      monthlySalary: '',
      addIncome: '',
      addIncomeType: '',
      creditCard: '',
      monthlyInstallments: '',
      emirate: '',
      loanAmount: '',
      propertyStatus: '',
      propertyValue: '',
      downPayment: '',
      mortgageType: '',
      mortgageLength: '',
      residualFee: '',
      transactionType: '',
    }
  );
  

  useEffect(() => {
    let temp = [

    ]
    let num = 0
    temp.map((item, idx) => {
      if (item) {
        num = num + 1
      } else {
        num = num
      }
    })
    setProgress1(num * 11.12)

  }, []);

  useEffect(() => {
    
    (async () => {
      setLoading(true)
      if (searchParams.get(`caseId`)) {
        try {
          let session = await Auth.currentSession();
          console.log("working")
          const caseData = await byAxios({
            route: `${CASE_LAMBDAS.GET_DETAIL.url}/${searchParams.get(`caseId`)}`,
            method: CASE_LAMBDAS.GET_DETAIL.method,
            headerCred: {
              "x-access-token": session?.accessToken?.jwtToken,
              "x-id-token": session?.idToken?.jwtToken
            }
          });

          console.log(`caseData in income`, caseData);
          setLocal({ ...caseData});
          
          if (caseData) {
            setLocal({ ...caseData});
            console.log("local" , local?.monthlyInstallments)
           
          }
        }
        catch (err) {

        }
      }
      setLoading(false)
    })()

  }, [lastTab])

  

 

  return (
    <>
      {
        loading ?
        <div id="preloader"> </div> :
        <div id="preloader" style={{display: 'none'}}></div>
      }
      <Typography variant='body2' sx={{
        p: '20px 0px',
        textAlign: 'left'
      }}>
        Fill in all the money information about the income, property and mortgage information.
      </Typography>
      <Box component='form' noValidate onSubmit={handleSubmit}>
        <Card sx={{
          borderRadius: '5px',
          overflow: 'unset',
          boxShadow: '0px 0px 10px lightgrey'
        }}>
          <Box sx={{
            height: '8px',
            background: 'lightgrey',
            borderRadius: '5px 5px 0px 0px',
            overflow: 'hidden'
          }}>
            <ProgressLine _width={progress1} />
          </Box>

          <Accordion
            expanded={expanded === 'panel1'}
            onChange={handlePanel('panel1')}
            sx={{
              boxShadow: 'none',
              '.MuiAccordionSummary-content': {
                margin: '10px 0px'
              }
            }}
          >
            <AccordionSummary
              expandIcon={<img style={{ width: '20px' }} src='/images/icons/project-icons/direction.png' />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{ p: '0px 20px', }}
            >
              <Box sx={{
                textAlign: 'left',
              }}>
                {/* <Typography variant='h6'>
                  {`${firstName} ${lastName}`}
                </Typography> */}
                <Typography variant='h5'>
                  Main applicant
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{
              p: '0px'
            }}>
              <CardContent >
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <Grid item xs={12} md={5.9}>
                      <Typography variant='body2'>
                        Fixed Monthly Income
                      </Typography>
                      <TextField
                      autoComplete="given-name"
                        sx={{ width: '98%' }}
                        required
                        fullWidth
                        type='integer'
                        label=''
                        placeholder='20,000'
                        name="monthlySalary"
                        id="monthlySalary"
                        onChange={handleChange}
                        error={errors.monthlySalary ? true : false}
                        helperText={errors.monthlySalary}
                        onBlur={handleBlur}
                        //disabled={local?.monthlySalary ? true : false}
                        value={local?.monthlySalary ? local?.monthlySalary : state.monthlySalary}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='body2'>
                      Additional Income
                    </Typography>
                    <TextField
                      sx={{ width: '98%' }}
                      required
                      fullWidth
                      type='integer'
                      label=''
                      placeholder='50,000'
                      name="addIncome"
                      id="addIncome"
                      onChange={handleChange}
                      error={errors.addIncome ? true : false}
                      helperText={errors.addIncome}
                      onBlur={handleBlur}
                      //disabled={local?.monthlySalary ? true : false}
                      value={local?.addIncome ? local?.addIncome : state.addIncome}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='body2'>
                      Additional income type
                    </Typography>
                    <FormControl fullwidth sx={{
                      width: '100%',
                    }}>
                      <Select
                        label=''
                        defaultValue=''
                        id='form-layouts-separator-select'
                        labelId='form-layouts-separator-select-label'
                        name='addIncomeType'
                        type='text'
                        onChange={handleChange}
                        //disabled={local?.monthlySalary ? true : false}
                        value={local?.addIncomeType ? local?.addIncomeType : state.addIncomeType}
                      >
                        <MenuItem value='Rental'>Rental</MenuItem>
                        <MenuItem value='Bonus'>Bonus</MenuItem>
                        <MenuItem value='Commission'>Commission</MenuItem>
                        <MenuItem value='Allowance'>Allowance</MenuItem>
                        <MenuItem value='Other'>Other</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='body2'>
                      Total credit card limit
                    </Typography>
                    <TextField
                      sx={{ width: '98%' }}
                      required
                      fullWidth
                      type='integer'
                      label=''
                      placeholder='50,000'
                      name="creditCard"
                      id="creditCard"
                      onChange={handleChange}
                      error={errors.creditCard ? true : false}
                      helperText={errors.creditCard}
                      onBlur={handleBlur}
                     // disabled={local?.monthlySalary ? true : false}
                      value={local?.creditCard ? local?.creditCard : state.creditCard}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='body2'>
                      Monthly fixed installments
                    </Typography>
                    <TextField
                      sx={{ width: '98%' }}
                      required
                      fullWidth
                      type='integer'
                      label=''
                      placeholder='6,000'
                      name="monthlyInstallments"
                      id="monthlyInstallments"
                      onChange={handleChange}
                      error={errors.monthlyInstallments ? true : false}
                      helperText={errors.monthlyInstallments}
                      onBlur={handleBlur}
                      //disabled={local?.monthlySalary ? true : false}
                      value={local?.monthlyInstallments ? local?.monthlyInstallments : state.monthlyInstallments}
                    />
                  </Grid>                
                </Grid>
              </CardContent>
            </AccordionDetails>
          </Accordion>

        </Card>
        <Card sx={{
          borderRadius: '5px',
          mt: '30px',
          boxShadow: '0px 0px 10px lightgrey'
        }}>
          <Box sx={{
            height: '8px',
            background: 'lightgrey'
          }}>
            <ProgressLine _width={progress2} />
          </Box>
          <Accordion
            expanded={expanded === 'panel2'}
            onChange={handlePanel('panel2')}
            sx={{
              boxShadow: 'none',
              '.MuiAccordionSummary-content': {
                margin: '10px 0px'
              }
            }}
          >
            <AccordionSummary
              expandIcon={<img style={{ width: '20px' }} src='/images/icons/project-icons/direction.png' />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{
                p: '0px 20px',
              }}
            >
              <Box>
                <Typography variant='h6'>
                  Mortgage details
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{
              p: '0px'
            }}>
              <CardContent >
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <Grid item xs={12} md={5.8}>
                      <Typography variant='body2'>Which Emirate?</Typography>
                      <FormControl fullWidth>
                        <InputLabel id='form-layouts-separator-select-label'>
                        </InputLabel>
                        <Select
                          defaultValue=''
                          label=''
                          name='emirate'
                          // onChange={(e)=>setWhichEmirate(e.target.value)}
                          onChange={handleChange}
                          id='form-layouts-separator-select'
                          labelId='form-layouts-separator-select-label'
                          //disabled={local?.monthlySalary ? true : false}
                          value={local?.emirate ? local?.emirate : state.emirate}
                        >
                          <MenuItem value='Dubai'>Dubai</MenuItem>
                          <MenuItem value='Abu Dhabi'>Abu Dhabi</MenuItem>
                          <MenuItem value='Sharjah'>Sharjah</MenuItem>
                          <MenuItem value='Ajman'>Ajman</MenuItem>
                          <MenuItem value='Ras AI Khaimah'>Ras AI Khaimah</MenuItem>
                          <MenuItem value='Fujairah'>Fujairah</MenuItem>
                          <MenuItem value='Umm AI Quwain'>Umm AI Quwain</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='body2'>Loan amount</Typography>
                    <TextField
                      sx={{ width: '98%' }}
                      required
                      fullWidth
                      type='integer'
                      label=''
                      placeholder='6,000'
                      name="loanAmount"
                      id="loanAmount"
                      onChange={handleChange}
                      error={errors.loanAmount ? true : false}
                      helperText={errors.loanAmount}
                      onBlur={handleBlur}
                      //disabled={local?.monthlySalary ? true : false}
                      value={local?.loanAmount ? local?.loanAmount : state.loanAmount}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='body2'>Property status</Typography>
                    <FormControl fullWidth>
                      <InputLabel id='form-layouts-separator-select-label'>
                      </InputLabel>
                      <Select
                        defaultValue=''
                        label=''
                        name='propertyStatus'
                        onChange={handleChange}
                        id='form-layouts-separator-select'
                        labelId='form-layouts-separator-select-label'
                       // disabled={local?.monthlySalary ? true : false}
                        value={local?.propertyStatus ? local?.propertyStatus : state.propertyStatus}
                      >
                        <MenuItem value='Ready'>Ready</MenuItem>
                        <MenuItem value='Under Construction'>Under Construction</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='body2'>Property value</Typography>
                    <TextField
                      sx={{ width: '98%' }}
                      required
                      fullWidth
                      type='integer'
                      label=''
                      placeholder='500,000'
                      name="propertyValue"
                      id="propertyValue"
                      onChange={handleChange}
                      error={errors.propertyValue ? true : false}
                      helperText={errors.propertyValue}
                      onBlur={handleBlur}
                     // disabled={local?.monthlySalary ? true : false}
                      value={local?.propertyValue ? local?.propertyValue : state.propertyValue}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='body2'>Downpayment(%)</Typography>
                    <TextField
                      sx={{ width: '98%' }}
                      required
                      fullWidth
                      type='integer'
                      label=''
                      placeholder='500,000'
                      name="downPayment"
                      id="downPayment"
                      onChange={handleChange}
                      error={errors.downPayment ? true : false}
                      helperText={errors.downPayment}
                      onBlur={handleBlur}
                     // disabled={local?.monthlySalary ? true : false}
                      value={local?.downPayment ? local?.downPayment : state.downPayment}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='body2'>Mortgage type</Typography>
                    <FormControl fullWidth>
                      <InputLabel id='form-layouts-separator-select-label'>
                      </InputLabel>
                      <Select
                        defaultValue=''
                        label=''
                        name='mortgageType'
                        onChange={handleChange}
                        id='form-layouts-separator-select'
                        labelId='form-layouts-separator-select-label'
                       // disabled={local?.monthlySalary ? true : false}
                        value={local?.mortgageType ? local?.mortgageType : state.mortgageType}
                      >
                        <MenuItem value='Conventional'>Conventional</MenuItem>
                        <MenuItem value='Islamic'>Islamic</MenuItem>
                        <MenuItem value='Best possible rate'>Best possible rate</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='body2'>
                      Mortgage length(Months)
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center", marginTop: "15px" }}>

                      <TextField
                        sx={{ width: '98%' }}
                        required
                        fullWidth
                        type='number'
                        label=''
                        placeholder='0-300'
                        name="mortgageLength"
                        id="mortgageLength"
                        min="0" 
                        max="306"
                        onChange={handleChange}
                        error={errors.mortgageLength ? true : false}
                        helperText={errors.mortgageLength}
                        onBlur={handleBlur}
                       // disabled={local?.monthlySalary ? true : false}
                        value={local?.mortgageLength ? local?.mortgageLength : state.mortgageLength}
                      />
                     
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='body2'>
                      Add Residual Associate fee?
                    </Typography>
                    <FormControl fullWidth>
                      <InputLabel id='form-layouts-separator-select-label'>
                      </InputLabel>
                      <Select
                        defaultValue=''
                        label=''
                        name='residualFee'
                        onChange={handleChange}
                        id='form-layouts-separator-select'
                        labelId='form-layouts-separator-select-label'
                       // disabled={local?.monthlySalary ? true : false}
                        value={local?.residualFee ? local?.residualFee : state?.residualFee}
                      >
                        <MenuItem value='Yes'>Yes</MenuItem>
                        <MenuItem value='No'>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='body2'>
                      Transaction type
                    </Typography>
                    <FormControl fullWidth>
                      <InputLabel id='form-layouts-separator-select-label'>

                      </InputLabel>
                      <Select
                        defaultValue=''
                        label=''
                        name='transactionType'
                        onChange={handleChange}
                        id='form-layouts-separator-select'
                        labelId='form-layouts-separator-select-label'
                       // type='integer'
                       // disabled={local?.monthlySalary ? true : false}
                        value={local?.transactionType ? local?.transactionType : state?.transactionType}
                      >
                        <MenuItem value='Handover payment'>Handover payment</MenuItem>
                        <MenuItem value='Primary purchase'>Primary purchase</MenuItem>
                        <MenuItem value='Resale'>Resale</MenuItem>
                        <MenuItem value='Buyout-equity'>Buyout-equity</MenuItem>
                        <MenuItem value='Buyout'>Buyout</MenuItem>
                        <MenuItem value='Equity'>Equity</MenuItem>
                        <MenuItem value='Handover resale'>Handover resale</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </CardContent>
            </AccordionDetails>
          </Accordion>
        </Card>

        {
          local?.monthlySalary ? <Box sx={{
            p: '30px 0px',
            display: 'flex',
            justifyContent: 'flex-end'
          }}>
            <NormalButton mw={150}
               onClick={() => {
                moveHandle(4);
                nextClick('documents');
                 
              }}
              >
                Next step
              </NormalButton>
            
          </Box> :
            <Box sx={{
              p: '30px 0px',
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
              <CustButton mw={150}>
              Next step
            </CustButton>
            </Box>
        }

      </Box>
    </>
  )
}

export default Income