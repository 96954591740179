import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  CircularProgress,
  Button,
  Box,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useToasts } from 'react-toast-notifications';
import { CASE_LAMBDAS } from './../../configs/lambdas'
import byAxios from './../../services/byAxios';
import { Storage } from "aws-amplify";
import { useDispatch } from 'react-redux';
import { broker } from '../../actions/broker';

const Upload = () => {
  const navigate = useNavigate()
  const { addToast } = useToasts()
  const [loading, setLoading] = useState(false)
  const [license, setLicense] = useState(null)
  const [id, setID] = useState(null)
  const [passport, setPassport] = useState(null)
  const [agreement, setAgreement] = useState(null)
  const [profileData, setProfileData] = useState({ name: "", email: "", companyName: "", rm: "", phone_office: "", trade_license_path: "" });
  const dispatch = useDispatch();
  console.log('license', license)
  const handleLicense = (e) => {
    console.log('e', e)
    if (e.target.files[0].size <= 26214400) {
      setLicense(e.target.files[0])
    }
    else {
      addToast('You can not select files larger than 25MB', {
        appearance: 'error',
        autoDismiss: true
      })
    }
  }
  const handleAgreement = (e) => {
    console.log('e', e)
    if (e.target.files[0].size <= 26214400) {
      setAgreement(e.target.files[0])
    }
    else {
      addToast('You can not select files larger than 25MB', {
        appearance: 'error',
        autoDismiss: true
      })
    }
  }
  const handleID = (e) => {
    if (e.target.files[0].size <= 26214400) {
      setID(e.target.files[0])
    }
    else {
      addToast('You can not select files larger than 25MB', {
        appearance: 'error',
        autoDismiss: true
      })
    }
  }
  const handlePassport = (e) => {
    if (e.target.files[0].size <= 26214400) {
      setPassport(e.target.files[0])
    }
    else {
      addToast('You can not select files larger than 25MB', {
        appearance: 'error',
        autoDismiss: true
      })
    }
  }

  const handleUpload = async () => {
    setLoading(true)
    try {
      let session = await Auth.currentSession();
      let username = await session.getIdToken().payload.sub;
      const currentCredentials = await Auth.currentCredentials();
      const cognitoIdentityId = currentCredentials.identityId;
      console.log('Cognito Identity ID:', cognitoIdentityId);
      let path1 = "";
      let path2 = "";
      let path3 = "";
      let path4 = "";
      if (license != null) {
        let fileExt1 = license?.name.split('.').pop()
        path1 = username + "/" + `trade_license.` + fileExt1 //  /trade_license.png
        await Storage.put(path1, license, {
          contentType: license.type,
        });
      }
      if (agreement != null) {
        let fileExt4 = agreement?.name.split('.').pop()
        path4 = username + "/" + `agreement.` + fileExt4
        await Storage.put(path4, agreement, {
          contentType: agreement.type,
        });
      }
      if (id != null) {
        let fileExt2 = id?.name.split('.').pop()
        path2 = username + "/" + `ID.` + fileExt2
        await Storage.put(path2, id, {
          contentType: id.type,
        });
      }
      if (passport != null) {
        let fileExt3 = passport?.name.split('.').pop()
        path3 = username + "/" + `passport.` + fileExt3
        await Storage.put(path3, passport, {
          contentType: passport.type,
        });
      }
      await byAxios({
        route: CASE_LAMBDAS.UPDATE_ACCOUNT.url,
        method: CASE_LAMBDAS.UPDATE_ACCOUNT.method,
        headerCred: {
          "x-access-token": session?.accessToken?.jwtToken,
          "x-id-token": session?.idToken?.jwtToken
        },
        data: {
          "trade_license_path": path1 ? cognitoIdentityId + '/' + path1 : '',
          "id_path": path2 ? cognitoIdentityId + '/' + path2 : '',
          "passport_path": path3 ? cognitoIdentityId + '/' + path3 : '',
          "agreement_path": path4 ? cognitoIdentityId + '/' + path4 : '',
        }
      });
      addToast('Your files are uploaded successfully', {
        appearance: 'success',
        autoDismiss: true
      })
    }
    catch (err) {
      console.log(err)
      addToast(err.message, {
        appearance: 'error',
        autoDismiss: true
      })
    }
    navigate('/cases')
    setLoading(false)
  }


  useEffect(() => {
    (async () => {
      setLoading(true);
      let session = await Auth.currentSession();
      console.log(`session`, session);
      const downloadedProfileData = await byAxios({
        route: `${CASE_LAMBDAS.GET_PROFILE.url}`,
        method: CASE_LAMBDAS.GET_PROFILE.method,
        headerCred: {
          "x-access-token": session?.accessToken?.jwtToken,
          "x-id-token": session?.idToken?.jwtToken
        }
      });
      dispatch(broker(downloadedProfileData))
      setProfileData(downloadedProfileData)
      console.log(profileData)
      setLoading(false);
    })()
  }, []);
  return (
    <body>

      <div class="main-navbar" style={{ backgroundColor: 'white', color: 'black', }} >

        <nav class="navbar navbar-style-two navbar-expand-lg navbar-light" style={{ backgroundColor: 'white' }}>
          <div class="container-fluid">

            <div class="logo">



              <a href="/signup">
                <img src="/assets/images/cropped-logo.png" className="white-logo" alt="Logo" style={{ height: '70px' }} />
              </a>
            </div>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav">

              </ul>

              <div class="others-option">
                <div class="d-flex align-items-center">
                  <div class="option-item">
                    <a href="tel:+971 42014000" class="call-btn" style={{ color: 'black' }}>Call Us: +971 42014000</a>
                  </div>

                  <div class="option-item">
                    <a href="https://user.mortgagebcf.com/" class="sign-up">Sign In as User </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <Box
        component="main"
        sx={{
          maxWidth: { xs: '80%', sm: '80%', md: '45%', lg: '45%' },
          width: '100%',
          marginTop: { xs: '30%', sm: '30%', md: '65px', lg: '65px' },
          marginLeft: 'auto',
          marginRight: 'auto',
        }}>
        <Box
          sx={{
            marginTop: 20,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            marginLeft: 'auto'
          }}
        >
          <Typography variant='h5'>
            Please Upload your Document in order to be get paid
          </Typography>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
              <Typography variant='body'>Trade license</Typography>
              <div style={{ border: '1px solid lightgrey', padding: '10px', borderRadius: '4px' }}>
                <div>
                  <input type="file" id="license-btn" hidden onChange={e => handleLicense(e)} />
                  <label
                    for="license-btn"
                    style={{
                      backgroundColor: '#6E4EE7',
                      color: 'white',
                      padding: '0.5rem',
                      fontFamily: 'sans-serif',
                      borderRadius: '0.3rem',
                      cursor: 'pointer',
                      marginTop: '1rem'
                    }}
                  >
                    Choose File
                  </label>
                  <span id="file-chosen" style={{ marginLeft: '1rem', fontFamily: 'sans-serif' }}>
                    {license?.name ? license.name : ''}
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
              <Typography variant='body'>ID</Typography>
              <div style={{ border: '1px solid lightgrey', padding: '10px', borderRadius: '4px' }}>
                <div>
                  <input type="file" id="id-btn" hidden onChange={e => handleID(e)} />
                  <label
                    for="id-btn"
                    style={{
                      backgroundColor: '#6E4EE7',
                      color: 'white',
                      padding: '0.5rem',
                      fontFamily: 'sans-serif',
                      borderRadius: '0.3rem',
                      cursor: 'pointer',
                      marginTop: '1rem'
                    }}
                  >
                    Choose File
                  </label>
                  <span id="file-chosen" style={{ marginLeft: '1rem', fontFamily: 'sans-serif' }}>
                    {id?.name ? id.name : ''}
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
              <Typography variant='body'>Passport</Typography>
              <div style={{ border: '1px solid lightgrey', padding: '10px', borderRadius: '4px' }}>
                <div>
                  <input type="file" id="passport-btn" hidden onChange={(e) => handlePassport(e)} />
                  <label
                    for="passport-btn"
                    style={{
                      backgroundColor: '#6E4EE7',
                      color: 'white',
                      padding: '0.5rem',
                      fontFamily: 'sans-serif',
                      borderRadius: '0.3rem',
                      cursor: 'pointer',
                      marginTop: '1rem'
                    }}
                  >
                    Choose File
                  </label>
                  <span id="file-chosen" style={{ marginLeft: '1rem', fontFamily: 'sans-serif' }}>
                    {passport?.name ? passport.name : ''}
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
              <Typography variant='body'>Visa Copy</Typography>
              <div style={{ border: '1px solid lightgrey', padding: '10px', borderRadius: '4px' }}>
                <div>
                  <input type="file" id="agreement-btn" hidden onChange={e => handleAgreement(e)} />
                  <label
                    for="agreement-btn"
                    style={{
                      backgroundColor: '#6E4EE7',
                      color: 'white',
                      padding: '0.5rem',
                      fontFamily: 'sans-serif',
                      borderRadius: '0.3rem',
                      cursor: 'pointer',
                      marginTop: '1rem'
                    }}
                  >
                    Choose File
                  </label>
                  <span id="file-chosen" style={{ marginLeft: '1rem', fontFamily: 'sans-serif' }}>
                    {agreement?.name ? agreement.name : ''}
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <Button
              variant="contained"
              sx={{ mt: 3, mb: 2, textTransform: 'none', background: '#6E4EE7', width: '100%' }}
              onClick={() => { handleUpload() }}
            >
              {loading && <CircularProgress />}
              Upload Or Next
            </Button>

          </div>
        </Box>
      </Box>
    </body>
  )
}

export default Upload
