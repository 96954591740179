import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Amplify,Storage } from 'aws-amplify';
import { SignIn, SignUp, Overview, CasesCreate, Confirmation, MainCase, Upload,Profile,  } from "./pages";
import Review from './pages/review'
import Firstview from "./pages/firstview";
import Sme from './pages/firstview/Sme'
import Homeloan from './pages/firstview/Homeloan'
import Calculate from './pages/firstview/Calculate'
import Mortgage from './pages/firstview/Mortgage'
import Compare from './pages/firstview/Compare'
import { CreatePassword, ResetPassword } from './pages/password'
import { ProtectedRoute } from './helpers'
import { Header } from "./layout";
import { awsConfig } from "./configs/aws-exports";
import './App.css'

Amplify.configure({...awsConfig,authenticationFlowType: 'CUSTOM_AUTH'})
Storage.configure({ level: 'private' });


function App() {
  return (
    <div className="App" style={{width:'100%'}}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/login" replace />}/>
          <Route path="/overview" element={<ProtectedRoute><Overview /></ProtectedRoute>} />
          <Route path="/login" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/upload" element={<Upload />} />
          <Route path="confirmation" element={<Confirmation />} />
          <Route path='create_password' element={<CreatePassword />} />
          <Route path='reset_password' element={<ResetPassword />} />
          <Route path='/cases/review' element={<Review />} />
          <Route path="/cases/create" element={<ProtectedRoute><CasesCreate /></ProtectedRoute>} />
          <Route path="/cases" element={<ProtectedRoute><MainCase /></ProtectedRoute>} />
          <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
          <Route path='/calculator' element={<ProtectedRoute><Calculate /></ProtectedRoute>} />
               <Route path='/mortgage' element={<ProtectedRoute><Mortgage /></ProtectedRoute>} />
               <Route path='/homeloan' element={<ProtectedRoute><Homeloan /></ProtectedRoute>} />
               {/* <Route path='/compare' element={<ProtectedRoute><Compare /></ProtectedRoute>} /> */}
               <Route path='/sme' element={<ProtectedRoute><Sme /></ProtectedRoute>} />
          {/* <Route path="/home" element={<ProtectedRoute><Firstview /></ProtectedRoute>} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

