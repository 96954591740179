import { combineReducers } from 'redux'

import auth from './auth'
import client from './client'
import income from './income'
import broker from './broker'

const rootReducer = combineReducers({
  auth,
  client,
  broker
})

export default rootReducer
