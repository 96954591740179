import { useState , useRef } from 'react'

import {
    Container,
    Row,
    Col,
    Button,
    Card,
    Form,
    InputGroup,
    Dropdown,
    Carousel
  } from 'react-bootstrap'
  
  import { Link , useNavigate, useLocation  } from 'react-router-dom'
  import { useToasts } from 'react-toast-notifications';
import { Auth } from 'aws-amplify';
  
  const Header = () => {
    const[sidebar, setSidebar]=useState(false)
    const { addToast } = useToasts();
    const sidebars = useRef();
    const location = useLocation()
    const navigate=useNavigate();
    const showSidebar=()=>{
       
           setSidebar(true)
           localStorage.setItem('sidebar', sidebar);
    }
    const hideSidebar=()=>{
       
        setSidebar(false)
        localStorage.setItem('sidebar', sidebar);
 }
 const handleLogOut = async () => {
    try {
      await Auth.signOut();
      addToast('You have logged out successfully.', {
        appearance:'success',
        autoDismiss:true
      })
      navigate('/login')
    } catch (error) {
        addToast(error.message, {
          appearance:'error',
          autoDismiss:true
        })
    }
  }
    return (
 
   
    <div>

<div ref={sidebars} className='sidebar-container'>
        <div className='sidebar-wrapper'>
       <Link to='/profile'>
        <div style={{margin:'2 px', justifyContent:'center' , }} className={`item home-link ${location.pathname === '/' ? 'white-bg' : ''}`} >
        <span class="bottombar-icons">
              <img src='/assets/images/user-2.svg'  style={{ width: '22px'}}/>
              </span>
              <div>
              Profile
            </div>
          </div>
          </Link>
          <Link to='/cases'>
          <div style={{margin:'2 px',justifyContent:'center' , }} className={`item home-link ${location.pathname === '/overview' ? 'white-bg' : ''}`}>
          <span class="bottombar-icons">
              <img src='/assets/images/status.svg'  style={{ width: '22px'}}/>
              </span>
              <div>
              Cases
            </div>
          </div>
          </Link>
{/* <Link to='#'>
          <div style={{margin:'2 px',justifyContent:'center' , }} className={`item home-link ${location.pathname === '/overview' ? 'white-bg' : ''}`}>
          <span class="bottombar-icons">
              <img src='/assets/images/status.svg'  style={{ width: '22px'}}/>
              </span>
              <div>
              Proposals
            </div>
          </div>
          </Link>
          <Link to='#'>
          <div style={{margin:'2 px',justifyContent:'center' , }} className={`item home-link ${location.pathname === '/overview' ? 'white-bg' : ''}`}>
          <span class="bottombar-icons">
              <img src='/assets/images/setting.svg'  style={{ width: '22px'}}/>
              </span>
              <div>
              Bank
            </div>
          </div>
          </Link>
          <Link to='#'>
          <div style={{margin:'2 px',justifyContent:'center' , }} className={`item home-link ${location.pathname === '/overview' ? 'white-bg' : ''}`}>
          <span class="bottombar-icons">
              <img src='/assets/images/share.svg'  style={{ width: '22px'}}/>
              </span>
              <div>
              Documents
            </div>
          </div>
          </Link> */}
         

          {/* {
            sideData.map((item, idx) => {
              return (
                <div key={idx} style={{margin:'2 px', justifyContent:'center'}} className={`item ${`/${item.name}` === location.pathname ? 'white-bg' : ''}`} onClick={()=> {router(idx+2, item.check, item.name)}}>
                  <span class="bottombar-icons">
                  <img
                    src={item.imgurl}
                    //className={`${item.check ? 'imgOp' : ''}`}
                    style={{ width: '15px'}}/>
                    </span>
                  
                    {item.name}
                  <div style={{marginTop:'20px' ,flexWrap:'wrap', justifyContent:'flex-end',display:'flex', width:'40px'}}>
                  {item.check ? 
                    <img
                      className='check-img'
                      src='/assets/images/check-circle-1.2f883aa2.svg'
                    /> 
                    : <></>
                  }
                  </div>
                </div>
              )
            })
          }   */}
           {/* <div style={{margin:'2px' ,justifyContent:'center' , }} className={`item home-link ${location.pathname === '/' ? 'white-bg' : ''}`} onClick={()=>logout()} >
         
        
           <img src="/assets/images/log-out.svg"  style={{ width: '22px'}}/>
           <div>
           Logout 
         </div>
       </div> */}
        </div>
        </div>  
    <div  className={`side-menu ${sidebar ? 'show-menu' : 'display'}`} id="sidebar-menu">
    <img src="/assets/images/cropped-logo.png" alt="" style={{height:'80px'}}/> 
           <br />
    <ul>
    {/* <li>
                    <a class="no-line" href="/home">
                    	<span class="material-icons"><img src="/assets/images/dashboard.svg" alt="" style={{height:'15px' , width:'15px'}} /></span>
						Home
					</a>
                </li> */}
                
                <li>
                    <a class="no-line" href="/profile">
                    	<span class="material-icons"><img src="/assets/images/user-2.svg" alt=""  style={{height:'15px' , width:'15px'}}/></span>
						My Profile
                    </a>
                </li>
                <li>
                    <a class="no-line" href="/cases">
                    	<span class="material-icons"><img src="/assets/images/status.svg" alt="" style={{height:'15px' , width:'15px'}} /></span>
						Cases
                    </a>
                </li>
                {/* <li>
                    <a class="no-line" href="/compare">
                    	<span class="material-icons"><img src="/assets/images/status.svg" alt="" style={{height:'15px' , width:'15px'}} /></span>
						Compare Mortgage
                    </a>
                </li> */}
                <li>
                    <a class="no-line" href="#">
                    	<span class="material-icons"><img src="/assets/images/status.svg" alt="" style={{height:'15px' , width:'15px'}} /></span>
						Proposals
                    </a>
                </li>
               
				
				<li>
                    <a class="no-line" href="#">
                    	<span class="material-icons"><img src="/assets/images/setting.svg" alt=""  style={{height:'15px' , width:'15px'}}/></span>
						Bank Products
                    </a>
                </li>
                <li>
                    <a class="no-line" href="#">
                    	<span class="material-icons"><img src="/assets/images/share.svg" alt="" style={{height:'15px' , width:'15px'}} /></span>
						Document center
                    </a>
                </li>
    </ul>
    <div class="close-btn" onClick={hideSidebar}><span class="material-icons"><img src="/assets/images/close.svg" alt="" style={{height:'24px' , width:'24px'}} /></span></div>
    <div class="sidebar-logout no-line main-default-btn2" onClick={handleLogOut} style={{height:'60px' , cursor:'pointer'}}><span><img src="/assets/images/logout.svg" alt="" style={{width:'24px'}}/></span>Logout</div>
           
       
</div>
    <div class="main-navbar" style={{backgroundColor:'white' , color:'black' , }} >

            <nav class="navbar navbar-style-two navbar-expand-lg navbar-light"  style={{backgroundColor:'white'}}>
                <div class="container-fluid">
               
                    <div class="logo">
                       
                     
             <button onClick={showSidebar} class="navbar-toggler2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
            <a href="/profile"> 
                            <img src="/assets/images/cropped-logo.png" className="white-logo" alt="Logo" style={{height:'70px'}}/>
                        </a>
                    </div>
                  
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav">
                           
                        </ul>
    
                        <div class="others-option">
                            <div class="d-flex align-items-center">
                                <div class="option-item">
                                    <a href="tel:+971 42014000" class="call-btn" style={{color:'black'}}>Call Us: +971 42014000</a>
                                </div>
                                {/* <div class="option-item">
                                    <a href="https://user.mortgagebcf.com/" class="log-in">Log In</a>
                                </div>
                                <div class="option-item">
                                    <a href="https://broker.bcf-mortgage.com/login" class="sign-up">Sign Up</a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
        </div>
    )
  }
  
  export default Header