import { useState } from 'react'

import {
    Container,
    Row,
    Col,
    Button,
    Card,
    Form,
    InputGroup,
    Dropdown,
    Carousel
  } from 'react-bootstrap'
  
  import { Link , useNavigate } from 'react-router-dom'
  import { useToasts } from 'react-toast-notifications';
import { Auth } from 'aws-amplify';
  
  const Sidebar = () => {
    const[sidebar, setSidebar]=useState(false)

    const showSidebar=()=>{
       
           setSidebar(true)
           
    }
    const hideSidebar=()=>{
       
        setSidebar(false)
        
  }
    return (
 
   
    <div>
     <div  className={`side-menu ${sidebar ? 'show-menu' : 'display'}`} id="sidebar-menu">
    <img src="/assets/images/cropped-logo.png" alt="" style={{height:'80px'}}/> 
           <br />
    <ul>
    <li>
                    <a class="no-line" href="https://mortgagebcf.com/">
                    	<span class="material-icons"><img src="/assets/images/dashboard.svg" alt="" style={{height:'15px' , width:'15px'}} /></span>
						Home
					</a>
                </li>
                
                <li>
                    <a class="no-line" href="https://www.broker.mortgagebcf.com/login">
                    	<span class="material-icons"><img src="/assets/images/user-2.svg" alt=""  style={{height:'15px' , width:'15px'}}/></span>
						My Profile
                    </a>
                </li>
                <li>
                    <a class="no-line" href="https://mortgagebcf.com/our-team">
                    	<span class="material-icons"><img src="/assets/images/user-2.svg" alt=""  style={{height:'15px' , width:'15px'}}/></span>
						Our Team
                    </a>
                </li>
                {/* <li>
                    <a class="no-line" href="https://user.mortgagebcf.com/">
                    	<span class="material-icons"><img src="/assets/images/calculator.svg" alt="" style={{height:'15px' , width:'15px'}} /></span>
						Calculator
                    </a>
                </li> */}
                <li>
                    <a class="no-line" href="https://mortgagebcf.com/compare">
                    	<span class="material-icons"><img src="/assets/images/status.svg" alt="" style={{height:'15px' , width:'15px'}} /></span>
						Compare Mortgage
                    </a>
                </li>
                <li>
                    <a class="no-line" href="https://www.broker.mortgagebcf.com/login">
                    	<span class="material-icons"><img src="/assets/images/status.svg" alt="" style={{height:'15px' , width:'15px'}} /></span>
						Status
                    </a>
                </li>
               
				
				<li>
                    <a class="no-line" href="https://www.broker.mortgagebcf.com/login">
                    	<span class="material-icons"><img src="/assets/images/setting.svg" alt=""  style={{height:'15px' , width:'15px'}}/></span>
						Profile Settings
                    </a>
                </li>
                {/* <li>
                    <a class="no-line" href="https://user.mortgagebcf.com/">
                    	<span class="material-icons"><img src="/assets/images/share.svg" alt="" style={{height:'15px' , width:'15px'}} /></span>
						Social Media
                    </a>
                </li> */}
    </ul>
    <div class="close-btn" onClick={hideSidebar}><span class="material-icons"><img src="/assets/images/close.svg" alt="" style={{height:'24px' , width:'24px'}} /></span></div>
    <a class="sidebar-logout no-line main-default-btn2" style={{height:'60px'}} href="https://user.mortgagebcf.com/"><span><img src="/assets/images/logout.svg" alt="" style={{width:'24px'}}/></span>Sign Up as User</a>
       
</div>
      <div class="main-navbar" style={{backgroundColor:'white' , color:'black' , }} >

<nav class="navbar navbar-style-two navbar-expand-lg navbar-light"  style={{backgroundColor:'white'}}>
    <div class="container-fluid">
   
        <div class="logo">
        <button onClick={showSidebar} class="navbar-toggler2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
      <a href="https://mortgagebcf.com/"> 
                <img src="/assets/images/cropped-logo.png" className="white-logo" alt="Logo" style={{height:'70px'}}/>
            </a>
        </div>
      
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav">
               
            </ul>

            <div class="others-option">
                <div class="d-flex align-items-center">
                    <div class="option-item">
                        <a href="tel:+971 42014000" class="call-btn" style={{color:'black'}}>Call Us: +971 42014000</a>
                    </div>
                    
                    <div class="option-item">
                        <a href="https://user.mortgagebcf.com/" class="sign-up">Sign In as User </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</nav>
</div>
        </div>
    )
  }
  
  export default Sidebar