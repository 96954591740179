import axios from "axios";

const setHeader = (headerCred) => {
  return {
    ...headerCred,
    "Accept": "*/*",
  };
}

const byAxios = async (
  {
    method,
    route,
    headerCred,
    data,
  }
) => {
  const headers = setHeader(headerCred);

  try {
    const response = await axios({
      method,
      url: route,
      headers,
      data      
    });

    if (response?.data?.data) {
      return response.data.data;
    }
    return response.data;
  }
  catch (err) {
    return null;
  }

}

export default byAxios;