import { useState, useEffect } from "react";
import { Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import AWS from 'aws-sdk'
import { getCountryCode } from "./Validator";
import { signup } from '../../actions/auth';

AWS.config.update({
    accessKeyId: 'AKIAWPEJKNXDD472SWMI',
    secretAccessKey: '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'
})

const useForm = ({ initState, callback, validator, phoneNumber }) => {
  console.log('phoneNumber',phoneNumber)
  const { addToast } = useToasts(); 
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const [loading, setLoading ] = useState(false)
  const [state, setState] = useState(initState);
  const [errors, setErrors] = useState({});
  const [isSubmited, setIsSubmited] = useState(false);
  const [countryCode, setCountryCode] = useState("GB");

  useEffect(() => {
    const isValidErrors = () =>
      Object.values(errors).filter(error => typeof error !== "undefined")
        .length > 0;
    if (isSubmited && !isValidErrors()) callback();
  }, [errors]);

  const handleChange = e => {
    const { name, value } = e.target;
    setState(() => ({
      ...state,
      [name]: value
    }));
    // if (name === "phone") {
    //   const country = getCountryCode(value);
    //   setCountryCode(() => country);
    // }
  };

  const handleBlur = e => {
    const { name: fieldName } = e.target;
    const faildFiels = validator(state, fieldName);
    setErrors(() => ({
      ...errors,
      [fieldName]: Object.values(faildFiels)[0]
    }));
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    dispatch(signup({email:state.email, name:state.name}))
    setLoading(true);
    try {
      await Auth.signUp({
        username: state.email,
        password: state.email,
        attributes:{
          email: state.email,
          phone_number: phoneNumber,
          name: state.name.replace(/ /g, ''),
          'custom:CompanyName': state.companyName
        }
      })
      const result = await Auth.signIn(state.email);
      window.cognitoUser=result

      navigate('/confirmation')
    }
    catch (error) {
      addToast(error.message, {
        appearance:'error',
        autoDismiss:true
      })
    }
    setLoading(false)
  };

  return {
    handleChange,
    handleSubmit,
    handleBlur,
    state,
    errors,
    countryCode,
    loading,
  };
};

export default useForm;
