import { useState, useEffect } from "react";
import { Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import * as AWS from 'aws-sdk';
import { income } from '../../actions/income';

import { CASE_LAMBDAS } from './../../configs/lambdas'

import byAxios from './../../services/byAxios';
// ******************************

const useForm = ({ initState, callback, validator, moveHandle, nextClick, email, local_client, setLastTab }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const docClient = new AWS.DynamoDB.DocumentClient()
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(initState);
  const [errors, setErrors] = useState({});
  const [isSubmited, setIsSubmited] = useState(false);

  // ******************************
  useEffect(() => {
    const isValidErrors = () =>
      Object.values(errors).filter(error => typeof error !== "undefined")
        .length > 0;
    if (isSubmited && !isValidErrors()) callback();
  }, [errors]);

  // ******************************
  let [count, setCount] = useState('');

  function incrementCount() {
    setCount(count => count + 1);
  }
  function decrementCount() {
    if (count > 0) {
      setCount(count => count - 1);
    }
  }

  const handleChange = e => {
    const { name, value } = e.target;
      const formattedValue = (Number(value.replace(/\D/g, '')) || '').toLocaleString();
    setState(() => ({
      ...state,
      [name]: formattedValue || value
    }));
  };

  // ******************************
  const handleBlur = e => {
    const { name: fieldName } = e.target;
    const faildFiels = validator(state, fieldName);
    setErrors(() => ({
      ...errors,
      [fieldName]: Object.values(faildFiels)[0]
    }));
  };

  // ******************************
  const handleSubmit = async (e) => {
    e.preventDefault();
    moveHandle(4);
    nextClick('documents');
    const payload = {
      monthlySalary: state?.monthlySalary,
      addIncome: state?.addIncome,
      addIncomeType: state?.addIncomeType,
      creditCard: state?.creditCard,
      monthlyInstallments: state?.monthlyInstallments,
      emirate: state?.emirate,
      loanAmount: state?.loanAmount,
      mortgageLength:state?.mortgageLength,
      propertyStatus: state?.propertyStatus,
      propertyValue: state?.propertyValue,
      downPayment: state?.downPayment,
      mortgageType: state?.mortgageType,
      residualFee: state?.residualFee,
      transactionType: state?.transactionType,
    }
    local_client = (local_client || []).map((item, index) => {
      if (item.client.email === email) {
        return {
          ...item,
          income: payload
        }
      }
      return item
    })
    localStorage.setItem('cases', JSON.stringify(local_client));

    try {
      let session = await Auth.currentSession();
      console.log(`session`, session);
      console.log(`income payload`, payload);
      const updateRes = await byAxios({
        route: CASE_LAMBDAS.UPDATE_CASE.url,
        method: CASE_LAMBDAS.UPDATE_CASE.method,
        headerCred: {
          "x-access-token": session?.accessToken?.jwtToken,
          "x-id-token": session?.idToken?.jwtToken
        },
        data: {
          ...payload,
          client_id: searchParams.get(`caseId`),
          // last_location: 2
        }
      });

      console.log(`updateRes in inco`, updateRes);

    }
    catch (errors) {
      console.log('errors', errors)
    }
    setLastTab(2);
  };

  return {
    handleChange,
    handleSubmit,
    handleBlur,
    state,
    errors,
    incrementCount,
    decrementCount,
    count
  };
};

export default useForm;
