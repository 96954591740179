import { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { Storage } from "aws-amplify";
// ** MUI Imports
import {
  Box,
  Button,
  Stack,
  styled
} from '@mui/material'
import Typography from '@mui/material/Typography'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { useSelector } from 'react-redux'
// ** Third Party Imports
import { useToasts } from 'react-toast-notifications'; 
import { Link, useSearchParams } from 'react-router-dom';
import { CASE_LAMBDAS } from './../../configs/lambdas'
import byAxios from './../../services/byAxios';

import CustButton from '../CustButton'
import DropZone from './dropzone'
import FileTile from './fileTile'
import './index.css'
import { ConsoleLogger } from '@aws-amplify/core';
// Styled component for the heading inside the dropzone area

const UploadButton = styled(Button)((props) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#6E4EE7',
  padding: '10px 0px',
  maxWidth: '250px',
  maxHeight: '50px',
  width: '100%',
  textTransform: 'none',
  color: 'white',
  '&:hover': {
    boxShadow: '0px 0px 10px black',
    color: 'black'
  }
}))

const Bankforms = ({ moveHandle, nextClick, setLastTab, lastTab }) => {
  const { addToast } = useToasts();
  const [searchParams, setSearchParams] = useSearchParams();
  const email = useSelector(state => state.client.currentUser.email)

  // const [local_income, set_local_income] = useState(income)
  const bankList = JSON.parse(localStorage.getItem('bankList'));
  console.log('bankList',bankList)
  const [uploadFiles, setUploadFiles] = useState([]);
  console.log('uploadFiles',uploadFiles)
  //const [bankForms, setBankForms] = useState([]);
  const [local, setLocal] = useState(false);
  console.log('local',local)
  const handleDropFiles = (item, files) => {
    console.log('files', files)
    console.log('item',item)
    if(files[0].size <= 26214400) {
      setUploadFiles([...uploadFiles, { name: item?.name, image: item?.image, file: files[0] }]);
    }
    else {
      addToast('You can not select files larger than 25MB.', {
        appearance:'error',
        autoDismiss:true
      })
    }
  };

  const updateProgerss = (p) => {
    const intervel = setTimeout(() => {
      console.log(p);
      if (p > 100) {
        console.log('cleared');
        clearInterval(intervel);
        return;
      }

      uploadFiles.forEach((f) => f.percent = p);
      updateProgerss(p + 1);
      //setUploadFiles([...uploadFiles]);
    }, 100)
  };

  const handleRemove = (f) => {
    setUploadFiles(uploadFiles.filter(x => x.file !== f));
  };

  const handleBankForms = async () => {
    moveHandle(8);
    nextClick('documents');
    try {
      let session = await Auth.currentSession();
      let username = searchParams.get(`caseId`)
      let index;
      console.log('uploadFiles',uploadFiles)
        for (index = 0; index <= uploadFiles.length - 1; index++ ) {
          let file=uploadFiles[index].file;
          let fileExt = file.name.split('.').pop()
          let path=username + "/" + `bank${index + 1}.` + fileExt
          let bankName = uploadFiles[index].name
          const result = await Storage.put(path, file, {
            contentType: uploadFiles[index].type,
          });
          let incomeData = {};
          incomeData[`bank${index + 1}_path`] = path
          incomeData[`bank${index + 1}_name`] = bankName
          const response = await byAxios({
            route: CASE_LAMBDAS.UPDATE_CASE.url,
            method: CASE_LAMBDAS.UPDATE_CASE.method,
            headerCred: {
              "x-access-token": session?.accessToken?.jwtToken,
              "x-id-token": session?.idToken?.jwtToken
            },
            data: {
              ...incomeData,
              client_id: searchParams.get(`caseId`),
            }
          });
        }
        
    }
    catch (error) {
      console.log(error)
    }
    setLastTab(6);
  }

  useEffect(() => {
    (async () => {
      if (searchParams.get(`caseId`)) {
        try {
          let session = await Auth.currentSession();
          const caseData = await byAxios({
            route: `${CASE_LAMBDAS.GET_DETAIL.url}/${searchParams.get(`caseId`)}`,
            method: CASE_LAMBDAS.GET_DETAIL.method,
            headerCred: {
              "x-access-token": session?.accessToken?.jwtToken,
              "x-id-token": session?.idToken?.jwtToken
            }
          });
          console.log('fetch', caseData)
          if (caseData) {
            // const banks = JSON.parse(caseData?.bank_formats) || [];
            setLocal(Object.keys(caseData).includes('bank1_path'))
          }
        }
        catch (err) {

        }
      }
    })()

  }, [lastTab])

  return (
    <Box >
      <Typography variant='body2' sx={{
        p: '20px 0px',
        textAlign: 'left'
      }}>
        Upload the application form from the banks selected. Make sure the required forms have been filed in and signed by your client.
      </Typography>

      <Box sx={{
        boxShadow: '0px 0px 10px grey',
        borderRadius: `5px`,
        p: 2
      }} >

        {bankList?.map((item, index) =>
          <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>
              <Button
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  textTransform: 'none',
                  color: 'black'
                }}
              >
                <img
                  style={{ width: '50px' }}
                  src={item.image}
                />
                <Typography
                  sx={{
                    textTransform: `uppercase`,
                    fontSize: {xs:'10px',sm:'10px', md:'16px', lg:'18px'},
                    fontWeight: 600
                  }}
                >
                  {item.name}
                </Typography>
              </Button>
              {
                // local.length < 1 ? 
                uploadFiles.map((f, i) =>
                  item.name === f.name &&
                  <FileTile key={index} file={f.file} onRemove={handleRemove} />
                ) 
                // :
                //   <div className="file-tile">
                //     <span style={{ width: '100%' }} >{item.file} </span>
                //   </div>
              }
            </div>
            { 
              local ? 
              <div className="drop-bank">
                <label className="select-bank">
                  {"File is already selected."} 
                </label>
              </div>
              :
              <DropZone files={uploadFiles} onDropFiles={(file) => handleDropFiles(item, file)} />
            }

          </div>
        )}

      </Box>

      <Box
        sx={{
          p: '30px 0px',
          display: 'flex',
          justifyContent: 'space-between'
        }}>
        <Box sx={{
          display: 'flex',
          flexDirection: { xs: 'column', ss: 'unset' }
        }}>
        </Box>
        {
          // local ? <></> :
            <Stack direction='row' spacing={5} style={{ width: '400px' }}>
              <UploadButton
                onClick={async () => { await handleBankForms() }}
              >
                Next step
              </UploadButton>
            </Stack>
        }

      </Box>
    </Box>
  )
}

export default Bankforms