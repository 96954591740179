import React, { useState } from 'react';
import { 
  CircularProgress, 
  Button, 
  Avatar, 
  CssBaseline, 
  TextField, 
  Grid,
  Box,
  Typography,
  Container,
  styled
} from '@mui/material';
import { Link } from 'react-router-dom';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
// import PhoneNumber from "./PhoneNumber";
import { validator } from "./Validator";
import useForm from "./useForm";
import Sidebar from '../firstview/Sidebar';
const theme = createTheme();

const StyledPhoneInput = styled(PhoneInput)(({theme}) => ({
  '& input' : {
    padding:'17px'
  }
}))

export default function SignUp() {
  const [phoneNumber, setPhoneNumber] = useState('')
  const initState = {
    name: "",
    companyName: "",
    // phone: "+44",
    email:"",
  };

  const submit = () => {
    console.log(" Submited");
  };
  
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    state,
    errors,
    countryCode,
    loading,
  } = useForm({
    initState,
    callback: submit,
    validator,
    phoneNumber
  });

  return (
    <body>
       <Sidebar/>
<div id="home" class="confidence-home-area col-lg-12 mt-5" style={{ width:'100%' , paddingBottom:'0px !important', marginLeft:'0px ' }}>
            <div class="container-fluid">
                <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12" style={{textAlign: 'center'}}>
                        <div class="confidence-home-content more-neg-signup">
                        <span class="sub-title" style={{fontSize: '25px'}}>Leading mortgage broker</span>
                        <h2 style={{fontSize:'35px' , fontWeight:'bold'}}>Your Personalised Mortgage Broker</h2>
                    </div>
                    </div>

                    <div class="col-lg-6 col-md-12" style={{textAlign: 'center'}}>
                        <div class="confidence-home-content">
                        
                        <span class="sub-title" style={{fontSize: '15px' , textAlign:'center'}}>
                        <Avatar sx={{ m: 1, bgcolor: '#00C195' }} style={{marginRight:'auto',
                      marginLeft:'auto'}}>
            <LockOutlinedIcon />
          </Avatar>
                          Already have an account?&nbsp;
            <Link to='/login'>Sign In</Link></span>
                        <h2>Sign Up for Brokers</h2>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} lg={12}>
                <TextField
                  autoComplete="given-name"
                  name="name"
                  required
                  fullWidth
                  id="name"
                  label="Your Name"
                  onChange={handleChange}
                  value={state.name}
                  error={errors.name ? true : false}
                  helperText={errors.name}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="companyName"
                  label="Company Name"
                  name="companyName"
                  autoComplete="companyName"
                  onChange={handleChange}
                  value={state.companyName}
                />
              </Grid>
              <Grid item xs={6}>
               
                 <StyledPhoneInput
                  className='phone-number-input'
                  // disabled={bcf_phone_number}
                  type='tel'
                  placeholder="Enter phone number"
                  international
                  defaultCountry="AE"
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={handleChange}
                  value={state.email}
                  error={errors.email ? true : false}
                  helperText={errors.email}
                  onBlur={handleBlur}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              class='main-default-btn2'
              style={{width:'280px'}}
              sx={{ mt: 3, mb: 2, textTransform: 'none', background:'#00C195' }}
            >
            {loading && <CircularProgress />}
              Sign Up
            </Button>
            <Grid container justifyContent="center">
              <Grid item>
                <Link to="/login" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
                     
                        </div>
                        </div>

                    <div class="col-lg-6 col-md-12" style={{textAlign: 'center'}}>
                        <div class="confidence-home-content more-neg-mar-signup" >
                        <p style={{textAlign:'center',margin:'auto'}}>
                    Bandenia will save you a lot of time and stress; We will handle everything from searching for a deal to applying and communicating with the lender on your behalf.
                     Expert in the mortgage market and will be able to recommend deals that suit your situation.</p>
                     <p style={{textAlign:'center',margin:'auto' , fontWeight:'bold'}}>
                     Empanel with us as a Referral Broker Partner and get your pay-out within 48 hours of registering the mortgage deal at Land Department (On submission of Title Deed) <br/>

                    *Conditions Apply please contact us for more details <a href="mailto:contact@bcf.ae"> (contact@bcf.ae)</a>
                    </p>
                        <a href="#" class="main-default-btn2" >Islamic Finance</a>
                        <a href="#" class="main-default-btn2" >Conventional Finance</a>
                        <a href="#" class="main-default-btn2" >Instant Quotation</a>
                         </div>
                        </div>
                        

                </div>
            </div>
        </div>
       {/* <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: {xs:'30%', sm:'30%', md:'120px', lg:'120px'},
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingBottom:'20px'
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: '#00C195' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} lg={12}>
                <TextField
                  autoComplete="given-name"
                  name="name"
                  required
                  fullWidth
                  id="name"
                  label="Your Name"
                  onChange={handleChange}
                  value={state.name}
                  error={errors.name ? true : false}
                  helperText={errors.name}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="companyName"
                  label="Company Name"
                  name="companyName"
                  autoComplete="companyName"
                  onChange={handleChange}
                  value={state.companyName}
                />
              </Grid>
              <Grid item xs={6}>
                {/* <PhoneNumber
                  fullWidth
                  errors={errors}
                  state={state}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  countryCode={countryCode}
                /> */}
                 {/* <StyledPhoneInput
                  className='phone-number-input'
                  // disabled={bcf_phone_number}
                  type='tel'
                  placeholder="Enter phone number"
                  international
                  defaultCountry="AE"
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={handleChange}
                  value={state.email}
                  error={errors.email ? true : false}
                  helperText={errors.email}
                  onBlur={handleBlur}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, textTransform: 'none', background:'#00C195' }}
            >
            {loading && <CircularProgress />}
              Sign Up
            </Button>
            <Grid container justifyContent="center">
              <Grid item>
                <Link to="/login" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container> */} 
    </body> 
  );
}