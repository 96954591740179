import React, { useState } from 'react';
import {
  Button,
  CssBaseline,
  TextField,
  Box,
  Typography,
  CircularProgress
} from '@mui/material'
import { Link } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Auth } from 'aws-amplify';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import Sidebar from '../firstview/Sidebar';
const theme = createTheme();

export default function Confirmation() {

  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false)
  const [code, setCode] = useState('')
  const email = useSelector(state => state.auth.currentUser.email);
  const name = useSelector(state => state.auth.currentUser.name)
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let response = await Auth.sendCustomChallengeAnswer(window.cognitoUser, code)
      console.log('conformation', response)
      if (response.signInUserSession == null) {
        addToast("Invalid Code", {
          appearance: 'error',
          autoDismiss: true
        })
      }
      else if (name) {
        addToast('Confirming your account success', {
          appearance: 'success',
          autoDismiss: true
        })
        navigate('/upload')
      }
      else {
        console.log('cases')
        addToast('Confirming your account success', {
          appearance: 'success',
          autoDismiss: true
        })
        navigate('/cases')
      }
    }
    catch (error) {
      addToast(error.message, {
        appearance: 'error',
        autoDismiss: true
      })
    }
    setLoading(false)
  };

  return (
    <body>
  <Sidebar/>
<div id="home" class="confidence-home-area" style={{paddingTop:'130px' , width:'100%' , paddingBottom:'0px !important', marginLeft:'0px ' }}>
            <div class="container-fluid">
                <div class="row align-items-center mt-5">
                        <div class="col-lg-6 col-md-12" style={{textAlign: 'center'}}>
                        <div class="confidence-home-content pos-confirm">
                        <span class="sub-title" style={{fontSize: '25px'}}>Leading mortgage broker</span>
                        <h2 style={{fontSize:'35px' , fontWeight:'bold'}}>Your Personalised Mortgage Broker</h2>
                        </div>
                        </div>
                        <div class="col-lg-6 col-md-12" style={{textAlign: 'center'}}>
                        <div class="confidence-home-content">
                       
                        <h2 style={{marginTop:'50px'}}>Confirm for Brokers</h2>
                         <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="verification"
              label="Verification Code"
              name="verification"
              autoComplete="verification"
              autoFocus
              onChange={e => setCode(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              class='main-default-btn2'
              style={{width:'280px'}}
              sx={{
                mt: 3,
                mb: 2,
                textTransform: 'none',
                background: '#00C195'
              }}
            >
              {loading && <CircularProgress />}
              Confirm
            </Button>
            </Box>
                        </div>
                        </div>
                        <div class="col-lg-6 col-md-12" style={{textAlign: 'center'}}>
                        <div class="confidence-home-content neg-confirm">
                         <p style={{textAlign:'center',margin:'auto'}}>
                    Bandenia will save you a lot of time and stress; We will handle everything from searching for a deal to applying and communicating with the lender on your behalf.
                     Expert in the mortgage market and will be able to recommend deals that suit your situation.</p>
                     <p style={{textAlign:'center',margin:'auto', fontWeight:'bold'}}>
                     Empanel with us as a Referral Broker Partner and get your pay-out within 48 hours of registering the mortgage deal at Land Department (On submission of Title Deed) <br/>

*Conditions Apply please contact us for more details <a href="mailto:contact@bcf.ae"> (contact@bcf.ae)</a>
</p>
                    
                        <a href="#" class="main-default-btn2" >Islamic Finance</a>
                        <a href="#" class="main-default-btn2" >Conventional Finance</a>
                        <a href="#" class="main-default-btn2" >Instant Quotation</a>
                         </div>
                        </div>

                </div>
            </div>
        </div>
      {/* <Box component="main" sx={{
        maxWidth: '450px',
        width: '100%',
        p: '20px',
        marginTop: {xs:'30%', sm:'30%', md:'120px', lg:'120px'}
      }}>
        <CssBaseline />
        <Box>
          <Typography>Already have access?&nbsp;
            <Link to='/login'>Sign in</Link>
          </Typography>
        </Box>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography component="h1" variant="h4">
            Confirm for Brokers
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="verification"
              label="Verification Code"
              name="verification"
              autoComplete="verification"
              autoFocus
              onChange={e => setCode(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                textTransform: 'none',
                background: '#00C195'
              }}
            >
              {loading && <CircularProgress />}
              Confirm
            </Button>
          </Box>
        </Box>
      </Box> */}
    </body>
  );
}
