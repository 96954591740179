import { useState, useEffect } from 'react';
import { Auth, Storage } from 'aws-amplify';
import './index.css';
// ** MUI Imports
import {
  Box,
  Typography,
  styled,
  Button,
  Paper,
  Grid,
  Stack,
  MenuItem,
  InputLabel,
  FormControl

} from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { useSelector } from 'react-redux';
// ** Third Party Imports
import { useToasts } from 'react-toast-notifications';
import { Link, useSearchParams } from 'react-router-dom';
import { CASE_LAMBDAS } from './../../configs/lambdas'
import byAxios from './../../services/byAxios';

import CustButton from '../CustButton'
import DropZone from './dropzone'
import FileTile from './fileTile'
import './index.css'
// Styled component for the heading inside the dropzone area

const UploadButton = styled(Button)((props) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#6E4EE7',
  padding: '10px 0px',
  maxWidth: '140px',
  maxHeight: '50px',
  width: '100%',
  textTransform: 'none',
  color: 'white',
  '&:hover': {
    boxShadow: '0px 0px 10px black',
    color: 'black'
  }
}))

const Documents = ({ moveHandle, nextClick, setLastTab, lastTab }) => {
  const { addToast } = useToasts();
  const [searchParams, setSearchParams] = useSearchParams();
  const firstName = useSelector(state => state.client.currentUser.firstName);
  const lastName = useSelector(state => state.client.currentUser.lastName);
  const email = useSelector(state => state.client.currentUser.email);
  const [document, setDocument] = useState('');

  const [uploadTradeFiles, setUploadTradeFiles] = useState([]);
  const [localTrade, setLocalTrade] = useState('');
  const [localTrade2, setLocalTrade2] = useState('');
  const [localTrade3, setLocalTrade3] = useState('');
  const [localTrade4, setLocalTrade4] = useState('');
  const [tradeBlock, setTradeBlock] = useState(false);

  const [uploadCreditFiles, setUploadCreditFiles] = useState([]);
  const [localCredit, setLocalCredit] = useState('');
  const [localCredit2, setLocalCredit2] = useState('');
  const [localCredit3, setLocalCredit3] = useState('');
  const [localCredit4, setLocalCredit4] = useState('');
  const [creditBlock, setCreditBlock] = useState(false);

  const [uploadAuditFiles, setUploadAuditFiles] = useState([]);
  const [localAudit, setLocalAudit] = useState('');
  const [localAudit2, setLocalAudit2] = useState('');
  const [localAudit3, setLocalAudit3] = useState('');
  const [localAudit4, setLocalAudit4] = useState('');
  const [auditBlock, setAuditBlock] = useState(false);

  const [uploadOfferLetterFiles, setUploadOfferLetterFiles] = useState([]);
  const [localOfferLetter, setLocalOfferLetter] = useState('');
  const [localOfferLetter2, setLocalOfferLetter2] = useState('');
  const [localOfferLetter3, setLocalOfferLetter3] = useState('');
  const [localOfferLetter4, setLocalOfferLetter4] = useState('');
  const [offerBlock, setOfferLetterBlock] = useState(false);

  const [uploadMOAFiles, setUploadMOAFiles] = useState([]);
  const [localMOA, setLocalMOA] = useState('');
  const [localMOA2, setLocalMOA2] = useState('');
  const [localMOA3, setLocalMOA3] = useState('');
  const [localMOA4, setLocalMOA4] = useState('');
  const [MOABlock, setMOABlock] = useState(false);

  const [uploadShareFiles, setUploadShareFiles] = useState([]);
  const [localShare, setLocalShare] = useState('');
  const [localShare2, setLocalShare2] = useState('');
  const [localShare3, setLocalShare3] = useState('');
  const [localShare4, setLocalShare4] = useState('');
  const [shareBlock, setShareBlock] = useState(false);

  const [uploadIncomeFiles, setUploadIncomeFiles] = useState([]);
  const [localIncome, setLocalIncome] = useState('');
  const [localIncome2, setLocalIncome2] = useState('');
  const [localIncome3, setLocalIncome3] = useState('');
  const [localIncome4, setLocalIncome4] = useState('');
  const [incomeBlock, setIncomeBlock] = useState(false);

  const [uploadPassFiles, setUploadPassFiles] = useState([]);
  const [localPass, setLocalPass] = useState('');
  const [localPass2, setLocalPass2] = useState('');
  const [localPass3, setLocalPass3] = useState('');
  const [localPass4, setLocalPass4] = useState('');
  const [passBlock, setPassBlock] = useState(false);

  
  //console.log('localPass', localPass)
  const [uploadVisaFiles, setUploadVisaFiles] = useState([]);
  console.log('uploadVisaFiles',uploadVisaFiles)
  const [localVisa, setLocalVisa] = useState('');
  const [localVisa2, setLocalVisa2] = useState('');
  const [localVisa3, setLocalVisa3] = useState('');
  const [localVisa4, setLocalVisa4] = useState('');
  const [visaBlock, setVisaBlock] = useState(false);
  
  const [uploadEIDFiles, setUploadEIDFiles] = useState([]);
  const [localEID, setLocalEID] = useState('');
  const [localEID2, setLocalEID2] = useState('');
  const [localEID3, setLocalEID3] = useState('');
  const [localEID4, setLocalEID4] = useState('');
  const [EIDBlock, setEIDBlock] = useState(false);
  
  const [uploadSalaryFiles, setUploadSalaryFiles] = useState([]);
  const [localSalary, setLocalSalary] = useState('');
  const [localSalary2, setLocalSalary2] = useState('');
  const [localSalary3, setLocalSalary3] = useState('');
  const [localSalary4, setLocalSalary4] = useState('');
  const [salaryBlock, setSalaryBlock] = useState(false);
  
  const [uploadBankFiles, setUploadBankFiles] = useState([]);
  const [localBank, setLocalBank] = useState('');
  const [localBank2, setLocalBank2] = useState('');
  const [localBank3, setLocalBank3] = useState('');
  const [localBank4, setLocalBank4] = useState('');
  const [bankBlock, setBankBlock] = useState(false);
  
 
  const [loading, setLoading] = useState(false)
 
  const [employed, setEmployment] = useState('');
  console.log(employed)
  
  const [other, setoOther] = useState('Other :');
  const handlePassFile = async (file, id) => {
    console.log('file', file)
    if(file.size <= 26214400) {
      setUploadPassFiles([...uploadPassFiles,
      {
        file: file
      }])
    }
    else {
      addToast('You can not select file larger than 25MB', {
        appearance:'error',
        autoDismiss:true
      })
    }
  };

  const handleTradeFile = async (file, id) => {
    console.log('file', file)
    if(file.size <= 26214400) {
      setUploadTradeFiles([...uploadTradeFiles,
      {
        file: file
      }])
    }
    else {
      addToast('You can not select file larger than 25MB', {
        appearance:'error',
        autoDismiss:true
      })
    }
  };

  const handleAuditFile = async (file, id) => {
    console.log('file', file)
    if(file.size <= 26214400) {
      setUploadAuditFiles([...uploadAuditFiles,
      {
        file: file
      }])
    }
    else {
      addToast('You can not select file larger than 25MB', {
        appearance:'error',
        autoDismiss:true
      })
    }
  };

  const handleMOAFile = async (file, id) => {
    console.log('file', file)
    if(file.size <= 26214400) {
      setUploadMOAFiles([...uploadMOAFiles,
      {
        file: file
      }])
    }
    else {
      addToast('You can not select file larger than 25MB', {
        appearance:'error',
        autoDismiss:true
      })
    }
  };

  const handleCreditFile = async (file, id) => {
    console.log('file', file)
    if(file.size <= 26214400) {
      setUploadCreditFiles([...uploadCreditFiles,
      {
        file: file
      }])
    }
    else {
      addToast('You can not select file larger than 25MB', {
        appearance:'error',
        autoDismiss:true
      })
    }
  };


  const handleIncomeFile = async (file, id) => {
    console.log('file', file)
    if(file.size <= 26214400) {
      setUploadIncomeFiles([...uploadIncomeFiles,
      {
        file: file
      }])
    }
    else {
      addToast('You can not select file larger than 25MB', {
        appearance:'error',
        autoDismiss:true
      })
    }
  };

  const handleOfferLetterFile = async (file, id) => {
    console.log('file', file)
    if(file.size <= 26214400) {
      setUploadOfferLetterFiles([...uploadOfferLetterFiles,
      {
        file: file
      }])
    }
    else {
      addToast('You can not select file larger than 25MB', {
        appearance:'error',
        autoDismiss:true
      })
    }
  };

  const handleShareFile = async (file, id) => {
    console.log('file', file)
    if(file.size <= 26214400) {
      setUploadShareFiles([...uploadShareFiles,
      {
        file: file
      }])
    }
    else {
      addToast('You can not select file larger than 25MB', {
        appearance:'error',
        autoDismiss:true
      })
    }
  };

  const handleBankFile = async (file, id) => {
    console.log('file', file)
    if(file.size <= 26214400) {
      setUploadBankFiles([...uploadBankFiles,
      {
        file: file
      }])
    }
    else {
      addToast('You can not select file larger than 25MB', {
        appearance:'error',
        autoDismiss:true
      })
    }
  };

  // const handleEarningFile = async (file, id) => {
  //   console.log('file', file)
  //   if(file.size <= 26214400) {
  //     setUploadEarningFiles([...uploadEarningFiles,
  //     {
  //       file: file
  //     }])
  //   }
  //   else {
  //     addToast('You can not select file larger than 25MB', {
  //       appearance:'error',
  //       autoDismiss:true
  //     })
  //   }
  // };

  // const handleOtherFile = async (file, id) => {
  //   console.log('file', file)
  //   if(file.size <= 26214400) {
  //     setUploadOtherFiles([...uploadOtherFiles,
  //     {
  //       file: file
  //     }])
  //   }
  //   else {
  //     addToast('You can not select file larger than 25MB', {
  //       appearance:'error',
  //       autoDismiss:true
  //     })
  //   }
  // };

  const handleVisaFile = async (file, id) => {
    if(file.size <= 26214400) {
      setUploadVisaFiles([...uploadVisaFiles,
      {
        file: file
      }])
    }
    else {
      addToast('You can not select file larger than 25MB', {
        appearance:'error',
        autoDismiss:true
      })
    }
  };

  const handleEIDFile = async (file, id) => {
    if(file.size <= 26214400) {
      setUploadEIDFiles([...uploadEIDFiles,
      {
        file: file
      }])
    }
    else {
      addToast('You can not select file larger than 25MB', {
        appearance:'error',
        autoDismiss:true
      })
    }
  };
  const handleChange = (event) => {
    setDocument(event.target.value );
  };

  const handleSalaryFile = async (file, id) => {
    if(file.size <= 26214400) {
      setUploadSalaryFiles([...uploadSalaryFiles,
      {
        file: file
      }])
    }
    else {
      addToast('You can not select file larger than 25MB', {
        appearance:'error',
        autoDismiss:true
      })
    }
  };
  const handlePassDropFiles = (files) => {
    files.forEach((f, i) => handlePassFile(f, `${new Date().getTime()}-${i}`))
    setPassBlock(true)
  };

  const handleTradeDropFiles = (files) => {
    files.forEach((f, i) => handleTradeFile(f, `${new Date().getTime()}-${i}`))
    setTradeBlock(true)
  };

  const handleAuditDropFiles = (files) => {
    files.forEach((f, i) => handleAuditFile(f, `${new Date().getTime()}-${i}`))
    setAuditBlock(true)
  };

  const handleMOADropFiles = (files) => {
    files.forEach((f, i) => handleMOAFile(f, `${new Date().getTime()}-${i}`))
    setMOABlock(true)
  };

  const handleShareDropFiles = (files) => {
    files.forEach((f, i) => handleShareFile(f, `${new Date().getTime()}-${i}`))
    setShareBlock(true)
  };

  const handleOfferLetterDropFiles = (files) => {
    files.forEach((f, i) => handleOfferLetterFile(f, `${new Date().getTime()}-${i}`))
    setOfferLetterBlock(true)
  };

  const handleCreditDropFiles = (files) => {
    files.forEach((f, i) => handleCreditFile(f, `${new Date().getTime()}-${i}`))
    setCreditBlock(true)
  };

  const handleIncomeDropFiles = (files) => {
    files.forEach((f, i) => handleIncomeFile(f, `${new Date().getTime()}-${i}`))
    setIncomeBlock(true)
  };

  // const handleAgreementDropFiles = (files) => {
  //   files.forEach((f, i) => handleAgreementFile(f, `${new Date().getTime()}-${i}`))
  //   setAgreementBlock(true)
  // };

  const handleBankDropFiles = (files) => {
    files.forEach((f, i) => handleBankFile(f, `${new Date().getTime()}-${i}`))
    setBankBlock(true)
  };

  // const handleEarningDropFiles = (files) => {
  //   files.forEach((f, i) => handleEarningFile(f, `${new Date().getTime()}-${i}`))
  //   setEarningBlock(true)
  // };

  // const handleOtherDropFiles = (files) => {
  //   files.forEach((f, i) => handleOtherFile(f, `${new Date().getTime()}-${i}`));
  //   setOtherBlock(true)
  // };

  const handleVisaDropFiles = (files) => {
    files.forEach((f, i) => handleVisaFile(f, `${new Date().getTime()}-${i}`))
    setVisaBlock(true)
  };

  const handleEIDDropFiles = (files) => {
    files.forEach((f, i) => handleEIDFile(f, `${new Date().getTime()}-${i}`))
    setEIDBlock(true)
  };

  const handleSalaryDropFiles = (files) => {
    files.forEach((f, i) => handleSalaryFile(f, `${new Date().getTime()}-${i}`))
    setSalaryBlock(true)
  };

  const handlePassRemove = (f) => {
    setUploadPassFiles(uploadPassFiles.filter(x => x.file.name !== f.name));
    // setPassBlock(false);
  };

  const handleAuditRemove = (f) => {
    setUploadAuditFiles(uploadAuditFiles.filter(x => x.file.name !== f.name));
    // setAuditBlock(false);
  };
  const handleCreditRemove = (f) => {
    setUploadCreditFiles(uploadCreditFiles.filter(x => x.file.name !== f.name));
    // setCreditBlock(false);
  };
  const handleIncomeRemove = (f) => {
    setUploadIncomeFiles(uploadIncomeFiles.filter(x => x.file.name !== f.name));
    // setIncomeBlock(false);
  };
  const handleShareRemove = (f) => {
    setUploadShareFiles(uploadShareFiles.filter(x => x.file.name !== f.name));
    // setShareBlock(false);
  };
  const handleMOARemove = (f) => {
    setUploadMOAFiles(uploadMOAFiles.filter(x => x.file.name !== f.name));
    // setMOABlock(false);
  };
  const handleOfferLetterRemove = (f) => {
    setUploadOfferLetterFiles(uploadOfferLetterFiles.filter(x => x.file.name !== f.name));
    // setOfferLetterBlock(false);
  };
  const handleTradeRemove = (f) => {
    setUploadTradeFiles(uploadTradeFiles.filter(x => x.file.name !== f.name));
    // setTradeBlock(false);
  };

  // const handleAgreementRemove = (f) => {
  //   setUploadAgreementFiles(uploadAgreementFiles.filter(x => x.file.name !== f.name));
  //   setAgreementBlock(false);
  // };
const submitVisa=()=>{
console.log('submit')
};

  const handleBankRemove = (f) => {
    setUploadBankFiles(uploadBankFiles.filter(x => x.file.name !== f.name));
    // setBankBlock(false);
  };

  // const handleEarningRemove = (f) => {
  //   setUploadEarningFiles(uploadEarningFiles.filter(x => x.file.name !== f.name));
  //   setEarningBlock(false);
  // };

  // const handleOtherRemove = (f) => {
  //   setUploadOtherFiles(uploadOtherFiles.filter(x => x.file.name !== f.name));
  //   setOtherBlock(false);
  // };

  const handleVisaRemove = (f) => {
    setUploadVisaFiles(uploadVisaFiles.filter(x => x.file.name !== f.name));
    // setVisaBlock(false);
  };

  const handleEIDRemove = (f) => {
    setUploadEIDFiles(uploadEIDFiles.filter(x => x.file.name !== f.name));
    // setEIDBlock(false);
  };

  const handleSalaryRemove = (f) => {
    setUploadSalaryFiles(uploadSalaryFiles.filter(x => x.file.name !== f.name));
    // setSalaryBlock(false);
  };

  const handleDocument = async () => {
    if (uploadVisaFiles[0]) {
      try {      
        let session = await Auth.currentSession();
        let username = searchParams.get(`caseId`);
        let index;
        console.log('uploadFiles',uploadVisaFiles)
        for (index = 0; index <= uploadVisaFiles.length - 1; index++ ) {
          let file=uploadVisaFiles[index].file;
          let fileExt = file.name.split('.').pop()
          let path=username + "/" + `visa${index + 1}.` + fileExt
          let visaName = uploadVisaFiles[index].name
          const result = await Storage.put(path, file, {
            contentType: uploadVisaFiles[index].type,
          });
          let incomeData = {}
          incomeData[`visa${index + 1}_path`] = path
          incomeData[`visa${index + 1}_name`] = visaName
        console.log('result',result)
        const response = await byAxios({
          route: CASE_LAMBDAS.UPDATE_CASE.url,
          method: CASE_LAMBDAS.UPDATE_CASE.method,
          headerCred: {
            "x-access-token": session?.accessToken?.jwtToken,
            "x-id-token": session?.idToken?.jwtToken
          },
          data: {
            ...incomeData,
            client_id: searchParams.get(`caseId`),
          }
        });
      }
      }
      catch(err){
        console.log('err',err)
      }
    }

    // if (uploadAgreementFiles[0]) {
    //   try {      
    //     let session = await Auth.currentSession();
    //     let username = searchParams.get(`caseId`);
    //     let index;
    //     console.log('uploadFiles',uploadAgreementFiles)
    //     for (index = 0; index <= uploadAgreementFiles.length - 1; index++ ) {
    //       let file=uploadAgreementFiles[index].file;
    //       let fileExt = file.name.split('.').pop()
    //       let path=username + "/" + `agreement${index + 1}.` + fileExt
    //       let agreementName = uploadAgreementFiles[index].name
    //       const result = await Storage.put(path, file, {
    //         contentType: uploadAgreementFiles[index].type,
    //       });
    //       let incomeData = {}
    //       incomeData[`agreement${index + 1}_path`] = path
    //       incomeData[`agreement${index + 1}_name`] = agreementName
    //     console.log('result',result)
    //     const response = await byAxios({
    //       route: CASE_LAMBDAS.UPDATE_CASE.url,
    //       method: CASE_LAMBDAS.UPDATE_CASE.method,
    //       headerCred: {
    //         "x-access-token": session?.accessToken?.jwtToken
    //       },
    //       data: {
    //         ...incomeData,
    //         client_id: searchParams.get(`caseId`),
    //       }
    //     });
    //   }
    //   }
    //   catch(err){
    //     console.log('err',err)
    //   }
    // }

    if (uploadBankFiles[0]) {
      try {      
        let session = await Auth.currentSession();
        let username = searchParams.get(`caseId`);
        let index;
        console.log('uploadFiles',uploadBankFiles)
        for (index = 0; index <= uploadBankFiles.length - 1; index++ ) {
          let file=uploadBankFiles[index].file;
          let fileExt = file.name.split('.').pop()
          let path=username + "/" + `bank${index + 1}.` + fileExt
          let BankName = uploadBankFiles[index].name
          const result = await Storage.put(path, file, {
            contentType: uploadBankFiles[index].type,
          });
          let incomeData = {}
          incomeData[`bank${index + 1}_path`] = path
          incomeData[`bank${index + 1}_name`] = BankName
        console.log('result',result)
        const response = await byAxios({
          route: CASE_LAMBDAS.UPDATE_CASE.url,
          method: CASE_LAMBDAS.UPDATE_CASE.method,
          headerCred: {
            "x-access-token": session?.accessToken?.jwtToken,
            "x-id-token": session?.idToken?.jwtToken
          },
          data: {
            ...incomeData,
            client_id: searchParams.get(`caseId`),
          }
        });
      }
      }
      catch(err){
        console.log('err',err)
      }
    }
    // if (uploadOtherFiles[0]) {
    //   try {      
    //     let session = await Auth.currentSession();
    //     let username = searchParams.get(`caseId`);
    //     let index;
    //     console.log('uploadFiles',uploadOtherFiles)
    //     for (index = 0; index <= uploadOtherFiles.length - 1; index++ ) {
    //       let file=uploadOtherFiles[index].file;
    //       let fileExt = file.name.split('.').pop()
    //       let path=username + "/" + `other${index + 1}.` + fileExt
    //       let OtherName = uploadOtherFiles[index].name
    //       const result = await Storage.put(path, file, {
    //         contentType: uploadOtherFiles[index].type,
    //       });
    //       let incomeData = {}
    //       incomeData[`other${index + 1}_path`] = path
    //       incomeData[`other${index + 1}_name`] = OtherName
    //     console.log('result',result)
    //     const response = await byAxios({
    //       route: CASE_LAMBDAS.UPDATE_CASE.url,
    //       method: CASE_LAMBDAS.UPDATE_CASE.method,
    //       headerCred: {
    //         "x-access-token": session?.accessToken?.jwtToken
    //       },
    //       data: {
    //         ...incomeData,
    //         client_id: searchParams.get(`caseId`),
    //       }
    //     });
    //   }
    //   }
    //   catch(err){
    //     console.log('err',err)
    //   }
    // }
    // if (uploadEarningFiles[0]) {
    //   try {      
    //     let session = await Auth.currentSession();
    //     let username = searchParams.get(`caseId`);
    //     let index;
    //     console.log('uploadFiles',uploadEarningFiles)
    //     for (index = 0; index <= uploadEarningFiles.length - 1; index++ ) {
    //       let file=uploadEarningFiles[index].file;
    //       let fileExt = file.name.split('.').pop()
    //       let path=username + "/" + `earning${index + 1}.` + fileExt
    //       let EarningName = uploadEarningFiles[index].name
    //       const result = await Storage.put(path, file, {
    //         contentType: uploadEarningFiles[index].type,
    //       });
    //       let incomeData = {}
    //       incomeData[`earning${index + 1}_path`] = path
    //       incomeData[`earning${index + 1}_name`] = EarningName
    //     console.log('result',result)
    //     const response = await byAxios({
    //       route: CASE_LAMBDAS.UPDATE_CASE.url,
    //       method: CASE_LAMBDAS.UPDATE_CASE.method,
    //       headerCred: {
    //         "x-access-token": session?.accessToken?.jwtToken
    //       },
    //       data: {
    //         ...incomeData,
    //         client_id: searchParams.get(`caseId`),
    //       }
    //     });
    //   }
    //   }
    //   catch(err){
    //     console.log('err',err)
    //   }
    // }
    if (uploadPassFiles[0]) {
      try {      
        let session = await Auth.currentSession();
        let username = searchParams.get(`caseId`);
        let index;
        console.log('uploadFiles',uploadPassFiles)
        for (index = 0; index <= uploadPassFiles.length - 1; index++ ) {
          let file=uploadPassFiles[index].file;
          let fileExt = file.name.split('.').pop()
          let path=username + "/" + `pass${index + 1}.` + fileExt
          let PassName = uploadPassFiles[index].name
          const result = await Storage.put(path, file, {
            contentType: uploadPassFiles[index].type,
          });
          let incomeData = {}
          incomeData[`pass${index + 1}_path`] = path
          incomeData[`pass${index + 1}_name`] = PassName
        console.log('result',result)
        const response = await byAxios({
          route: CASE_LAMBDAS.UPDATE_CASE.url,
          method: CASE_LAMBDAS.UPDATE_CASE.method,
          headerCred: {
            "x-access-token": session?.accessToken?.jwtToken,
            "x-id-token": session?.idToken?.jwtToken
          },
          data: {
            ...incomeData,
            client_id: searchParams.get(`caseId`),
          }
        });
      }
      }
      catch(err){
        console.log('err',err)
      }
    }

    if (uploadCreditFiles[0]) {
      try {      
        let session = await Auth.currentSession();
        let username = searchParams.get(`caseId`);
        let index;
        console.log('uploadFiles',uploadCreditFiles)
        for (index = 0; index <= uploadCreditFiles.length - 1; index++ ) {
          let file=uploadCreditFiles[index].file;
          let fileExt = file.name.split('.').pop()
          let path=username + "/" + `credit${index + 1}.` + fileExt
          let CreditName = uploadCreditFiles[index].name
          const result = await Storage.put(path, file, {
            contentType: uploadCreditFiles[index].type,
          });
          let incomeData = {}
          incomeData[`credit${index + 1}_path`] = path
          incomeData[`credit${index + 1}_name`] = CreditName
        console.log('result',result)
        const response = await byAxios({
          route: CASE_LAMBDAS.UPDATE_CASE.url,
          method: CASE_LAMBDAS.UPDATE_CASE.method,
          headerCred: {
            "x-access-token": session?.accessToken?.jwtToken,
            "x-id-token": session?.idToken?.jwtToken
          },
          data: {
            ...incomeData,
            client_id: searchParams.get(`caseId`),
          }
        });
      }
      }
      catch(err){
        console.log('err',err)
      }
    }

    if (uploadAuditFiles[0]) {
      try {      
        let session = await Auth.currentSession();
        let username = searchParams.get(`caseId`);
        let index;
        console.log('uploadFiles',uploadAuditFiles)
        for (index = 0; index <= uploadAuditFiles.length - 1; index++ ) {
          let file=uploadAuditFiles[index].file;
          let fileExt = file.name.split('.').pop()
          let path=username + "/" + `audit${index + 1}.` + fileExt
          let AuditName = uploadAuditFiles[index].name
          const result = await Storage.put(path, file, {
            contentType: uploadAuditFiles[index].type,
          });
          let incomeData = {}
          incomeData[`audit${index + 1}_path`] = path
          incomeData[`audit${index + 1}_name`] = AuditName
        console.log('result',result)
        const response = await byAxios({
          route: CASE_LAMBDAS.UPDATE_CASE.url,
          method: CASE_LAMBDAS.UPDATE_CASE.method,
          headerCred: {
            "x-access-token": session?.accessToken?.jwtToken,
            "x-id-token": session?.idToken?.jwtToken
          },
          data: {
            ...incomeData,
            client_id: searchParams.get(`caseId`),
          }
        });
      }
      }
      catch(err){
        console.log('err',err)
      }
    }

    if (uploadShareFiles[0]) {
      try {      
        let session = await Auth.currentSession();
        let username = searchParams.get(`caseId`);
        let index;
        console.log('uploadFiles',uploadShareFiles)
        for (index = 0; index <= uploadShareFiles.length - 1; index++ ) {
          let file=uploadShareFiles[index].file;
          let fileExt = file.name.split('.').pop()
          let path=username + "/" + `share${index + 1}.` + fileExt
          let ShareName = uploadShareFiles[index].name
          const result = await Storage.put(path, file, {
            contentType: uploadShareFiles[index].type,
          });
          let incomeData = {}
          incomeData[`share${index + 1}_path`] = path
          incomeData[`share${index + 1}_name`] = ShareName
        console.log('result',result)
        const response = await byAxios({
          route: CASE_LAMBDAS.UPDATE_CASE.url,
          method: CASE_LAMBDAS.UPDATE_CASE.method,
          headerCred: {
            "x-access-token": session?.accessToken?.jwtToken,
            "x-id-token": session?.idToken?.jwtToken
          },
          data: {
            ...incomeData,
            client_id: searchParams.get(`caseId`),
          }
        });
      }
      }
      catch(err){
        console.log('err',err)
      }
    }

    if (uploadIncomeFiles[0]) {
      try {      
        let session = await Auth.currentSession();
        let username = searchParams.get(`caseId`);
        let index;
        console.log('uploadFiles',uploadIncomeFiles)
        for (index = 0; index <= uploadIncomeFiles.length - 1; index++ ) {
          let file=uploadIncomeFiles[index].file;
          let fileExt = file.name.split('.').pop()
          let path=username + "/" + `income${index + 1}.` + fileExt
          let IncomeName = uploadIncomeFiles[index].name
          const result = await Storage.put(path, file, {
            contentType: uploadIncomeFiles[index].type,
          });
          let incomeData = {}
          incomeData[`income${index + 1}_path`] = path
          incomeData[`income${index + 1}_name`] = IncomeName
        console.log('result',result)
        const response = await byAxios({
          route: CASE_LAMBDAS.UPDATE_CASE.url,
          method: CASE_LAMBDAS.UPDATE_CASE.method,
          headerCred: {
            "x-access-token": session?.accessToken?.jwtToken,
            "x-id-token": session?.idToken?.jwtToken
          },
          data: {
            ...incomeData,
            client_id: searchParams.get(`caseId`),
          }
        });
      }
      }
      catch(err){
        console.log('err',err)
      }
    }

    if (uploadMOAFiles[0]) {
      try {      
        let session = await Auth.currentSession();
        let username = searchParams.get(`caseId`);
        let index;
        console.log('uploadFiles',uploadMOAFiles)
        for (index = 0; index <= uploadMOAFiles.length - 1; index++ ) {
          let file=uploadMOAFiles[index].file;
          let fileExt = file.name.split('.').pop()
          let path=username + "/" + `MOA${index + 1}.` + fileExt
          let MOAName = uploadMOAFiles[index].name
          const result = await Storage.put(path, file, {
            contentType: uploadMOAFiles[index].type,
          });
          let incomeData = {}
          incomeData[`MOA${index + 1}_path`] = path
          incomeData[`MOA${index + 1}_name`] = MOAName
        console.log('result',result)
        const response = await byAxios({
          route: CASE_LAMBDAS.UPDATE_CASE.url,
          method: CASE_LAMBDAS.UPDATE_CASE.method,
          headerCred: {
            "x-access-token": session?.accessToken?.jwtToken,
            "x-id-token": session?.idToken?.jwtToken
          },
          data: {
            ...incomeData,
            client_id: searchParams.get(`caseId`),
          }
        });
      }
      }
      catch(err){
        console.log('err',err)
      }
    }

    if (uploadOfferLetterFiles[0]) {
      try {      
        let session = await Auth.currentSession();
        let username = searchParams.get(`caseId`);
        let index;
        console.log('uploadFiles',uploadOfferLetterFiles)
        for (index = 0; index <= uploadOfferLetterFiles.length - 1; index++ ) {
          let file=uploadOfferLetterFiles[index].file;
          let fileExt = file.name.split('.').pop()
          let path=username + "/" + `offerLetter${index + 1}.` + fileExt
          let OfferLetterName = uploadOfferLetterFiles[index].name
          const result = await Storage.put(path, file, {
            contentType: uploadOfferLetterFiles[index].type,
          });
          let incomeData = {}
          incomeData[`offerLetter${index + 1}_path`] = path
          incomeData[`offerLetter${index + 1}_name`] = OfferLetterName
        console.log('result',result)
        const response = await byAxios({
          route: CASE_LAMBDAS.UPDATE_CASE.url,
          method: CASE_LAMBDAS.UPDATE_CASE.method,
          headerCred: {
            "x-access-token": session?.accessToken?.jwtToken,
            "x-id-token": session?.idToken?.jwtToken
          },
          data: {
            ...incomeData,
            client_id: searchParams.get(`caseId`),
          }
        });
      }
      }
      catch(err){
        console.log('err',err)
      }
    }

    if (uploadTradeFiles[0]) {
      try {      
        let session = await Auth.currentSession();
        let username = searchParams.get(`caseId`);
        let index;
        console.log('uploadFiles',uploadTradeFiles)
        for (index = 0; index <= uploadTradeFiles.length - 1; index++ ) {
          let file=uploadTradeFiles[index].file;
          let fileExt = file.name.split('.').pop()
          let path=username + "/" + `trade${index + 1}.` + fileExt
          let TradeName = uploadTradeFiles[index].name
          const result = await Storage.put(path, file, {
            contentType: uploadTradeFiles[index].type,
          });
          let incomeData = {}
          incomeData[`trade${index + 1}_path`] = path
          incomeData[`trade${index + 1}_name`] = TradeName
        console.log('result',result)
        const response = await byAxios({
          route: CASE_LAMBDAS.UPDATE_CASE.url,
          method: CASE_LAMBDAS.UPDATE_CASE.method,
          headerCred: {
            "x-access-token": session?.accessToken?.jwtToken,
            "x-id-token": session?.idToken?.jwtToken
          },
          data: {
            ...incomeData,
            client_id: searchParams.get(`caseId`),
          }
        });
      }
      }
      catch(err){
        console.log('err',err)
      }
    }

    if (uploadEIDFiles[0]) { 
      try {      
        let session = await Auth.currentSession();
        let username = searchParams.get(`caseId`);
        let index;
        console.log('uploadFiles',uploadEIDFiles)
        for (index = 0; index <= uploadEIDFiles.length - 1; index++ ) {
          let file=uploadEIDFiles[index].file;
          let fileExt = file.name.split('.').pop()
          let path=username + "/" + `EID${index + 1}.` + fileExt
          let EIDName = uploadEIDFiles[index].name
          const result = await Storage.put(path, file, {
            contentType: uploadEIDFiles[index].type,
          });
          let incomeData = {}
          incomeData[`EID${index + 1}_path`] = path
          incomeData[`EID${index + 1}_name`] = EIDName
        console.log('result',result)
        const response = await byAxios({
          route: CASE_LAMBDAS.UPDATE_CASE.url,
          method: CASE_LAMBDAS.UPDATE_CASE.method,
          headerCred: {
            "x-access-token": session?.accessToken?.jwtToken,
            "x-id-token": session?.idToken?.jwtToken
          },
          data: {
            ...incomeData,
            client_id: searchParams.get(`caseId`),
          }
        });
      }
      }
      catch(err){
        console.log('err',err)
      }
    }
    if (uploadSalaryFiles[0]) {
      try {      
        let session = await Auth.currentSession();
        let username = searchParams.get(`caseId`);
        let index;
        console.log('uploadFiles',uploadSalaryFiles)
        for (index = 0; index <= uploadSalaryFiles.length - 1; index++ ) {
          let file=uploadSalaryFiles[index].file;
          let fileExt = file.name.split('.').pop()
          let path=username + "/" + `salary${index + 1}.` + fileExt
          let SalaryName = uploadSalaryFiles[index].name
          const result = await Storage.put(path, file, {
            contentType: uploadSalaryFiles[index].type,
          });
          let incomeData = {}
          incomeData[`salary${index + 1}_path`] = path
          incomeData[`salary${index + 1}_name`] = SalaryName
        console.log('result',result)
        const response = await byAxios({
          route: CASE_LAMBDAS.UPDATE_CASE.url,
          method: CASE_LAMBDAS.UPDATE_CASE.method,
          headerCred: {
            "x-access-token": session?.accessToken?.jwtToken,
            "x-id-token": session?.idToken?.jwtToken
          },
          data: {
            ...incomeData,
            client_id: searchParams.get(`caseId`),
          }
        });
      }
      }
      catch(err){
        console.log('err',err)
      }
    }
  }

  useEffect(() => {
    (async () => {
      setLoading(true)
      if (searchParams.get(`caseId`)) {
        try {
          let session = await Auth.currentSession();
          const caseData = await byAxios({
            route: `${CASE_LAMBDAS.GET_DETAIL.url}/${searchParams.get(`caseId`)}`,
            method: CASE_LAMBDAS.GET_DETAIL.method,
            headerCred: {
              "x-access-token": session?.accessToken?.jwtToken,
              "x-id-token": session?.idToken?.jwtToken
            }
          });

          console.log(`caseData in doc`, caseData);

            const pass = caseData?.pass1_path; 
            const visa = caseData?.visa1_path; 
            const eid = caseData?.EID1_path ; 
            const salary = caseData?.salary1_path;
            const bank = caseData?.bank1_path;
            const other = caseData?.other1_path;
            const earning = caseData?.earning1_path;
            const pass2 = caseData?.pass2_path; 
            const visa2 = caseData?.visa2_path; 
            const eid2 = caseData?.EID2_path ; 
            const salary2 = caseData?.salary2_path;
            const bank2 = caseData?.bank2_path;
            const other2 = caseData?.other2_path;
            const earning2 = caseData?.earning2_path;
            const pass3 = caseData?.pass3_path; 
            const visa3 = caseData?.visa3_path; 
            const eid3 = caseData?.EID3_path ; 
            const salary3 = caseData?.salary3_path;
            const bank3 = caseData?.bank3_path;
            const other3 = caseData?.other3_path;
            const earning3 = caseData?.earning3_path;
            const pass4 = caseData?.pass4_path; 
            const visa4 = caseData?.visa4_path; 
            const eid4 = caseData?.EID4_path ; 
            const salary4 = caseData?.salary4_path;
            const bank4 = caseData?.bank4_path;
            const other4 = caseData?.other4_path;
            const earning4 = caseData?.earning4_path;
            const employment = caseData?.employment;
            const audit = caseData?.audit1_path; 
            const audit2 = caseData?.audit2_path; 
            const audit3 = caseData?.audit3_path; 
            const audit4 = caseData?.audit4_path;

            const credit = caseData?.credit1_path; 
            const credit2 = caseData?.credit2_path; 
            const credit3 = caseData?.credit3_path; 
            const credit4 = caseData?.credit4_path;

            const share = caseData?.share1_path; 
            const share2 = caseData?.share2_path; 
            const share3 = caseData?.share3_path; 
            const share4 = caseData?.share4_path;

            const income = caseData?.income1_path; 
            const income2 = caseData?.income2_path; 
            const income3 = caseData?.income3_path; 
            const income4 = caseData?.income4_path; 

            const offerLetter = caseData?.offerLetter1_path; 
            const offerLetter2 = caseData?.offerLetter2_path; 
            const offerLetter3 = caseData?.offerLetter3_path; 
            const offerLetter4 = caseData?.offerLetter4_path;

            const MOA = caseData?.MOA1_path; 
            const MOA2 = caseData?.MOA2_path; 
            const MOA3 = caseData?.MOA3_path; 
            const MOA4 = caseData?.MOA4_path; 

            const trade = caseData?.trade1_path; 
            const trade2 = caseData?.trade2_path; 
            const trade3 = caseData?.trade3_path; 
            const trade4 = caseData?.trade4_path;  
            
            setLocalPass(pass);
            setLocalVisa(visa);
            setLocalEID(eid);
            setLocalBank(bank);
            // setLocalEarning(earning);
            // setLocalOther(other);
            setLocalSalary(salary);
            setLocalPass2(pass2);
            setLocalVisa2(visa2);
            setLocalEID2(eid2);
            setLocalBank2(bank2);
            // setLocalEarning2(earning2);
            // setLocalOther2(other2);
            setLocalSalary2(salary2);
            setLocalPass3(pass3);
            setLocalVisa3(visa3);
            setLocalEID3(eid3);
            setLocalBank3(bank3);
            // setLocalEarning3(earning3);
            // setLocalOther3(other3);
            setLocalSalary3(salary3);
            setLocalPass4(pass4);
            setLocalVisa4(visa4);
            setLocalEID4(eid4);
            setLocalBank4(bank4);
            // setLocalEarning4(earning4);
            // setLocalOther4(other4);
            setLocalSalary4(salary4);
            setLocalCredit(credit);
            setLocalCredit2(credit2);
            setLocalCredit3(credit3);
            setLocalCredit4(credit4);

            setLocalAudit(audit);
            setLocalAudit2(audit2);
            setLocalAudit3(audit3);
            setLocalAudit4(audit4);

            setLocalIncome(income);
            setLocalIncome2(income2);
            setLocalIncome3(income3);
            setLocalIncome4(income4);

            setLocalOfferLetter(offerLetter);
            setLocalOfferLetter2(offerLetter2);
            setLocalOfferLetter3(offerLetter3);
            setLocalOfferLetter4(offerLetter4);

            setLocalShare(share);
            setLocalShare2(share2);
            setLocalShare3(share3);
            setLocalShare4(share4);

            setLocalMOA(MOA);
            setLocalMOA2(MOA2);
            setLocalMOA3(MOA3);
            setLocalMOA4(MOA4);

            setLocalTrade(trade);
            setLocalTrade2(trade2);
            setLocalTrade3(trade3);
            setLocalTrade4(trade4);

            setEmployment(employment)
            
          }
        catch (err) {
          console.log('error',err)
        }
      }
      setLoading(false)
    })()

  }, [lastTab])

  
  return (
<>
      {
        loading ?
        <div id="preloader"> </div> :
        <div id="preloader" style={{display: 'none'}}></div>
      }
      <Typography variant='body2' sx={{
        p: '20px 0px',
        textAlign: 'left'
      }}>
        Upload the application form from the banks selected. Make sure the required forms have been filed in and signed by your client.
      </Typography>

      <Box sx={{
        boxShadow: '0px 0px 10px grey',
        borderRadius: `5px`,
        p: 2
      }} >
        <Box sx={{
          display: 'flex',
          justifyContent: `space-between`,
          alignItems: `center`
        }}
        >
          <Box>
            <Typography sx={{
              fontSize: `18px`,
              fontWeight: 600
            }} > {`${firstName} ${lastName}`}</Typography>
            <Typography sx={{
              fontSize: `16px`,
              fontWeight: 400
            }} >Main Applicant</Typography>
          </Box>
        </Box>
        <FormControl fullWidth style={{marginTop:'10px'}}>
        <InputLabel id="document">Select Document</InputLabel>
        {employed === 'Self employed' ? 
        <Select
          labelId="document"
          id="document"
          value={document}
          label="Select File"
          onChange={handleChange}
        >
         
          <MenuItem value='Visa Copy'>Visa Copy</MenuItem>
          <MenuItem value='Passport'>Passport</MenuItem>
          <MenuItem value='EID'>EID</MenuItem>
          <MenuItem value='Bank Statement'>Last 6 months Bank Statement (Personal & Company)</MenuItem>
          <MenuItem value='Trade License'>Trade License</MenuItem>
          <MenuItem value='MOA & AOA'>MOA & AOA</MenuItem>
          <MenuItem value='Share holding pattern'>Share holding pattern</MenuItem>
          <MenuItem value='Audit'>Audit Financial for last 2 years(if Available/ Optional)</MenuItem>
          <MenuItem value='Source of income'>Proof for any additional source of income( rental income, spouse income, etc)</MenuItem>
          <MenuItem value='loan details'>Existing liability details -offer letter copy to ascertain the loan details.</MenuItem>
          <MenuItem value='Credit card'>Latest credit card statement copy</MenuItem>
        </Select>
         :
         <></>
       }
       {employed === 'Salaried' ? 
        <Select
          labelId="document"
          id="document"
          value={document}
          label="Select File"
          onChange={handleChange}
        >
          <MenuItem value='Visa Copy'>Visa Copy</MenuItem>
          <MenuItem value='Passport'>Passport</MenuItem>
          <MenuItem value='EID'>EID</MenuItem>
          <MenuItem value='Bank Statement'>Last 6 months bank statement</MenuItem>
          <MenuItem value='Salary Slip'> Last 6 months pay slip (In case of income variation) </MenuItem>
          <MenuItem value='loan details'>Existing liability details -offer letter copy to ascertain the loan details.</MenuItem>
          <MenuItem value='Source of income'>Proof for any additional income (rent, spouse income, etc)</MenuItem>
          <MenuItem value='Credit card'>Latest credit card statement copy.</MenuItem>
        </Select>
         :
         <></>
       }
      </FormControl>
        <Grid
          container
          alignItems={`stretch`}
          justifyContent={`space-between`}
          spacing={2}
          sx={{
            mt: 2
          }}
        >
          {document === 'Visa Copy' ?
          <Grid
            item
            xs={6}
            sm={3}
          >
            <Paper
              elevation={3}
              sx={{
                p: 1.5,
                height: `100%`
              }}
            >
              <Typography
                variant={`subtitle1`}
              >
                Visa Copy
              </Typography>
              {
                localVisa !== '' && localVisa ? 
                <div className="drop-area">
                  <label className="select-panel">
                    {"File is already selected."} 
                  </label>
                </div>
                :
                  <DropZone files={uploadVisaFiles} onDropFiles={handleVisaDropFiles} />
              }
            </Paper>
          </Grid>
          :
          <></>
            }
            {document === 'Bank Statement' ?
          <Grid
            item
            xs={6}
            sm={3}
          >
            <Paper
              elevation={3}
              sx={{
                p: 1.5,
                height: `100%`
              }}
            >
              <Typography
                variant={`subtitle1`}
              >
                Bank Statement
              </Typography>
              {
                localBank !== '' && localBank ? 
                <div className="drop-area">
                  <label className="select-panel">
                    {"File is already selected."} 
                  </label>
                </div>
                :
                  <DropZone files={uploadBankFiles} onDropFiles={handleBankDropFiles} />
              }
            </Paper>
          </Grid>
          :
          <></>
            }
            {document === 'Source of income' ?
          <Grid
            item
            xs={6}
            sm={3}
          >
            <Paper
              elevation={3}
              sx={{
                p: 1.5,
                height: `100%`
              }}
            >
              <Typography
                variant={`subtitle1`}
              >
               Source of income
              </Typography>
              {
                localIncome !== '' && localIncome ? 
                <div className="drop-area">
                  <label className="select-panel">
                    {"File is already selected."} 
                  </label>
                </div>
                :
                  <DropZone files={uploadIncomeFiles} onDropFiles={handleIncomeDropFiles} />
              }
            </Paper>
          </Grid>
          :
          <></>
            }
            {document === 'loan details' ?
          <Grid
            item
            xs={6}
            sm={3}
          >
            <Paper
              elevation={3}
              sx={{
                p: 1.5,
                height: `100%`
              }}
            >
              <Typography
                variant={`subtitle1`}
              >
               Offer letter
              </Typography>
              {
                localOfferLetter !== '' && localOfferLetter ? 
                <div className="drop-area">
                  <label className="select-panel">
                    {"File is already selected."} 
                  </label>
                </div>
                :
                  <DropZone files={uploadOfferLetterFiles} onDropFiles={handleOfferLetterDropFiles} />
              }
            </Paper>
          </Grid>
          :
          <></>
            }

      {document === 'Passport' ?
          <Grid
            item
            xs={6}
            sm={3}
          >
            <Paper
              elevation={3}
              sx={{
                p: 1.5,
                height: `100%`
              }}
            >
              <Typography
                variant={`subtitle1`}
              >
                PassPort
              </Typography>
              {
                localPass !== '' && localPass ? 
                <div className="drop-area">
                  <label className="select-panel">
                    {"File is already selected."} 
                  </label>
                </div>
                :   
                  <DropZone files={uploadPassFiles} onDropFiles={handlePassDropFiles} />
              }
            </Paper>
          </Grid>
            :
            <></>
              }

      {document === 'EID' ?
          <Grid
            item
            xs={6}
            sm={3}
            
          >
            <Paper
              elevation={3}
              sx={{
                p: 1.5,
                height: `100%`
              }}
            >
              <Typography
                variant={`subtitle1`}
              >
                EID
              </Typography>

              {
                localEID !== '' && localEID ? 
                <div className="drop-area">
                  <label className="select-panel">
                    {"File is already selected."} 
                  </label>
                </div>
                :
                  <DropZone files={uploadEIDFiles} onDropFiles={handleEIDDropFiles} />
              }

             
            </Paper>
          </Grid>
            :
            <></>
              }
               {document === 'Credit card' ?
          <Grid
            item
            xs={6}
            sm={3}
           
          >
            <Paper
              elevation={3}
              sx={{
                p: 1.5,
                height: `100%`
              }}
            >
              <Typography
                variant={`subtitle1`}
              >
                Credit card
              </Typography>
              {
                localCredit !== '' && localCredit ?  
                <div className="drop-area">
                  <label className="select-panel">
                    {"File is already selected."} 
                  </label>
                </div>
                :
                  <DropZone files={uploadCreditFiles} onDropFiles={handleCreditDropFiles} />
              }
              
            </Paper>
          </Grid>
          :
          <></>
            }
             {document === 'Audit' ?
          <Grid
            item
            xs={6}
            sm={3}
           
          >
            <Paper
              elevation={3}
              sx={{
                p: 1.5,
                height: `100%`
              }}
            >
              <Typography
                variant={`subtitle1`}
              >
                Audit Financial
              </Typography>
              {
                localAudit !== '' && localAudit ?  
                <div className="drop-area">
                  <label className="select-panel">
                    {"File is already selected."} 
                  </label>
                </div>
                :
                  <DropZone files={uploadAuditFiles} onDropFiles={handleAuditDropFiles} />
              }
              
            </Paper>
          </Grid>
          :
          <></>
            }
             {document === 'Share holding pattern' ?
          <Grid
            item
            xs={6}
            sm={3}
           
          >
            <Paper
              elevation={3}
              sx={{
                p: 1.5,
                height: `100%`
              }}
            >
              <Typography
                variant={`subtitle1`}
              >
               Share holding pattern
              </Typography>
              {
                localShare !== '' && localShare ?  
                <div className="drop-area">
                  <label className="select-panel">
                    {"File is already selected."} 
                  </label>
                </div>
                :
                  <DropZone files={uploadShareFiles} onDropFiles={handleShareDropFiles} />
              }
              
            </Paper>
          </Grid>
          :
          <></>
            }
             {document === 'MOA & AOA' ?
          <Grid
            item
            xs={6}
            sm={3}
           
          >
            <Paper
              elevation={3}
              sx={{
                p: 1.5,
                height: `100%`
              }}
            >
              <Typography
                variant={`subtitle1`}
              >
               MOA & AOA
              </Typography>
              {
                localMOA !== '' && localMOA ?  
                <div className="drop-area">
                  <label className="select-panel">
                    {"File is already selected."} 
                  </label>
                </div>
                :
                  <DropZone files={uploadMOAFiles} onDropFiles={handleMOADropFiles} />
              }
              
            </Paper>
          </Grid>
          :
          <></>
            }
            {document === 'Trade License' ?
          <Grid
            item
            xs={6}
            sm={3}
           
          >
            <Paper
              elevation={3}
              sx={{
                p: 1.5,
                height: `100%`
              }}
            >
              <Typography
                variant={`subtitle1`}
              >
               Trade License
              </Typography>
              {
                localTrade !== '' && localTrade ?  
                <div className="drop-area">
                  <label className="select-panel">
                    {"File is already selected."} 
                  </label>
                </div>
                :
                  <DropZone files={uploadTradeFiles} onDropFiles={handleTradeDropFiles} />
              }
              
            </Paper>
          </Grid>
          :
          <></>
            }
    {document === 'Salary Slip' ?
          <Grid
            item
            xs={6}
            sm={3}
           
          >
            <Paper
              elevation={3}
              sx={{
                p: 1.5,
                height: `100%`
              }}
            >
              <Typography
                variant={`subtitle1`}
              >
                Salary Slip
              </Typography>
              {
                localSalary !== '' && localSalary ?  
                <div className="drop-area">
                  <label className="select-panel">
                    {"File is already selected."} 
                  </label>
                </div>
                :
                  <DropZone files={uploadSalaryFiles} onDropFiles={handleSalaryDropFiles} />
              }
              
            </Paper>
          </Grid>
          :
          <></>
            }
            <Grid item
            xs={12}
            >
              {employed === "Salaried" ?
            <div> 
            <p className={`${salaryBlock ? ''  : 'display'}`} style={{width:'10%', fontWeight:'bold'}}>
                  Last 6 months Pay Slip
                </p> 
              {
                localSalary !== '' && localSalary ? 
                    <div>
                      <span style={{width:'10%' , fontWeight:'bold'}}> Last 6 months Pay Slip:</span> <br/>
                      <span style={{ width: '100%' }} > {localSalary} </span><br/>
                      <span style={{ width: '100%' }} > {localSalary2} </span><br/>
                      <span style={{ width: '100%' }} > {localSalary3} </span><br/>
                      <span style={{ width: '100%' }} > {localSalary4} </span><br/>
                    </div>
                  :
                
                  uploadSalaryFiles.map((f, i) =>  
                    <FileTile key={i} file={f?.file} onRemove={handleSalaryRemove}/>
                  ) 
              }
               </div>
                :
                <></>
              }
               <div>
                
               <p className={`${passBlock ? ''  : 'display'}`} style={{width:'100%', fontWeight:'bold'}}>
                  Passport
                </p>  
                {
                localPass !== '' && localPass ? 
                    <div>
                       <span style={{width:'100%' , fontWeight:'bold'}}> Passport:</span> <br/>
                      <span style={{ width: '100%' }} >{localPass} </span> <br/>
                      <span style={{ width: '100%' }} >{localPass2} </span> <br/>
                      <span style={{ width: '100%' }} >{localPass3} </span> <br/>
                      <span style={{ width: '100%' }} >{localPass4} </span> <br/>
                    </div>
                  :
                  uploadPassFiles.map((f, i) =>
                    <FileTile key={i} file={f?.file} onRemove={handlePassRemove}/>
                  )
              } 
              </div> 
              <div> 
              <p className={`${EIDBlock ? ''  : 'display'}`} style={{width:'100%', fontWeight:'bold'}}>
                  EID
                </p> 
                {
                localEID !== '' && localEID ? 
                    <div>
                       <span style={{width:'100%', fontWeight:'bold'}}> EID:</span> <br/>
                      <span style={{ width: '100%' }} >{localEID} </span><br/>
                      <span style={{ width: '100%' }} >{localEID2} </span><br/>
                      <span style={{ width: '100%' }} >{localEID3} </span><br/>
                      <span style={{ width: '100%' }} >{localEID4} </span><br/>
                    </div>
                  :
                  uploadEIDFiles.map((f, i ) =>
                    <FileTile key={i} file={f?.file} onRemove={handleEIDRemove} />
                  )
              } 
              </div>
               <div> 
               <p className={`${visaBlock ? ''  : 'display'}`} style={{width:'100%', fontWeight:'bold'}}>
                  Visa Copy
                </p> 
                {
                localVisa !== '' && localVisa ? 
                    <div>
                       <span style={{width:'100%', fontWeight:'bold'}}> Visa Copy :</span> <br/>
                      <span style={{ width: '100%' }} >{localVisa} </span><br/>
                      <span style={{ width: '100%' }} >{localVisa2} </span><br/>
                      <span style={{ width: '100%' }} >{localVisa3} </span><br/>
                      <span style={{ width: '100%' }} >{localVisa4} </span><br/>
                    </div>
                  :
                  uploadVisaFiles.map((f, i) =>
                    <FileTile key={i} file={f?.file} onRemove={handleVisaRemove}/>
                  )
              } 
              </div>
              <div> 
              <p className={`${bankBlock ? ''  : 'display'}`} style={{width:'100%', fontWeight:'bold'}}>
              Last 6 months Bank Statement
                </p> 
                {
                localBank !== '' && localBank ? 
                    <div>
                       <span style={{width:'100%', fontWeight:'bold'}}>  Last 6 months Bank Statement:</span> <br/>
                      <span style={{ width: '100%' }} >{localBank} </span><br/>
                      <span style={{ width: '100%' }} >{localBank2} </span><br/>
                      <span style={{ width: '100%' }} >{localBank3} </span><br/>
                      <span style={{ width: '100%' }} >{localBank4} </span><br/>
                    </div>
                  :
                  uploadBankFiles.map((f, i) =>
                    <FileTile key={i} file={f?.file} onRemove={handleBankRemove} />
                  )
              } 
              </div>
              {employed === "Self employed" ?
              <div> 
              <p className={`${MOABlock ? ''  : 'display'}`} style={{width:'100%', fontWeight:'bold'}}>
                  MOA & AOA
                </p> 
                {
                localMOA !== '' && localMOA ? 
                    <div>
                       <span style={{width:'100%', fontWeight:'bold'}}> MOA & AOA:</span> <br/>
                      <span style={{ width: '100%' }} >{localMOA} </span><br/>
                      <span style={{ width: '100%' }} >{localMOA2} </span><br/>
                      <span style={{ width: '100%' }} >{localMOA3} </span><br/>
                      <span style={{ width: '100%' }} >{localMOA4} </span><br/>
                    </div>
                  :
                  uploadMOAFiles.map((f, i ) =>
                    <FileTile key={i} file={f?.file} onRemove={handleMOARemove} />
                  )
              } 
              </div>
                :
                <></>
              }
              {employed === "Self employed" ?
              <div> 
              <p className={`${auditBlock ? ''  : 'display'}`} style={{width:'100%', fontWeight:'bold'}}>
              Audit Financial for last 2 years
                </p> 
                {
                localAudit !== '' && localAudit ? 
                    <div>
                       <span style={{width:'100%', fontWeight:'bold'}}> Audit Financial for last 2 years:</span> <br/>
                      <span style={{ width: '100%' }} >{localAudit} </span><br/>
                      <span style={{ width: '100%' }} >{localAudit2} </span><br/>
                      <span style={{ width: '100%' }} >{localAudit3} </span><br/>
                      <span style={{ width: '100%' }} >{localAudit4} </span><br/>
                    </div>
                  :
                  uploadAuditFiles.map((f, i ) =>
                    <FileTile key={i} file={f?.file} onRemove={handleAuditRemove} />
                  )
              } 
              </div>
              :
              <></>
            }
              <div> 
              <p className={`${creditBlock ? ''  : 'display'}`} style={{width:'100%', fontWeight:'bold'}}>
              Latest credit card statement copy
                </p> 
                {
                localCredit !== '' && localCredit ? 
                    <div>
                       <span style={{width:'100%', fontWeight:'bold'}}> Latest credit card statement copy:</span> <br/>
                      <span style={{ width: '100%' }} >{localCredit} </span><br/>
                      <span style={{ width: '100%' }} >{localCredit2} </span><br/>
                      <span style={{ width: '100%' }} >{localCredit3} </span><br/>
                      <span style={{ width: '100%' }} >{localCredit4} </span><br/>
                    </div>
                  :
                  uploadCreditFiles.map((f, i ) =>
                    <FileTile key={i} file={f?.file} onRemove={handleCreditRemove} />
                  )
              } 
              </div>
              {employed === "Self employed" ?
              <div> 
              <p className={`${tradeBlock ? ''  : 'display'}`} style={{width:'100%', fontWeight:'bold'}}>
              Trade License
                </p> 
                {
                localTrade !== '' && localTrade ? 
                    <div>
                       <span style={{width:'100%', fontWeight:'bold'}}> Trade License:</span> <br/>
                      <span style={{ width: '100%' }} >{localTrade} </span><br/>
                      <span style={{ width: '100%' }} >{localTrade2} </span><br/>
                      <span style={{ width: '100%' }} >{localTrade3} </span><br/>
                      <span style={{ width: '100%' }} >{localTrade4} </span><br/>
                    </div>
                  :
                  uploadTradeFiles.map((f, i ) =>
                    <FileTile key={i} file={f?.file} onRemove={handleTradeRemove} />
                  )
              } 
              </div>
                :
                <></>
               }
               {employed === "Self employed" ?
              <div> 
              <p className={`${shareBlock ? ''  : 'display'}`} style={{width:'100%', fontWeight:'bold'}}>
              Share holding pattern
                </p> 
                {
                localShare !== '' && localShare ? 
                    <div>
                       <span style={{width:'100%', fontWeight:'bold'}}> Share holding pattern:</span> <br/>
                      <span style={{ width: '100%' }} >{localShare} </span><br/>
                      <span style={{ width: '100%' }} >{localShare2} </span><br/>
                      <span style={{ width: '100%' }} >{localShare3} </span><br/>
                      <span style={{ width: '100%' }} >{localShare4} </span><br/>
                    </div>
                  :
                  uploadShareFiles.map((f, i ) =>
                    <FileTile key={i} file={f?.file} onRemove={handleShareRemove} />
                  )
              } 
              </div>
               :
               <></>
             }
              <div> 
              <p className={`${offerBlock ? ''  : 'display'}`} style={{width:'100%', fontWeight:'bold'}}>
              Offer letter copy
                </p> 
                {
                localOfferLetter !== '' && localOfferLetter ? 
                    <div>
                       <span style={{width:'100%', fontWeight:'bold'}}> offer letter copy:</span> <br/>
                      <span style={{ width: '100%' }} >{localOfferLetter} </span><br/>
                      <span style={{ width: '100%' }} >{localOfferLetter2} </span><br/>
                      <span style={{ width: '100%' }} >{localOfferLetter3} </span><br/>
                      <span style={{ width: '100%' }} >{localOfferLetter4} </span><br/>
                    </div>
                  :
                  uploadOfferLetterFiles.map((f, i ) =>
                    <FileTile key={i} file={f?.file} onRemove={handleOfferLetterRemove} />
                  )
              } 
              </div>
              <div> 
              <p className={`${incomeBlock ? ''  : 'display'}`} style={{width:'100%', fontWeight:'bold'}}>
              Proof for any additional source of income
                </p> 
                {
                localIncome !== '' && localIncome ? 
                    <div>
                       <span style={{width:'100%', fontWeight:'bold'}}> Additional source of income:</span> <br/>
                      <span style={{ width: '100%' }} >{localIncome} </span><br/>
                      <span style={{ width: '100%' }} >{localIncome2} </span><br/>
                      <span style={{ width: '100%' }} >{localIncome3} </span><br/>
                      <span style={{ width: '100%' }} >{localIncome4} </span><br/>
                    </div>
                  :
                  uploadIncomeFiles.map((f, i ) =>
                    <FileTile key={i} file={f?.file} onRemove={handleIncomeRemove} />
                  )
              } 
              </div>

              {/* <div> 
                <p className={`${earningBlock ? ''  : 'display'}`} style={{width:'10%', fontWeight:'bold'}}>
                  Earning
                </p> 
                {
                localEarning !== '' && localEarning ?
                 
                    <div>
                         <span style={{width:'10%', fontWeight:'bold'}}> Earning :</span> <br/>
                      <span style={{ width: '100%' }} >{localEarning} </span><br/>
                      <span style={{ width: '100%' }} >{localEarning2} </span><br/>
                      <span style={{ width: '100%' }} >{localEarning3} </span><br/>
                      <span style={{ width: '100%' }} >{localEarning4} </span><br/>
                    </div>
                  :
                  uploadEarningFiles.map((f, i) =>
                    <FileTile key={i} file={f?.file} onRemove={handleEarningRemove}/>
                  )
              } 
              </div> */}
              {/* <div> 
                <p className={`${otherBlock ? ''  : 'display'}`}  style={{width:'10%', fontWeight:'bold'}}>
                  Other
                </p>
                {
                localOther !== '' && localOther ? 
                    <div>
                       <span style={{width:'10%', fontWeight:'bold'}}> Other :</span> <br/>
                      <span style={{ width: '100%' }} >{localOther} </span><br/>
                      <span style={{ width: '100%' }} >{localOther2} </span><br/>
                      <span style={{ width: '100%' }} >{localOther3} </span><br/>
                      <span style={{ width: '100%' }} >{localOther4} </span><br/>
                    </div>
                  :
                  uploadOtherFiles.map((f, i) =>
                    <FileTile key={i} file={f?.file} onRemove={handleOtherRemove}/>
                  )
                  
              } 
              </div>
               */}

            </Grid>
        </Grid>

      </Box>
      <Box sx={{
        p: '30px 0px',
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: { xs: 'column', ss: 'unset' }
        }}>
        </Box>
        {
          ((localPass !== '' && localPass) && (localVisa !== '' && localVisa) && (localEID !== '' && localEID)  && (localSalary !== '' && localSalary) ) ? 
          <UploadButton
              mw={150}
              mh={50}
              onClick={() => {
                moveHandle(6);
                nextClick('review');
                 handleDocument();
                localStorage.removeItem('cases')
              }}>
              Next step
            </UploadButton>
          :
            <UploadButton
              mw={150}
              mh={50}
              onClick={() => {
                moveHandle(6);
                nextClick('review');
                handleDocument();
                localStorage.removeItem('cases')
              }}>
              Next step
            </UploadButton>
        }

      </Box>
  </>
  )
}

export default Documents