import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Container,
  Row,
  Modal,
  Col,
  Form,
  InputGroup,
  Button,
  Card, 
  Spinner,
  Dropdown,
} from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import Slider from 'react-custom-slider'
import './style/profile.css'
  import Calculator from './Calculator'
  import Header from './Header'
  const Calculate = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false)
    const [loanLength, setLoanLength] = useState(0)
    const [propertyPrice, setPropertyPrice] = useState(null)
    const [downPayment, setDownPayment] = useState(null)
    const [interest, setInterest] = useState(null)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleGo = () => {
      navigate('/')
    }
    const r = (interest/100.0)/12.0
    const n = loanLength * 12
    const oneplusrn = Math.pow(1+r,n)
    const p = propertyPrice - downPayment
    console.log(r)
    console.log(n)
    console.log(p)
    // const monthlyPayment = ((propertyPrice - downPayment) * (interest * Math.pow(1 + parseInt(interest), loanLength * 12))) / (Math.pow(1 + parseInt(interest), loanLength * 12) - 1)    
    const monthlyPayment = (p*((r*oneplusrn)/(oneplusrn-1))).toFixed(2)
    const[sidebar, setSidebar]=useState(false)
    const showSidebar=()=>{
           setSidebar(true)   
    }
    const hideSidebar=()=>{
        setSidebar(false)
 }

    return (
        <body class={`${sidebar ? 'overlay-body' : ''}`}>
        <div class={`side-menu ${sidebar ? 'show-menu' : ''}`} id="sidebar-menu">
        <div class="close-btn" onClick={hideSidebar}><span class="material-icons"><img src="/assets/images/close.svg" alt="" style={{height:'24px' , width:'24px'}} /></span></div>  
       <Header/>
       </div>
       <div class="home" style={{backgroundColor:'#f4f4f6'}}>
<div class="navbar two-action no-hairline" style={{ paddingTop:'0rem'}}>
    <div class="navbar-inner d-flex align-items-center">
        <div class="left">
            <a href="#" class="link icon-only" onClick={showSidebar}>
                <i class="custom-hamburger">
                    <span><b></b></span>
                </i>
            </a>
        </div>
        <Link to='/' class="sliding custom-title no-line" >
        <div class="sliding custom-title">BCF Mortgage</div>
        </Link>
        <div class="right d-flex">
            {/* <a href="#" class="link icon-only"><span style={{width:'125px'}}> Sign Up as Partner </span></a> */}
            {/* <a href="#" data-bs-toggle="dropdown" aria-expanded="true" class="link"><span style={{width:'125px'}}> Sign Up as User </span></a>
            <div class="dropdown-menu dropdown-menu-end header_drop_icon">
                <a href="#" class="dropdown-item">Sign Up as User</a>
                <a href="#" class="dropdown-item">Sign Up as Partner</a>
                <a href="#" class="dropdown-item">Register</a>
            </div> */}
 <Dropdown>
      <Dropdown.Toggle style={{backgroundColor:'transparent' ,border:'0px'}}>
        <img src='/assets/images/user.svg'/>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href="https://broker.bcf-mortgage.com/signup">Sign Up as Partner</Dropdown.Item>
        <Dropdown.Item href="https://user.mortgagebcf.com/">Sign Up as User</Dropdown.Item>
        {/* <Dropdown.Item href="#">Something else</Dropdown.Item> */}
      </Dropdown.Menu>
    </Dropdown>
        </div>
    </div>
</div>
{/* <div class="page-content header-bg">
    <div class="top-search">
        <div class="container">
            <div class="search-area">
                <Calculator/>
            </div>
        </div>
    </div>
   </div>  */}
   <Row>
    <Col xs={12} lg={7} style={{ paddingLeft:'80px',paddingRight:'80px' ,marginBottom:'30px' , marginTop:'30px'  , display:'flex' , flexDirection:'column' }}>
        <h1 style={{fontSize:'2.1em' , fontWeight:'800'}}>Mortgage Calculator</h1>
        <br/>
        <br/>
        <h2 style={{fontSize:'1.5em' , lineHeight:'2.5rem' , fontWeight:'400'}}> Use our free mortgage calculator to estimate your monthly mortgage payments. We'll take care of everything else. </h2>
    </Col>
    <Col xs={12} lg={4} style={{border:'1px solid #e8e8e8', paddingBottom:'20px',display:'flex', flexDirection:'column', alignItems:'center',marginRight:'10px' , backgroundColor:'white' , marginBottom:'30px' , marginTop:'30px' }}>
    <div style={{
                maxWidth: '400px',
              fontSize: '12px'
              }}
            >
              <div style={{
                  margin: '1.4rem 0px 5px',
                fontSize: '14 px',
                fontWeight:'bold'
                }}
              >
                Property Price
                <img
                  style={{width: '15px', marginLeft: '5px'}}
                  src='/assets/images/information-circle.72ccce5b.svg'
                />
              </div>
              <InputGroup inline>
                <Form.Control
                  type='number'
                  className='input-box borderR-0'
                  value={propertyPrice}
                  onChange={e => setPropertyPrice(e.target.value)}
                  name='propertyPrice'
                />
                <InputGroup.Text
                  className='input-box borderL-0'
                  style={{ background: 'white', color: 'rgb(102, 102, 102)'}}
                >AED</InputGroup.Text>
              </InputGroup>
              <div style={{
                  margin: '1.4rem 0px 5px',
                  fontSize: '14px',
                  fontWeight:'bold', borderBlockColor: 'black'
                }}
              >
                Down Payment
                <img
                  style={{width: '15px', marginLeft: '5px'}}
                  src='/assets/images/information-circle.72ccce5b.svg'
                />
              </div>
              <div className='d-flex'>
                <InputGroup inline style={{
                    maxWidth: '340px',
                  }}
                >
                  <Form.Control
                    type='number'
                    className='input-box borderR-0'
                    value={downPayment}
                    onChange={e => setDownPayment(e.target.value)}
                    name='downPayment'
                  />
                  <InputGroup.Text
                    className='input-box borderL-0'
                    style={{background: 'white', color: 'rgb(102, 102, 102)'}}
                  >AED</InputGroup.Text>
                </InputGroup>
                <InputGroup inline style={{
                    maxWidth: '30px',
                    marginLeft: '13px'
                  }}
                >
                  {/* <Form.Control
                    type='number'
                    className='input-box borderR-0'
                  /> */}
                  <InputGroup.Text
                    className='input-box'
                    style={{background: 'white', color: 'rgb(102, 102, 102)' }}
                    disbaled
                  >{downPayment * 100 / propertyPrice}%</InputGroup.Text>
                </InputGroup>
              </div>
              <div style={{
                  margin: '1.4rem 0px 5px',
                  fontSize: '14px',
                fontWeight:'bold'
                }}
              >
                Average interest rate
                <img
                  style={{width: '15px', marginLeft: '5px'}}
                  src='/assets/images/information-circle.72ccce5b.svg'
                />
              </div>
              <InputGroup inline>
                <Form.Control
                  type='number'
                  className='input-box borderR-0'
                  value={interest}
                  onChange={e => setInterest(e.target.value)}
                  name='interest'
                />
                <InputGroup.Text
                  className='input-box borderL-0'
                  style={{
                    background: 'white',
                    maxWidth: '90px',
                  }}
                >%</InputGroup.Text>
              </InputGroup>
              <Form.Label style={{
                  marginTop: '20px',
                  fontSize: '11px',
                  color: 'rgb(102, 102, 102)',
                }}
              >This is just an estimated value based on the average of the market, your final interest rate depends on what you choose </Form.Label>
              <div style={{fontSize: '14px',
                fontWeight:'bold'}}>
                Length of loan
                <img
                  style={{width: '15px', marginLeft: '5px'}}
                  src='/assets/images/information-circle.72ccce5b.svg'/>
              </div>
              <Slider
                value={loanLength}
                trackLength={250}
                onChange={(value) => setLoanLength(value)}
                valueRenderer={(value) => `${value} years`}
                min={0}
                max={25}
                valueLabelStyle={{height:'0px', top:'30px !important', padding:'0px'}}
              />
            </div>
            <Form.Label style={{
                margin: '2.4rem 0px 0.4rem',
                display: 'block',
                fontSize: '14px',
                color: 'rgb(102, 102, 102)',
                }}
              >
                Estimated monthly payment
              </Form.Label>
              <Form.Label style={{
                  fontSize: '48px',
                  lineHeight: '45px',
                  fontFamily: 'AGaramond',
                  letterSpacing: '-4px',
                  color: 'black',
                }}
              >
                AED {monthlyPayment} </Form.Label> <br/>
              <Form.Label style={{
                  margin: '1rem 0px 0px',
                  fontSize: '14px',
                  color: 'rgb(102, 102, 102)',
                }}
              >This amount is based on a total <br/>
                <span style={{ color: 'black' , fontWeight:'bold'}}>&nbsp;loan of AED &nbsp;&nbsp; {propertyPrice - downPayment}</span>
              </Form.Label>
              <Form.Label style={{
                  fontSize: '14px',
                  lineHeight: '15px'
                }}
              > </Form.Label>
         
    </Col>

   </Row>
              
           
             <Row style={{ backgroundColor:'#00C195' , color:'white', textAlign:'center' , paddingTop:'60px' , paddingBottom:'60px', }}>
                    <Col>
                        <h3>Get pre-approved for a mortgage in a few simple steps!</h3>
                        <p>Answer a few simple questions and one of our mortgage brokers will help you find and secure the best mortgage offers</p>
                        <Button href="https://user.mortgagebcf.com/" style={{padding:'20px', marginTop:'20px' , fontWeight:'bold' , fontSize:'14px'}}> Connect With Us </Button>
                    </Col>
                </Row>
                <Row style={{ backgroundColor:'white' , color:'black', textAlign:'center' , paddingTop:'60px' , paddingBottom:'60px'}}>
                    <Col>
                        <h3>Are You Lookin For Investment</h3>
                        <p>If You are looking for investment, then you are at a right place.</p>
                        <Button href="https://user.mortgagebcf.com/" style={{padding:'20px', marginTop:'20px' , fontWeight:'bold' , fontSize:'14px'}}> Connect With Us </Button>
                    </Col>
                </Row>
                <Row style={{ backgroundColor:'#00C195' , color:'white',   paddingTop:'60px' , paddingBottom:'60px', }}>
                    <Col xs={4} style={{textAlign:'center'}}>
                       <img src="/assets/images/cropped-logo.png" alt="" style={{height:'100px'}} />
                    </Col>
                    <Col xs={4}>
                    <ul>
                        <li>
                            Dashboard
                        </li>

                        <li>
                            Profile
                        </li>
                        <li>
                            About
                        </li>
                    </ul>
                    </Col>
                    <Col xs={4}>
                    <ul>
                        <li>
                           Contact Info
                        </li>

                        <li>
                        00971 42014000
                        </li>
                        <li>
                        support@bcf.ae
                        </li>
                    </ul>
                    </Col>
                                        
                </Row>
                
</div>
</body>
      
    )
  }
  
  export default Calculate